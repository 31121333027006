import React, { Component } from 'react'
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import postDealValidation from './validation/postDealValidation'
class DealPreview extends Component {

    constructor(props) {

        super(props)
        this.state = {
            open: false,



        }
       
        this.toggle = this.toggle.bind(this);
 }

    componentDidMount() {

 
}

    toggle(e) {

   

        const { dealData} = this.props;
        const {open} = this.state

const validResult = postDealValidation(dealData)
if(validResult.isValid){

        this.setState({
           open: !open
        })
 }
}

    render() {

        const {open} = this.state
        const {dealData, errors} = this.props

        return (
            <span>
            
           <a  href="#" onClick= {this.toggle} className="btn btn-secondary mr-2">Preview Deal</a>
            <Modal isOpen={open} toggle={this.toggle} size={'lg'} >
              {/* <ModalHeader toggle={this.toggle}>Modal title</ModalHeader> */}
              <ModalBody>
              <div class="deal_section frontpageDeal">
					<div class="deal_box topline">
						<div class="deal_header">
	        				<div class="deal_header_top">
	        					
	        				</div>
	        				<div id="dealTitle" class="">
	        					<h1>{dealData.dealTitle}</h1>
	        				</div>
	        				<span id="op_reputationmenu_133266635x"></span>
	        			</div>
	        			<div class="deal_detailarea">
	        				<div class="row">
	        					<div class="col-md-8 col-sm-12">
	        						<div class="deal_detail_left_content">
	        							<div class="deal_price">
	        								<span class="deal_new_price">{isNaN(dealData.dealPrice)?dealData.dealPrice:`$${dealData.dealPrice}`}</span>

                          <span class="deal_old_price"> {dealData.stricken_price ?`$${dealData.stricken_price}`:''}</span>
	        							
	        							</div>
	        							
					                    <div class="deal_description" dangerouslySetInnerHTML={{__html: dealData.dealDescription}}>
					                    
											
					                    </div>
					                  
	        							
	        						</div>
	        					</div>	
	        					<div class="col-md-4 col-sm-12">
	        						<div class="detailImages">
	        							{dealData.dealImage&&dealData.dealImage.length>0 && <a href="#"><img src={dealData.dealImage[0].preview}/></a>}
										
	        						</div>
	        						
	        						
	        						
	        					</div>	
	        				</div>
	        			</div>
	        			
	        		
	        			
	        			
					</div>
					<div class="comment_disclaimer_container">
	        				<span><i class="fa fa-info-circle" aria-hidden="true"></i></span>Terms and conditions apply
	        			</div>
				</div>
              </ModalBody>
              <ModalFooter>
                {/* <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '} */}
                <Button color="secondary" onClick={this.toggle}>OK</Button>
              </ModalFooter>
            </Modal>
          </span>

        )


    }


}
export default DealPreview