import React, { Component } from 'react'
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PostDealImages from './postDealImages';
import { connect } from 'react-redux';
import { postDealAction, sendProgressAction, getImagesFromUrlAction, clearUrlImagesAction, removeDealImageAction } from '../actions/postDealAction';
import { getBrandsAction, getCategoriesAction, getStoresAction, getTagsAction } from '../actions/masterAction'
import { Redirect } from 'react-router-dom';
import classnames from 'classnames';
import EditDealImages from './editDealImages';
import PostDealUrlImages from './postDealUrlImages';
import DealPreview from './dealPreview';
import UpdateDealImages from './updateDealImages';
import { Spinner } from 'reactstrap';

let config = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    heading: {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        ]
    }
};


class postDealForm extends Component {

    constructor(props) {

        super(props)
        this.state = {
            submitted: false,
            dragIsPrimary: false,
            urlIsPrimary: true,
            primaryImageError: false,


            formData: {
                id: '',
                isOnline: "1",
                isFeatured: "0",
                dealUrl: '',
                dealTitle: '',
                dealPrice: '',
                stricken_price: '',
                dealDescription: '',
                dealCategories: [],
                dealStores: [],
                dealBrands: [],
                dealTags: [],
                dealImage: [],
                dealUrlImages: [],
                dealStatus: '',
                primaryImage: ''


            },
            errors: {
                urlError: false,
                titleError: false,
                descriptionError: false,
                categoryError: false,
                priceError: false,

            },








        }

        this.errorRef = React.createRef();
        this.titleRef = React.createRef();
        this.saveDeal = this.saveDeal.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.onChangeDescription = this.onChangeDescription.bind(this);

        this.setCategory = this.setCategory.bind(this);

        this.setBrands = this.setBrands.bind(this);
        this.setStores = this.setStores.bind(this);
        this.setTags = this.setTags.bind(this);
        this.setStatus = this.setStatus.bind(this);

        this.validate = this.validate.bind(this)
        this.getLengthofDesc = this.getLengthofDesc.bind(this)
        this.getFiles = this.getFiles.bind(this);
        this.sendInputProgress = this.sendInputProgress.bind(this);
        this.getClkick = this.getClkick.bind(this);
        this.getUrlImages = this.getUrlImages.bind(this);
        this.scrollToRef = this.scrollToRef.bind(this);
        this.dragPrimarySet = this.dragPrimarySet.bind(this);
        this.setPrimaryImageWarning = this.setPrimaryImageWarning.bind(this);
        this.cancelForm = this.cancelForm.bind(this);
        this.numbersOnly = this.numbersOnly.bind(this);
        this.checkFeatured = this.checkFeatured.bind(this);




    }

    componentDidMount() {
        this.nameInput.focus();

        if (!localStorage.getItem('myToken')) {
            this.props.history.push('/login')
        }

        let isShowAll = {
            showAll: 0
        }

        this.props.getBrandList(isShowAll);
        this.props.getTagsList(isShowAll);
        this.props.getCategoryList(isShowAll);
        this.props.getStoreList(isShowAll);




        if (this.props.editMode && this.props.dealData) {

            let dprice = this.props.dealData.price
            if (this.props.dealData.price) {
                dprice = this.props.dealData.price.substring(1);
            }
            this.setState({
                formData: {
                    id: this.props.match.params.id,
                    isOnline: this.props.dealData.del_is_online,
                    isFeatured: this.props.dealData.del_is_featured,
                    dealUrl: this.props.dealData.del_url,
                    dealStatus: this.props.dealData.del_active,
                    dealTitle: this.props.dealData.del_title,
                    dealPrice: dprice,
                    stricken_price: this.props.dealData.stricken_price,
                    // dealPrice: this.props.dealData.price,
                    dealDescription: this.props.dealData.del_descr,
                    dealCategories: this.props.dealCategories,
                    dealStores: this.props.dealStores,
                    dealBrands: this.props.dealBrands,
                    dealTags: this.props.dealTags || [],
                    dealImage: [],


                }

            })



            if (this.props.dealData.del_is_online >= 0) {
                this.props.sendProgress({ 'urlStatus': 'hidden' })

            }
            if (this.props.dealData.del_url) {
                this.props.sendProgress({ 'urlStatus': 'complete' })
                this.props.getImagesFromUrl(this.props.dealData.del_url);
            }
            if (this.props.dealData.del_title) {
                this.props.sendProgress({ 'titleStatus': 'complete' })
            }
            if (this.props.dealData.del_descr) {
                this.props.sendProgress({ 'descriptionStatus': 'complete' })
            }
            if (this.props.dealData.del_price || this.props.dealData.price) {
                this.props.sendProgress({ 'priceStatus': 'complete' })
            }
            if (this.props.dealCategories.length > 0) {
                this.props.sendProgress({ 'categoryStatus': 'complete' })
            }
            if (this.props.dealBrands.length > 0) {
                this.props.sendProgress({ 'brandStatus': 'complete' })
            }
            if (this.props.dealStores.length > 0) {
                this.props.sendProgress({ 'storeStatus': 'complete' })
            }
            if (this.props.dealImages.length > 0) {
                this.props.sendProgress({ 'imageStatus': 'complete' })
            }










        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.urlTitle && this.props.urlTitle !== nextProps.urlTitle && !this.state.formData.dealTitle) {


            this.setState({
                formData: {
                    ...this.state.formData,
                    dealTitle: nextProps.urlTitle

                }
            })
            if (this.titleRef) {
                this.titleRef.current.focus()
            }
        }
        if (!nextProps.editMode && nextProps.tags) {

            if (this.props.tags != nextProps.tags) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        dealTags: nextProps.tags.filter(tag => tag.id == 3)

                    }
                })

            }



        }
    }

    saveDeal(e) {
        e.preventDefault();
        const { errors, formData } = this.state

        this.setState({
            ...this.state,
            submitted: true
        })


        if (Object.keys(errors).every((k) => !errors[k]) && formData.dealTitle && formData.dealPrice && this.getLengthofDesc(formData.dealDescription) > 5 && formData.dealCategories.length > 0) {

            if (formData.isOnline == "1") {

                if (formData.dealUrl != '') {
                    this.props.postDeal(formData);

                } else {
                    this.validate(formData.dealUrl, 'dealUrl')
                    this.scrollToRef(this.errorRef);
                    console.log("not submitted onlinedeal")
                }


            } else {
                this.props.postDeal(formData);
            }


        } else {
            this.scrollToRef(this.errorRef);
            console.log("not submitted common")

        }

    }

    handleChange(e) {


        const { formData } = this.state

        if (e.target.name == "isOnline") {

            if (e.target.value == "0") {
                this.sendInputProgress({ urlStatus: 'hidden' })

                this.setState({
                    errors: {
                        ...this.state.errors,
                        urlError: false
                    },
                    formData: {
                        ...formData,
                        [e.target.name]: e.target.value,
                        dealUrl: ''
                    }

                })

            } else {
                this.validate(formData.dealUrl, 'dealUrl')
                this.setState({
                    formData: {
                        ...formData,
                        [e.target.name]: e.target.value

                    }
                })

            }


        } else {

            this.setState({
                formData: {
                    ...formData,
                    [e.target.name]: e.target.value
                }
            })

        }

    }
    checkFeatured(e) {


        const { formData } = this.state

        if (e.target.checked) {
            this.setState({
                formData: {
                    ...formData,
                    isFeatured: 1
                }
            })

        } else {

            this.setState({
                formData: {
                    ...formData,
                    isFeatured: 0
                }
            })

        }

    }
    numbersOnly(e) {

        console.log("keycode", e.keyCode)
        let number = document.getElementById('dealprice');
        number.onkeydown = function (e) {
            if (!((e.keyCode > 95 && e.keyCode < 106)
                || (e.keyCode > 47 && e.keyCode < 58)
                || e.keyCode == 8
                || e.keyCode == 9
                || e.keyCode == 190
                || e.keyCode == 110)) {
                return false;
            }
        }
    }
    numbersOnlyStricken(e) {
        let number = document.getElementById('stricken_price');
        number.onkeydown = function (e) {
            if (!((e.keyCode > 95 && e.keyCode < 106)
                || (e.keyCode > 47 && e.keyCode < 58)
                || e.keyCode == 8
                || e.keyCode == 9
                || e.keyCode == 190
                || e.keyCode == 110)) {
                return false;
            }
        }
    }
    cancelForm(e) {
        e.preventDefault();
        this.setState({
            primaryImage: '',
            formData: {
                dealUrl: '',
                dealTitle: '',
                dealPrice: '',
                stricken_price: '',
                dealDescription: '',
                dealCategories: [],
                dealStores: [],
                dealBrands: [],
                dealImage: [],
                dealUrlImages: [],
                dealStatus: '',
                primaryImage: ''


            }
        })
        this.props.history.push('/')

    }
    setStatus(e) {


        const { formData } = this.state
        this.setState({
            formData: {
                ...formData,
                dealStatus: e.target.value
            }
        })


    }


    onChangeDescription(editor) {


        console.log(editor.getData())
        const { formData } = this.state



        let newContent = editor.getData();
        this.setState({
            formData: {
                ...formData,
                dealDescription: newContent
            }
        })


    }
    setCategory(value) {
        const { formData } = this.state
        this.validate(value, 'dealCategories')
        if (value) {

            this.setState({
                formData: {
                    ...formData,
                    dealCategories: value
                }
            })
        } else {
            this.setState({
                formData: {
                    ...formData,
                    dealCategories: []
                }
            })
        }
    }

    setBrands(value) {
        const { formData } = this.state
        this.validate(value, 'dealBrands')
        if (value) {

            this.setState({
                formData: {
                    ...formData,
                    dealBrands: value
                }
            })
        } else {
            this.setState({
                formData: {
                    ...formData,
                    dealBrands: []
                }
            })
        }
    }

    setStores(value) {
        const { formData } = this.state
        this.validate(value, 'dealStores')

        if (value) {
            this.setState({
                formData: {
                    ...formData,
                    dealStores: value
                }
            })
        } else {
            this.setState({
                formData: {
                    ...formData,
                    dealStores: []
                }
            })
        }
    }
    setTags(value) {
        const { formData } = this.state


        if (value) {
            this.setState({
                formData: {
                    ...formData,
                    dealTags: value
                }
            })
        } else {
            this.setState({
                formData: {
                    ...formData,
                    dealTags: []
                }
            })
        }
    }

    getFiles(files) {
        this.setState({
            formData: {
                ...this.state.formData,
                dealImage: files
            }
        })

        if (files.length > 0) {
            this.props.sendProgress({ 'imageStatus': 'complete' })
        } else {
            this.props.sendProgress({ 'imageStatus': 'current' })

        }
    }
    getUrlImages(images) {



        this.setState({
            formData: {
                ...this.state.formData,
                dealUrlImages: images
            }


        })


        if (images.length > 0) {
            this.props.sendProgress({ 'imageStatus': 'complete' })
        } else {
            this.props.sendProgress({ 'imageStatus': 'current' })

        }
    }
    getClkick() {
        this.props.sendProgress({ 'current': 'dealImages' })
    }
    sendInputProgress(status) {


        this.props.sendProgress(status)

    }
    getLengthofDesc = (cell, e) => {

        if (cell) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = cell;
            let text = tmp.textContent || tmp.innerText;
            return text.length
        } else return 0;




    }

    validate(value, field) {
        switch (field) {
            case 'dealUrl':
                let re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
                let urlStatus;
                if (value && !re.test(value)) {
                    urlStatus = '';
                    this.setState({
                        urlStatus,
                        errors: {
                            ...this.state.errors,
                            urlError: true
                        }
                    })

                } else {
                    if (!value) {

                        urlStatus = '';
                        this.setState({
                            urlStatus,
                            errors: {
                                ...this.state.errors,
                                urlError: true
                            }
                        })
                    } else {
                        urlStatus = 'complete';
                        this.props.getImagesFromUrl(value);

                        this.setState({
                            urlStatus,
                            errors: {
                                ...this.state.errors,
                                urlError: false
                            }
                        })
                    }


                }
                this.sendInputProgress({ urlStatus })
                break;

            case 'dealTitle':
                let titleStatus;
                if (!value) {
                    titleStatus = '';
                    this.setState({
                        titleStatus,
                        errors: {
                            ...this.state.errors,
                            titleError: true
                        }


                    })


                } else {
                    titleStatus = 'complete';
                    this.setState({
                        titleStatus,
                        errors: {
                            ...this.state.errors,
                            titleError: false
                        }
                    })
                }
                this.sendInputProgress({ titleStatus })
                break;

            case 'dealDescription':


                let descriptionStatus;
                if (this.state.formData.dealDescription && this.getLengthofDesc(this.state.formData.dealDescription) < 6) {
                    descriptionStatus = '';
                    this.setState({
                        descriptionStatus,
                        errors: {
                            ...this.state.errors,
                            descriptionError: true
                        }
                    })




                } else {
                    if (this.state.formData.dealDescription) {
                        descriptionStatus = 'complete'
                    } else descriptionStatus = ''

                    this.setState({
                        descriptionStatus,
                        errors: {
                            ...this.state.errors,
                            descriptionError: false
                        }

                    })

                }
                this.sendInputProgress({ descriptionStatus })

                break;

            case 'dealCategories':
                let categoryStatus;
                if (!value) {
                    categoryStatus = '';
                    this.setState({
                        categoryStatus,
                        errors: {
                            ...this.state.errors,
                            categoryError: true
                        }
                    })
                } else {
                    categoryStatus = 'complete';
                    this.setState({
                        categoryStatus,
                        errors: {
                            ...this.state.errors,
                            categoryError: false
                        }
                    })


                }
                this.sendInputProgress({ categoryStatus })

                break;
            case 'dealBrands':
                let brandStatus;
                if (!value) {
                    brandStatus = '';
                    this.setState({
                        brandStatus
                    })
                } else {
                    brandStatus = 'complete';
                    this.setState({
                        brandStatus

                    })
                }
                this.sendInputProgress({ brandStatus })

                break;
            case 'dealStores':
                let storeStatus;

                if (!value) {
                    storeStatus = ''
                    this.setState({
                        storeStatus
                    })
                } else {
                    storeStatus = 'complete'
                    this.setState({
                        storeStatus

                    })
                }
                this.sendInputProgress({ storeStatus })

                break;
            case 'dealPrice':
                let priceStatus;
                if (!value || isNaN(value)) {
                    priceStatus = ''
                    this.setState({
                        errors: {
                            priceStatus,
                            ...this.state.errors,
                            priceError: true
                        }
                    })


                } else {
                    priceStatus = 'complete'

                    this.setState({
                        priceStatus,
                        errors: {
                            ...this.state.errors,
                            priceError: false
                        }
                    })
                }
                this.sendInputProgress({ priceStatus })

                break;
            default:
                break;
        }


    }
    scrollToRef = (ref) => {
        // console.log("reference", ref)

        setTimeout(() => {

            if (ref.current) {

                window.scrollTo(0, ref.current.offsetTop)
            }

        }, 1000);

    }

    dragPrimarySet = (status, imageName) => {

        let tempformData = { ...this.state.formData }
        tempformData.primaryImage = imageName;


        if (status === 'drag') {



            this.setState({

                dragIsPrimary: true,
                urlIsPrimary: false,





            })





        } else if (status === 'url') {
            this.setState({

                dragIsPrimary: false,
                urlIsPrimary: true,




            })

        }


        setTimeout(() => {
            this.setState({

                formData: tempformData
            })
        });



    }
    setPrimaryImageWarning = (status, id) => {



        if (status) {

            this.setState({

                ...this.state,
                primaryImageError: true


            })

        } else {

           setTimeout(() => {
            this.setState({

                ...this.state,
                primaryImageError: false


            })
               
           },1000);

            this.props.removeDealImage(id)


        }



    }


    componentWillUnmount() {

        this.props.clearUrlImages()

    }




    render() {


        const { formData, submitted, errors, primaryImageError } = this.state
        const { submitLoader, submitResult, submitError, brands, categories, stores, tags, dealImages, editMode, dealData, urlImages, userInfo, updateResult, urlLoader } = this.props

        return (
            <form onSubmit={this.saveDeal} encType="multipart/form-data" >
                <div className="form-group row">

                    <div className="col-6">
                        <label for="isOnline">Online deal? </label>&nbsp;
                    <input
                            type="radio"
                            name="isOnline"
                            checked={formData.isOnline == "1" ? true : false}
                            className="" id="isOnline" value="1" onChange={this.handleChange}
                        />
                    </div>
                    <div className="col-6">
                        <label for="isOnline">Not an Online deal? </label> &nbsp;
                    <input
                            type="radio"
                            name="isOnline"
                            checked={formData.isOnline == "0" ? true : false}
                            className="" id="isOnline" value="0" onChange={this.handleChange}
                        />
                    </div>




                </div>
                {formData.isOnline == "1" ? <div className={classnames("form-group", { 'has-error': errors.urlError })}>
                    <label for="dealUrl">Deal URL</label>
                    <input
                        type="text"
                        name="dealUrl" className="form-control" placeholder="http://" id="dealurl" value={formData.dealUrl} onChange={this.handleChange} onBlur={() => this.validate(formData.dealUrl, 'dealUrl')} onFocus={() => this.sendInputProgress({ 'current': 'dealUrl' })}
                        ref={(input) => { this.nameInput = input; }}
                    />
                    {errors.urlError && <p ref={this.errorRef} className="help-block">Please enter a valid Deal URL. Only a direct link, with no HTML or BB Code can be provided.</p>}
                </div> : ''}

                <div className={classnames("form-group", { 'has-error': errors.titleError || (submitted && !formData.dealTitle) })}>
                    <label for="dealTitle">Deal Title   {urlLoader ? 'Fetching ...' : ''}</label>
                    <input type="text" name="dealTitle" className="form-control" placeholder="Deal or product name with price (e.g. 60' Samsung 4K TV $600 + Free Shipping)" id="dealpw" value={formData.dealTitle} onChange={this.handleChange} onBlur={() => this.validate(formData.dealTitle, 'dealTitle')} onFocus={() => this.sendInputProgress({ 'current': 'dealTitle' })} ref={this.titleRef} />
                    {errors.titleError || (submitted && !formData.dealTitle) ? <p
                        className="help-block" ref={this.errorRef}  >Please provide a deal title with the price included.</p> : ''}
                </div>
                <div className={classnames("form-group row", { 'has-error': errors.priceError || (submitted && !formData.dealPrice) })}>
                    <div className="col-6">
                        <label for="dealPrice">Price</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend2">$</span>
                            </div>
                            <input name="dealPrice" className="form-control" id="dealprice" value={formData.dealPrice} onChange={this.handleChange} onBlur={() => this.validate(formData.dealPrice, 'dealPrice')} onFocus={() => this.sendInputProgress({ 'current': 'dealPrice' })} onKeyDown={this.numbersOnly} />


                        </div>
                        {errors.priceError || (submitted && !formData.dealPrice) ? <p className="help-block" ref={this.errorRef}>Please provide a valid price (Numbers only).</p> : ''}

                    </div>
                    <div className="col-6">
                        <label for="stricken_price">Original Price</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend2">$</span>
                            </div>
                            <input name="stricken_price" className="form-control" id="stricken_price" value={formData.stricken_price} onChange={this.handleChange} onBlur={() => this.validate(formData.stricken_price, 'stricken_price')} onKeyDown={this.numbersOnlyStricken} />


                        </div>


                    </div>

                </div>
                <div className={classnames("form-group", { "has-error": errors.descriptionError || (submitted && this.getLengthofDesc(formData.dealDescription) < 6) })}>
                    <label for="dealescription">Description</label>
                    <CKEditor

                        onReady={editor => {
                            formData.dealDescription && editor.setData(formData.dealDescription)
                        }}
                        config={config}
                        editor={ClassicEditor}
                       
                        data={formData.dealDescription}

                        onChange={(event, editor) => this.onChangeDescription(editor)
                        }
                        onBlur={(event, editor) => {
                            this.validate(formData.dealDescription, 'dealDescription')
                        }}
                        onFocus={(event, editor) => {
                            this.sendInputProgress({ 'current': 'dealDescription' })
                        }}
                    />

                    {(submitted && !formData.dealDescription) ? <p className="help-block" ref={this.errorRef} >Please enter a description.</p> : errors.descriptionError ? <p className="help-block" ref={this.errorRef} >The description you entered is too short. It must be at least 5 characters.</p> : ''}
                    {/* {errors.descriptionError || (submitted && !formData.dealDescription) ? <p className="help-block" ref={this.errorRef} >The description you entered is too short. It must be at least 5 characters.</p> : ''} */}
                </div>
                <div className={classnames("form-group", { "has-error": errors.categoryError || (submitted && formData.dealCategories.length < 1) })}>
                    <label for="dealcategories">Categories</label>
                    <Select

                        isClearable={false}
                        isMulti
                        name="category"
                        value={formData.dealCategories}
                        options={categories}
                        getOptionLabel={(option) => option.ctg_name}
                        getOptionValue={(option) => option.id}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Add one or more categories"
                        onChange={this.setCategory}
                        onFocus={() => this.sendInputProgress({ 'current': 'dealCategories' })}
                    />
                    {errors.categoryError || (submitted && formData.dealCategories.length < 1) ? <p className="help-block" ref={this.errorRef}>Please add one or more categories.</p> : ''}

                </div>
                <div className="form-group">
                    <label for="dealstores">Stores</label>
                    <Select
                        isClearable={false}
                        isMulti
                        name="store"
                        value={formData.dealStores}
                        options={stores}
                        getOptionLabel={(option) => option.str_name}
                        getOptionValue={(option) => option.id}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Add one or more stores"
                        onChange={this.setStores}

                        onFocus={() => this.sendInputProgress({ 'current': 'dealStores' })}
                    />
                </div>
                <div className="form-group">
                    <label for="dealbrands">Brands</label>
                    <Select
                        isClearable={false}
                        isMulti
                        name="brands"
                        value={formData.dealBrands}
                        options={brands}
                        getOptionLabel={(option) => option.brd_name}
                        getOptionValue={(option) => option.id}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Add one or more brands"
                        onChange={this.setBrands}

                        onFocus={() => this.sendInputProgress({ 'current': 'dealBrands' })}
                    />
                </div>
                {userInfo && userInfo.role === 3 && <div className="form-group">
                    <label for="dealTags">Tags</label>
                    <Select
                        isClearable={false}
                        isMulti
                        id="dealTags"
                        name="tags"
                        value={formData.dealTags}
                        options={tags}
                        getOptionLabel={(option) => option.tag_name}
                        getOptionValue={(option) => option.id}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Add one or more tags"
                        onChange={this.setTags}



                    />
                </div>}
                {editMode && userInfo.role != 4 && <div className="form-group">
                    <label for="dealbrands">Status</label>
                    <select
                        onChange={(e) => this.setStatus(e)}
                        className="form-control" name="dealStatus" >

                        <option selected={formData.dealStatus == 1 ? 'selected' : ''} value="1">Active</option>
                        <option selected={formData.dealStatus == 0 ? 'selected' : ''} value="0">Expired</option>
                        <option selected={formData.dealStatus == 2 ? 'selected' : ''} value="2">Suspended</option>
                    </select>
                </div>}
                <div className="form-group">
                    <label for="dealimages">Images &amp; Attachments</label>
                    <div className="file-upload-wrapper">



                        {!editMode && urlImages ? <PostDealUrlImages imageData={urlImages} getUrlImages={this.getUrlImages} dragPrimarySet={this.dragPrimarySet} primaryStatus={this.state.urlIsPrimary ? true : false} /> : editMode ? <UpdateDealImages dragPrimarySet={this.dragPrimarySet} primaryStatus={this.state.urlIsPrimary ? true : false} sendPrimaryWarning={this.setPrimaryImageWarning} imageData={dealImages} primaryImage={dealData.del_image_primary} dealId={dealData.id} /> : ''}
                        {primaryImageError ? <p className="help-block" ref={this.errorRef}>Primary image can't be removed</p> : ''}




                        {/* <input type="file" id="input-file-now" className="file-upload" /> */}
                        {editMode ? <EditDealImages primaryStatus={this.state.dragIsPrimary ? true : false} dragPrimarySet={this.dragPrimarySet} clicked={this.getClkick} getFiles={this.getFiles} imageData={dealImages} dealId={dealData.id} /> : <PostDealImages dragPrimarySet={this.dragPrimarySet} clicked={this.getClkick} getFiles={this.getFiles} primaryStatus={this.state.dragIsPrimary ? true : false} />}

                    </div>

                </div>

                { userInfo && (userInfo.role === 2 || userInfo.role === 3) && <div className="form-group">
                    <label for="dealbrands">Make Featured</label>&nbsp;
           <input
                        type="checkbox"
                        className=""
                        name="isFeatured"
                        id="isFeatured"
                        onChange={this.checkFeatured}
                        checked={formData.isFeatured == 1 ? true : false}

                    />
                </div>}
                <div className="form-group">
                    {submitLoader ? <div className="spinner-border"></div> : <input type="submit" name="" value={editMode ? "Update Deal" : "Submit New Deal"} className="btn btn-primary" />}

                    {!editMode && !submitLoader ? <DealPreview dealData={this.state.formData} /> : ''}
                    {!submitLoader && <a href="#" onClick={this.cancelForm} className="btn btn-secondary">Cancel</a>}

                </div>



                {submitResult ? <Redirect to={{
                    pathname: '/',

                }} /> : updateResult ? <Redirect to={{
                    pathname: `/`,

                }} /> : (submitError ? <p ref={this.errorRef} className="help-block">Some error occured. Please try again</p> : '')}

            </form>

        )


    }


}

const mapStateToProps = state => {
    return {

        userInfo: state.profileReducer.result,

        submitLoader: state.postDealReducer.loader,
        submitResult: state.postDealReducer.result,
        updateResult: state.postDealReducer.updateResult,
        submitError: state.postDealReducer.error,
        brands: state.brandsMasterReducer.brands,
        categories: state.categoryMasterReducer.category,
        stores: state.storesMasterReducer.stores,
        tags: state.tagsMasterReducer.tags,
        urlImages: state.dealUrlImagesReducer.result,
        urlTitle: state.dealUrlImagesReducer.title,
        urlLoader: state.dealUrlImagesReducer.loader,
    }
}
const mapDispatchToProps = dispatch => ({
    postDeal: (data) => dispatch(postDealAction(data)),
    getBrandList: (isShowAll) => dispatch(getBrandsAction(isShowAll)),
    getTagsList: (isShowAll) => dispatch(getTagsAction(isShowAll)),

    getCategoryList: (isShowAll) => dispatch(getCategoriesAction(isShowAll)),
    getStoreList: (isShowAll) => dispatch(getStoresAction(isShowAll)),
    getImagesFromUrl: (url) => dispatch(getImagesFromUrlAction(url)),
    clearUrlImages: () => dispatch(clearUrlImagesAction()),
    sendProgress: (status) => dispatch(sendProgressAction(status)),
    removeDealImage: (id) => dispatch(removeDealImageAction(id)),

})

export default connect(mapStateToProps, mapDispatchToProps)(postDealForm)