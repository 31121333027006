

export default (state = {

}, action) => {
    switch (action.type) {
     case 'PWD_TOKEN_VERIFICATION_SUCCESS':
      return {
       result: 'verified',
       token:action.payload.message
      }

      case 'PWD_TOKEN_VERIFICATION_FAILURE':
        return {
            result: 'error',
            token:''
        }
      case 'PWD_TOKEN_VERIFICATION_CLEAR':
        return {
            result: '',
            token:''
        }

       

          
     default:
      return state
    }
   }

   

   