import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../components/auth/Footer';
import Header from '../components/auth/Header';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert } from 'reactstrap';

import '../css/mails.scss';
import MailList from '../components/MailList';
import { Link } from 'react-router-dom';
import { listHuddle, clearHuddleLikesReducer } from '../actions/huddleAction'
import PaginationComponent from '../components/paginationComponent';
import { perPage} from '../helpers/constants'
import { stubString } from 'lodash';
import moment from 'moment';

function Huddle(props) {

  const convertUTCToLocal =(utcDt, utcDtFormat) => {
    var toDt = moment.utc(utcDt, utcDtFormat).toDate();
    return moment(toDt).format('DD-MM-YYYY hh:mm:ss A');
  }


  
  const [selectedItems, setSelected] = useState([])
  const [formData, setFormData] = useState({ page: 1,  searchtxt:'',
  sortby:'newest' })

  const dispatch = useDispatch()


  const huddleData = useSelector(state => state.huddleListReducer.result)
  const totalCount = useSelector(state => state.huddleListReducer.count)
  const huddleLoader = useSelector(state => state.huddleListReducer.loader)
  const huddleError = useSelector(state => state.huddleListReducer.error)

 

  useEffect(() => {



  window.scrollTo(0,0)

    dispatch(listHuddle(formData));

    return(()=>dispatch(clearHuddleLikesReducer()))


  }, []);
 

  const  doPaginate = (page)=>{
    let searchParams = {
      searchtxt:formData.searchtxt,
      sortby:formData.sortby,
      page:page
    
    }

    setFormData({...formData, page:page })
    dispatch(listHuddle(searchParams));

  }

  const  changeSortBy = (e)=>{

    setFormData({...formData, sortby: e.target.value, page:1})
  
  

    let searchParams = {
      searchtxt:formData.searchtxt,
      sortby:e.target.value,
      page:1
    
    }

   
    dispatch(listHuddle(searchParams));


  }
  const  handleSearchTxtCHange = (e)=>{

    setFormData({...formData, searchtxt: e.target.value})
  

  }
  const  doSearch = ()=>{

   

    let searchParams = {
      searchtxt:formData.searchtxt,
      sortby:formData.sortby,
      page:1
    
    }


    if(formData.searchtxt){

      setFormData({...formData, page:1 })
    dispatch(listHuddle(searchParams));
      
    }


  }
  const  showDescription = (content, limit)=>{

  // create a new dov container
let div = document.createElement('div');

// assing your HTML to div's innerHTML
div.innerHTML = content;


// get div's innerHTML into a new variable
// let newContent = div.innerHTML;
let newContent= div.textContent || div.innerText || ""; 

if(newContent && newContent.length>limit){

  return newContent.substring(0,limit)+'...'
}else return newContent






  }


  const renderHuddle =()=>{

    return( <div className="row">
  
    <div className="col-md-12">
      <div className="row">
        <div className="col-md-6">
        <div className="hdlSearch">
                    <input type="text" className="" value={formData.searchtxt} onChange={handleSearchTxtCHange}   placeholder="Search huddle..."/>
                    <button onClick={doSearch} className="hdlSearchBtn" >Search</button>
                    </div>
        </div>
      </div>
 
   
      <div className="search_results_table table-responsive">
        <table className="table">
          <thead>
            <tr>
            
              <th className="deal_info">Title</th>
              <th className="deal_price">Description</th>
             
              <th className="deal_activity">Activity</th>
              <th className="deal_sort">
                <select name="sortby" id="sorter" onChange={changeSortBy}>
                 
                  <option selected={formData.sortby == 'newset'} value="newest">Sort By Newest</option>
                  <option selected={formData.sortby == 'oldest'} value="oldest">Sort By Oldest</option>
                  <option selected={formData.sortby == 'popular'} value="popular">Sort By Popularity</option>
                  <option value="relevance">Sort By Relevance</option>
                  {/* <option value="relevance">Sort By Relevance</option>
                  <option value="popular">Sort By Popualarity</option>
                  <option selected={formData.sortby == 'lowest_price'} value="lowest_price">Sort by Price (Lowest)</option>
                  <option selected={formData.sortby == 'highest_price'} value="highest_price">Sort by Price (Highest)</option> */}
                 
                </select>
              </th>
            </tr>
          </thead>
          {!huddleLoader? <tbody>

            {renderResultRows()}
          
          </tbody>:<tbody> <div className="spinner-border"></div></tbody>}
          <tfoot>
            <tr>
              <td colSpan="5">
              
             
{!huddleLoader && <PaginationComponent page={formData.page} perPage={perPage} totalCount={totalCount} handlePageChange={doPaginate} />}

              </td>
            </tr>
          </tfoot>
        </table>
      
      </div>
    </div>
  </div>)


  }

  const renderResultRows = ()=>{


    return  huddleData && huddleData.map((huddle, idx)=>{




      return( <tr className="resultRow" key={idx}>
     
      <td className="mainDealInfo">
        <div className="dealWrapper">
<Link to={`/huddle/${huddle.id}`} title={huddle.for_title} className="dealTitle">{huddle.for_title}</Link>
          <div className="dealInfo">
            <div>
             
              Posted on {convertUTCToLocal(huddle.for_created_at)}
            </div>
          </div>
        </div>
      </td>
      <td className="deal_price">
            <span className="" > 
          {showDescription(huddle.for_descr,30)}
          
             </span>
            
            
          </td>
     
      
      
      <td className="deal_activity">
        <div className="text" title={`${huddle.for_count_view} views`}><i className="fa fa-eye" aria-hidden="true" />{huddle.for_count_view} <span className="textLabel">Views</span></div>

        <div className="text" title={`${(huddle.for_count_like - huddle.for_count_dislike)} Likes`}><i className="fa fa-thumbs-up" aria-hidden="true" /> {(huddle.for_count_like - huddle.for_count_dislike)} <span className="textLabel">Likes</span></div>
        <div className="text" title={`${huddle.for_count_comments} Comments`}><i className="fa fa-commenting" aria-hidden="true" /> {huddle.for_count_comments} <span className="textLabel">Comments</span></div>

       
      </td>
      <td className="deal_sort">
        Posted on<br />
       
        {convertUTCToLocal(huddle.for_created_at)}
       
      </td>
     
    </tr>)


});
  }

  return (

    <>
      <Header {...props} />


      <section className="main">
        <div className="container-fluid">

          <div className="searchresult huddle" id="huddle">
           {/* <h1>Huddle</h1>
           <Link to="/create-huddle" >Add New</Link> */}
  <div className="row">
        <div className="col-md-12">
          <div className="search_info">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="search_results_num">
                
                  <span>
                    <a href="#" className tabIndex={0} data-toggle="popover" data-trigger="click" data-placement="bottom" data-popover-content="#searchresult_info">
                      {/* <i className="fa fa-info-circle" aria-hidden="true" /> */}
                      </a>
                    <div id="searchresult_info" className="">
                      
                   {(localStorage.getItem('myStatus') === 'logged_in') ? <Link to="/create-huddle" className="pull-right btn btn-primary" >Add New</Link>:''}
                    <h3 className="text-center">Huddle</h3>

                   

                   
                   
                    </div>
                  </span>
                </div>
              </div>
         
            </div>
            <hr className="gridSeparator" />

           <div className="row">
              <div className="col-md-12">
      
                <div className="tab-content">
                  <div id="Mixed" className="tab-pane active">
                   {renderHuddle()}
                  </div>
                 
                </div>
              </div>
            </div>

            {/*  */}
            </div>
            </div>
            </div>
          </div>



        </div>
      </section>

      <Footer />
    </>
  )



}

export default Huddle