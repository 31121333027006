

export default (state = {

}, action) => {
    switch (action.type) {
     case 'TOKEN_VERIFICATION_SUCCESS':
      return {
       result: 'verified'
      }

      case 'TOKEN_VERIFICATION_FAILURE':
        return {
            result: 'error'
        }
      case 'TOKEN_VERIFICATION_CLEAR':
        return {
            result: ''
        }

       

          
     default:
      return state
    }
   }

   

   