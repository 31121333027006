import React, { Component } from 'react'
import axios from 'axios';
import {url} from '../helpers/constants';
import { connect } from 'react-redux';
import { postDealAction } from '../actions/postDealAction';
import { postDealAlertAction } from '../actions/dealAlertAction';
import ReactTags from 'react-tag-autocomplete'
import { Redirect } from 'react-router-dom';
import { Collapse} from 'reactstrap';

class dealAlertForm extends Component {

    constructor(props) {

        super(props)
        this.state = {
            toggleStatus:'Fewer Options',
            isOpen:true,
            tags: [],
            suggestions:[],
            shortName:'',
            submitted:false,
            editMode:false,
            alertId:''
          }
       
       
        this.saveDealAlert = this.saveDealAlert.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);

        this.toggle = this.toggle.bind(this);
        this.onEntered = this.onEntered.bind(this);
        this.onExited = this.onExited.bind(this);
        this.clearState = this.clearState.bind(this);

    
      
        


    }

    componentDidMount() {




    }

    componentWillReceiveProps(nextProps){
        if(Object.keys(nextProps.editData).length>0){

        
let tagArr = []
            let tempTags = nextProps.editData.dua_keyword.split(',')
            tempTags.forEach(element => {
                tagArr.push({'id':element, 'name':element})
            });

            this.setState({
                editMode:true,
                isOpen:true,
                toggleStatus:'Fewer Options',
                shortName:nextProps.editData.dua_title,
                tags:tagArr,
                alertId:nextProps.editData.id
            })
    
         }else{


            this.setState({
                // toggleStatus:'More Options',
                // isOpen:false,
                tags: [],
                suggestions:[],
                shortName:'',
                submitted:false,
                editMode:false,
                alertId:''
            })
         }

    }

    saveDealAlert(e) {

        const {tags,shortName, editMode, alertId} = this.state
        e.preventDefault();
       

        let formData = {
            tags,
            shortName,
            editMode,
            alertId
        }

      this.props.postDealAlert(formData)

      setTimeout(() => {

        this.setState({
            tags:[],
            shortName:'',
            editMode:false
         })

         this.props.clearSelected()
          
      },2000);

     
   

    }

   

    handleChange(e) {
       
        axios.post(url+'/api/alertSuggetion', {'alttxt':e}
        )
            .then(res => {

              
    
                if (res.data.response=='success') {

                    this.setState({
                        suggestions:[
                          
                            ...res.data.message
                        ]
                      })
    
                 
                } else {
    
                  
    
                }
            })
       
      




    }
    handleTitleChange(e) {
       
     
       
        this.setState({
         ...this.state,
         shortName:e.target.value
        })




    }
    clearState() {
       
     
       
        this.setState({
            toggleStatus:'More Options',
            isOpen:false,
            tags: [],
            suggestions:[],
            shortName:'',
            submitted:false,
            editMode:false,
            alertId:''
        })




    }
    componentWillUnmount(){


}

    handleDelete (i) {
        let oldShortName = this.state.shortName
        let oldTag = [...this.state.tags]

        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)

        if(tags.length>0){
            this.setState({ tags})
        
        }else this.setState({ tags})
        // this.setState({ tags })
      }
    
      handleAddition (tag) {

        let oldTag = [...this.state.tags]
        let oldShortName = this.state.shortName

      // console.log("tags", tag)
        const tags = [].concat(this.state.tags, tag)
   
if(oldTag.length>0){
    this.setState({ tags, shortName: oldShortName!=''?oldShortName:this.state.tags[0].name})

}else this.setState({ tags, shortName: oldShortName!=''?oldShortName:tag.name})
   
      }



   

    onEntered = () => {
        
        this.setState({
            toggleStatus:'Fewer Options'
        })
    }

    onExited = () => {
        
        this.setState({
            toggleStatus:'More Options'
        })
    }
    
    toggle = (e) => {
        e.preventDefault();
        this.setState({
            isOpen:!this.state.isOpen
        })

    }

 


    render() {
const {submitLoader,userInfo } = this.props
const {isOpen,toggleStatus, editMode } = this.state
        return (
            <div className="col-md-8 col-sm-12" >
                
						<h4>Add a Custom Deal Alert</h4>	
						{/* <input type="text" className="form-control"  name="keyword"  placeholder="Search for keywords, stores, brands or categories" autocomplete="off"/> */}
                        <ReactTags
        allowNew = {true}        
          tags={this.state.tags}
          noSuggestionsText={'No tags found'}
          suggestions={this.state.suggestions}
          handleDelete={this.handleDelete.bind(this)}
          handleAddition={this.handleAddition.bind(this)}
          placeholder="Search for keywords, stores, brands or categories"
          handleInputChange = {this.handleChange}
         autofocus
         addOnBlur = {true} 
          />

                        
						<div className="moreOpt_container">	
							<a href="#" className={!isOpen?`opt_link collapsed`:`opt_link`}  onClick={this.toggle}>
                                <span className={!isOpen?`moreOpt_text`:`fewOpt_text`}>{toggleStatus}</span>
                                {/* <span className="fewOpt_text">Fewer Options</span>
                                <span className="moreOpt_text">More Options</span> */}
                            </a>

                            <Collapse
        isOpen={isOpen}
        onEntered={this.onEntered}
        onExited={this.onExited}
       
      >
      <div className="titleInput mt-3">
								    <label>
								      <strong>Title / Short Name</strong> <span className="optional">(optional)</span>
								    </label>
								    <input type="text" className="form-control" data-role="dealAlertTitle" value={this.state.shortName} onChange={this.handleTitleChange} />
                                   
							  	</div>
      </Collapse>
							
						</div>
					
						<button onClick={this.saveDealAlert} className="btn add_alert" disabled = {submitLoader?true:this.state.tags.length<=0?true:false}>{submitLoader?'Please wailt..':editMode?'Update Alert':'Add Alert'}</button>

                        <button className="btn btn-secondary" onClick = {this.clearState}>
Cancel
                        </button>
						</div>	

        )


    }


}

const mapStateToProps = state => {
    return {
        submitLoader: state.postDealAlertReducer.loader,
        submitResult: state.postDealAlertReducer.result,
        submitError: state.postDealAlertReducer.error,
        userInfo:state.profileReducer.result

       
    }
}
const mapDispatchToProps = dispatch => ({
    postDealAlert: (data) => dispatch(postDealAlertAction(data)),
  

})

export default connect(mapStateToProps, mapDispatchToProps)(dealAlertForm)