import React, { Component } from 'react';

import {verifyResetPwdTokenAction} from '../actions/auth/passwordAction'
import {connect} from 'react-redux' 
import { Redirect } from 'react-router-dom';


class forgotPasswordVerification extends Component {


  componentDidMount(){


   const {token} = this.props.match.params

   if(token){
         //console.log("token", token)

         this.props.verifyToken(token);

   }

  }
    render(){
      const {token} = this.props.match.params
      const {verified, pwdToken} = this.props
        return(
         <div>
      <section className="main">
{verified =='verified' ? <Redirect to={{
  pathname: '/reset-password/'+pwdToken
}} />:verified =='error' ? <Redirect to={{
  pathname: '/'
}}/>: <div className="row">

<div className = "spinner-border"></div>
        </div>}


</section>


         </div>
        ) }

    

}

const mapStateToProps = state => ({
verified:state.pwdTokenAuthReducer.result,
pwdToken:state.pwdTokenAuthReducer.token
 
 })
 const mapDispatchToProps = dispatch => ({
  verifyToken: (token) => dispatch(verifyResetPwdTokenAction(token)),
   
 
 })
 export default connect(mapStateToProps, mapDispatchToProps)(forgotPasswordVerification)