import React, { Component } from 'react';
import { connect } from 'react-redux';



class DealCommentLike extends Component {


	constructor(props) {

		super(props)

		this.state = {
			like:0,
			dislike:0
		}



this.handleLike = this.handleLike.bind(this)
	

	}

	componentDidMount(){

	this.setState({
		like:this.props.likeCount,
		dislike:this.props.dislikeCount,
	})


	}

	handleLike = (action, id)=>{

		if(!this.props.userInfo){
			this.props.history.push('/login')
		}
const {like, dislike} = this.state
const {likedComments, dislikedComments, postId} = this.props



switch (action) {
	case  '1':
		if(!likedComments.includes(postId)){

			if(dislikedComments.includes(postId)){
				this.setState({
					like:like+1,
					// dislike:dislike-1>=0?dislike-1:0
					dislike:dislike-1
				})

			}else{

				this.setState({
					...this.state,
					like:like+1
				
				})



			}



			

		}else{
			this.setState({
				...this.state,
				like:like-1,
				
			})

		}

		
		break;
	case '0': 

	if(!dislikedComments.includes(postId)){

		if(likedComments.includes(postId)){
			this.setState({
				// like:like-1>=0?like-1:0,
				like:like-1,
				dislike:dislike+1
			})

		}else{
			this.setState({
				...this.state,
				dislike:dislike+1
			})

		}

		
	}else{
		this.setState({
			...this.state,
			dislike:dislike-1,
			
		})

	}

		
		break;

	default:
		break;
}

this.props.getLikeAction(action,id)

	}






	render() {

const {postId ,likedComments, dislikedComments, likeCount, dislikeCount} = this.props
const {like, dislike} = this.state

		return (

			
						<span class=""><span>Helpful Comment?    </span>
						<span class="postLikesDislikes" >
							<span class="postRateButton postRateUp">
								<span> 
									<i class="fa fa-thumbs-up" aria-hidden="true" onClick={()=>this.handleLike('1',postId)}
									style = {likedComments.includes(postId)?{'color': '#BBDA90','cursor':'pointer'}:{'border': 'none','cursor':'pointer'}}
									
									
									></i>
								</span>
									<span class="postUpVote" > {like}</span>
							</span>
							<span class="dot">|</span>
							<span  class="postRateButton postRateDown"><span>
								 <i class="fa fa-thumbs-down" aria-hidden="true" onClick={()=>this.handleLike('0',postId)}
								 style = {dislikedComments.includes(postId)?{'color': '#F1ACAD','cursor':'pointer'}:{'border': 'none','cursor':'pointer'}}
								 
								 ></i>
								 </span>
		<span class="postDownVote"> {dislike}</span>
							</span>
						</span>
						</span>

		)
					





}
}
const mapStateToProps = state => ({
	loader: state.dealCommentsReducer.loader,
	
  
  
  })
  const mapDispatchToProps = dispatch => ({

  
  })

export default connect(mapStateToProps,mapDispatchToProps) (DealCommentLike)
