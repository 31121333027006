import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../components/auth/Footer';
import Header from '../components/auth/Header';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert } from 'reactstrap';
import '../css/mails.scss';
import MailList from '../components/MailList';
import { Link } from 'react-router-dom';
import { listInbox, listSentItems, deleteMails } from '../actions/mailAction'
import ComposeNewMail from '../components/ComposeNewMail';
import MailPagination from '../components/MailPagination';

function Mails(props) {

 
const limit= 50;

  const [allChecked, setAllChecked] = useState(false)
  const [selectedMenu, changeMenu] = useState('inbox')
  const [selectedItems, setSelected] = useState([])
  const [formData, setFormData] = useState({ page: 1, limit: limit, searchText: '' })

  const dispatch = useDispatch()


  const data = useSelector(state => state.mailListReducer.result)
  const totalCount = useSelector(state => state.mailListReducer.count)
  const mailLoader = useSelector(state => state.mailListReducer.loader)
  const mailError = useSelector(state => state.mailListReducer.error)

  const sendResult = useSelector(state => state.mailActionReducer.result)
  const sendLoader = useSelector(state => state.mailActionReducer.loader)
  const sendError = useSelector(state => state.mailActionReducer.error)


  const userInfo = useSelector(state => state.profileReducer.result)
  const userLoader = useSelector(state => state.profileReducer.loader)

  useEffect(() => {

    if (!localStorage.getItem('myToken')) {
      props.history.push('/')
   }

    dispatch(listInbox(formData));


  }, []);
  useEffect(() => {


if(!userLoader && !userInfo){
  props.history.push('/')

}
   


  }, [userInfo]);

  useEffect(() => {


if(sendResult){
  setSelected([])
  setAllChecked(false)

}
   


  }, [sendResult]);



  const onChangeSearchText = (e) => {

    let newFormData = { ...formData, searchText: e.target.value }

    setFormData(newFormData)
  }
  const handleSearch = (e) => {

    let fmDt = { page: 1, limit: limit, ...formData }
    setFormData(fmDt)
    switch (selectedMenu) {
      case 'inbox':
        dispatch(listInbox(fmDt));
        break;
      case 'sent':
        dispatch(listSentItems(fmDt));
        break;

      default:
        break;
    }



  }


  const onChangeSelected = (id) => {



    let tempArray = [...selectedItems]

    if (selectedItems.includes(id)) {
      tempArray = tempArray.filter(item => item != id)

    } else tempArray.push(id)

    setSelected(tempArray)

  }
  const handleChangeMenu = (menu) => {

    let fmDt = { page: 1, limit: limit, searchText: '' }
    setFormData(fmDt)
    switch (menu) {
      case 'inbox':
        dispatch(listInbox(fmDt));
        break;
      case 'sent':
        dispatch(listSentItems(fmDt));
        break;

      default:
        break;
    }
    changeMenu(menu)
    setSelected([])
    setAllChecked(false)

  }
  const checkAll = (e) => {

    let tempArray = [...selectedItems]

    if (e.target.checked) {
setAllChecked(true)
      data && data.map((item) => {

        if (!tempArray.includes(item)) {
          tempArray.push(item.id)
        }

      })
    } else {
      setAllChecked(false)
      tempArray = []

    }
    setSelected(tempArray)

  }

  const deleteMessages = () => {



    dispatch(deleteMails(selectedItems))

  }
  const handlePageChange = (page) => {

    let newFormData = { ...formData, page }

  setFormData(newFormData)

  switch (selectedMenu) {
    case 'inbox':
      dispatch(listInbox(newFormData));
      break;
    case 'sent':
      dispatch(listSentItems(newFormData));
      break;

    default:
      break;
  }

   

  }

  return (

    <>
      <Header {...props} />


      <section className="main">
        <div className="container-fluid">

          <div className="container mails" id="mails">
            <div className="row">

              {/* <!-- BEGIN INBOX --> */}
              <div className="col-md-12">
                <div className="grid email">
                  <div className="grid-body">
                    <div className="row">
                      {/* <!-- BEGIN INBOX MENU --> */}
                      <div className="col-md-3">
                        <h2 className="grid-title"><i className="fa fa-inbox"></i> Inbox</h2>

                        <ComposeNewMail />

                        {/* <a className="btn btn-block btn-primary" data-toggle="modal" data-target="#compose-modal"><i className="fa fa-pencil"></i>&nbsp;&nbsp;NEW MESSAGE</a> */}

                        <hr />

                        <div>
                          <ul className="nav nav-pills nav-stacked">
                            <li className="header">Folders</li>
                            <li className={selectedMenu == "inbox" ? "active" : ''}><Link to="#"
                              onClick={() => handleChangeMenu('inbox')}   ><i className="fa fa-inbox"></i> Inbox</Link></li>

                            <li className={selectedMenu == "sent" ? "active" : ''} ><Link to="#" onClick={() => handleChangeMenu('sent')}  ><i className="fa fa-mail-forward"></i> Sent
                </Link></li>


                          </ul>
                        </div>
                      </div>
                      {/* <!-- END INBOX MENU --> */}

                      {/* <!-- BEGIN INBOX CONTENT --> */}
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-12">

                            {sendResult && <Alert color="success">
                              {sendResult}
                            </Alert>}
                            {sendError && <Alert color="danger">
                              {sendError}
                            </Alert>}
                          </div>
                          <div className="col-sm-6 mb-1">
                            <input type="checkbox" checked={allChecked} className="mr-3" onChange={checkAll} />

                            <UncontrolledDropdown style={{ display: "inline-block" }} >
                             {!sendLoader? <DropdownToggle disabled={selectedItems.length>0?false:true} className="btn btn-default dropdown-toggle" caret>
                                Action
      </DropdownToggle  >:<div className="spinner-border" ></div>}
                              <DropdownMenu >

                                <DropdownItem onClick={deleteMessages} >Delete</DropdownItem>


                              </DropdownMenu>
                            </UncontrolledDropdown>

                          </div>

                          <div className="col-md-6 search-form mb-1">
                            <form onSubmit={(e) => e.preventDefault()} className="text-right">
                              <div className="input-group">
                                <input type="text" className="form-control input-sm" placeholder="Search" onChange={onChangeSearchText} />
                                <span className="input-group-btn">
                                  <button onClick={handleSearch} type="submit" name="search" className="btn_ btn-primary btn-sm search"><i className="fa fa-search"></i></button></span>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="padding"></div>

                        {mailLoader ? <div className="spinner-border"></div> : data && data.length > 0 ?
                          <><MailList data={data} selectedItems={selectedItems} changeItems={onChangeSelected} menu={selectedMenu} />

                         {totalCount>limit?  <MailPagination 
                           totalCount ={totalCount}
                           perPage={limit}
                           paginationLength ={5}
                           currentPage ={formData.page}
                           onPageChange = {handlePageChange}
                           
                           
                           />:''}


                          </> : <div className="text-center mt-5">No messages to show</div>

                        }
                      </div>
                      {/* <!-- END INBOX CONTENT --> */}

                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- END INBOX --> */}
            </div>
          </div>



        </div>
      </section>

      <Footer />
    </>
  )



}

export default Mails