import React, { useState } from 'react';



const PaginationComponent = (props) => {

 
  const {
    page,
    perPage,
    totalCount,
    handlePageChange
  } = props;



 
  const [currentPage, setCurrentPage] = useState(page);
  
  const next = (e,current) =>{

    setCurrentPage(current+1)
    handlePageChange(current+1)
    e.preventDefault()
  
  }
  const prev = (e,current) =>{
    e.preventDefault()
    setCurrentPage(current-1)
    handlePageChange(current-1)
    
  }
  const jumbTo = (e,page) =>{
    e.preventDefault()
    setCurrentPage(page)
    handlePageChange(page)
  }

  const pageList =()=>{
let liContent = [];

let fromPage;
let toPage;
let toMax = Math.ceil(totalCount/perPage)
switch (currentPage) {
  case 1:
    fromPage = 1
    toPage = toMax>3?3:toMax
    break;
  case 2:
    fromPage = 1
    toPage = toMax>3?3:toMax
    break;
  case toMax:
    fromPage = currentPage-2
    toPage = toMax
    break;

  default:
    fromPage = currentPage-1
    toPage = currentPage+1
    break;
}




    for (let pageNum = fromPage; pageNum <= toPage; pageNum++) {
    

      liContent.push(<li className={currentPage== pageNum?"page-item active":"page-item"}><a className="page-link" href="#" onClick={(e)=>jumbTo(e,pageNum)}>{pageNum}</a></li>)
      
    }

    return liContent;
  }
  return (
   <div className="pull-right d-flex align-items-center">
     <span className="mr-2"> Page {currentPage} / {Math.ceil(totalCount/perPage)}</span>
      <nav aria-label="Page navigation example">
  <ul className="pagination ">
    <li className={currentPage>1?"page-item":"page-item disabled"}>
      <a className="page-link" href="#" aria-label="Previous" onClick={(e)=>prev(e,currentPage)} >
        <span aria-hidden="true">&laquo;</span>
        <span className="sr-only">Previous</span>
      </a>
    </li>
    {
      

    }
  {pageList()}


    <li className={currentPage< Math.ceil(totalCount/perPage)?"page-item":"page-item disabled"}>
      <a className="page-link" href="#" aria-label="Next" onClick={(e)=>next(e,currentPage)}>
        <span aria-hidden="true">&raquo;</span>
        <span className="sr-only">Next</span>
      </a>
    </li>
  </ul>
</nav>
   </div>
  );
}

export default PaginationComponent;