import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { clearUserUpdateMsg } from '../actions/profileAction'


const { SearchBar } = Search;


class UsersList extends Component {

  constructor(props) {

    super(props)
    this.state = {
      usersData: [],
      currentPage: 1,
      perPage: 10
    }

    this.formatAction = this.formatAction.bind(this)
    this.formatRole = this.formatRole.bind(this)
    this.formatSlno = this.formatSlno.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onSizePerPageChange = this.onSizePerPageChange.bind(this)


  }

  onPageChange = (page, perPage) => {

 
    this.setState({
      currentPage: page,
      perPage: perPage
    })


  };
  onSizePerPageChange = (perPage, page) => {


    this.setState({
      currentPage: page,
      perPage: perPage
    })


  };


  formatAction = (cell) => {

    const { userInfo } = this.props


    if (userInfo.role == '2') {
      return (<a title="edit" onClick={(e) => { e.preventDefault(); this.props.sendEditClick(cell) }} href="#"> <i className="fa fa-pencil"></i></a>)
    } else return '';


  }
  formatSlno = (cell, row, rowIndex, ) => {
    const { currentPage, perPage } = this.state

   let slNo=rowIndex+1

  
    slNo = (currentPage * perPage + slNo) - perPage
 
   
     

    
    return slNo


  }
  formatRole = (cell) => {
    let roleName = ''
    switch (cell) {
      case 1:
        roleName = "Super Admin"

        break;
      case 2:
        roleName = "Admin"

        break;
      case 3:
        roleName = "Moderator"

        break;
      case 4:
        roleName = "Normal User"

        break;
      case 5:
        roleName = "Store rep."

        break;

      default:
        roleName = "Normal User"
        break;
    }

    return roleName
  }




  componentDidMount() {



    if (this.props.usersData) {

      this.setState({
        usersData: this.props.usersData
      })

      setTimeout(() => {

        this.props.clearUserUpdateMsg()


      }, 3000);
    }

  }

  componentWillReceiveProps(nextProps) {



  }
  render() {
    const columns = [{
      dataField: 'id',
      text: '#',
      formatter: this.formatSlno

    }, {
      dataField: 'firstName',
      text: 'First Name'
    },
    {
      dataField: 'email',
      text: 'Email ID'
    },
    {
      dataField: 'role',
      text: 'User Role',
      formatter: (cell, row) => this.formatRole(cell)
    },
    {
      dataField: 'id',
      text: 'Action',
      formatter: (cell, row) => this.formatAction(cell)
    },

    ];

    const { usersData } = this.state

    const options = {
      onPageChange: this.onPageChange,
      onSizePerPageChange: this.onSizePerPageChange

    };


    return (


      <div>

        <ToolkitProvider
          bootstrap4 keyField='id'
          data={usersData}
          columns={columns}

          search
        >
          {props =>
            (<div>
              <SearchBar {...props.searchProps} />
              <hr />
              <BootstrapTable
                classes="table-responsive"
                {...props.baseProps} pagination={paginationFactory(options)}

              />
            </div>)}

        </ToolkitProvider>

      </div>



    )
  }

}

const mapStateToProps = state => ({
  userInfo: state.profileReducer.result,


})
const mapDispatchToProps = dispatch => ({

  clearUserUpdateMsg: () => dispatch(clearUserUpdateMsg()),


})
export default connect(mapStateToProps, mapDispatchToProps)(UsersList)