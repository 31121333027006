import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux' 
import Moment from 'react-moment';	
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { getReputationAction } from '../actions/profileAction'
import { Table } from 'reactstrap';
class ProfileOverview extends Component {

   constructor(props){

      super(props)
this.toTab = this.toTab.bind(this);
this.toggle =  this.toggle.bind(this);

this.state = ({
   open:false
})


   }


   toTab = (e, tab, condition)=>{

e.preventDefault()
this.props.getToTabs(tab,condition)

   }

   toggle =(e)=>{

      if(!this.state.open){
         this.props.getReputation(this.props.userInfo.id)

      }


this.setState({
   open:!this.state.open
})


   }

 


  componentDidMount(){



  }
    render(){

      const {userLoader, userDetailsInfo, breakUpResult, breakUpLoader} = this.props
return(
  

    userLoader?<div className="spinner-border"></div>:

  
   <div className="row profileContent_stat">
      <div className="col-md-4">
         <div className="aboutMe">
            <div className="subHead">
               <h4>About Me</h4>
            </div>

             <div className="item">
               <span><i className="fa fa-user-o" aria-hidden="true"></i></span>{userDetailsInfo.firstName+" "+userDetailsInfo.lastName } 
            </div>
         
           {userDetailsInfo.city&& <div className="item">
               <span><i className="fa fa-location-arrow" aria-hidden="true"></i></span>
							{userDetailsInfo.city}
	
               
            </div>}
            <div className="item">
               <span><i className="fa fa-calendar" aria-hidden="true"></i></span>
               Joined {
                  	<Moment format="DD MMM YYYY">
							 {userDetailsInfo.created_at}
				</Moment>
               }
      
            </div>
            {/* <div className="item">
               <span><i className="fa fa-clock-o" aria-hidden="true"></i></span>Timespent: 3w 3d 13h 40m
            </div>
            <div className="item">
               <span><i className="fa fa-clock-o" aria-hidden="true"></i></span>Last Activity: 01/20/2020 01:19:35am
            </div> */}
         </div>
      </div>
      <div className="col-md-8">
         <div className="myStats">
            <div className="subHead">
               <h4>My Statistics</h4>
            </div>
            <div className="item">
               <div className="num"><a >{this.props.reputation}</a></div>
               <div className="statLine">
                  <span><i className="fa fa-star" aria-hidden="true"></i></span>
                  <Link to="#" onClick={this.toggle} >Reputation Points</Link>
                 
               </div>
            </div>
            <div className="item">
               <div className="num"><a href="#" onClick={(e)=>this.toTab(e,'myDeals','all')} >{this.props.myDealsCount}</a></div>
               <div className="statLine">
                  <span><i className="fa fa-tags" aria-hidden="true"></i></span>
                  <a href ="#" onClick={(e)=>this.toTab(e,'myDeals','all')} >Deals Posted</a>
               </div>
            </div>
            <div className="item">
               <div className="num"><a href="#" onClick={(e)=>this.toTab(e,'myDeals','votes')} >{this.props.myVotesCount}</a></div>
               <div className="statLine">
                  <span><i className="fa fa-thumbs-up" aria-hidden="true"></i></span>
                  <a href="#" onClick={(e)=>this.toTab(e,'myDeals','votes')}  >Votes Submitted</a>
               </div>
            </div>
            <div className="item">
               <div className="num"><a href="#" onClick={(e)=>this.toTab(e,'myDeals','comments')}  >{this.props.myCommentsCount}</a></div>
               <div className="statLine">
                  <span><i className="fa fa-commenting" aria-hidden="true"></i></span>
                  <a href="#" onClick={(e)=>this.toTab(e,'myDeals','comments')} >Comments Posted</a>
               </div>
            </div>
         </div>
      </div>
{/* reputation points popup */}

<Modal centered={true} isOpen={this.state.open}  backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={this.toggle}>Reputation Points</ModalHeader>
        <ModalBody>
           {breakUpLoader?<>
<div className="spinner-border"></div>
           </>:<>
           
          {breakUpResult &&  <Table bordered hover striped>
      <thead>
        <tr>
        
          <th>EasyRun Contribution</th>
          <th> Points earned</th>
         
        </tr>
      </thead>
      <tbody>
             
              <tr>
                 <td>Upload Avatar</td>
          <td>{breakUpResult.uploadAvatar||0}</td>
              </tr>
              {/* <tr>
                 <td>Like Post (Created by others)</td>
          <td>{breakUpResult.likePost||0}</td>
              </tr> */}
              <tr>
                 <td>Comment Post</td>
          <td>{breakUpResult.commentPost||0}</td>
              </tr>
              <tr>
                 <td>Create Post</td>
          <td>{breakUpResult.createPost||0}</td>
              </tr>
              {/* <tr>
                 <td>Like recieved for the posts created</td>
          <td>{breakUpResult.likePostCreated||0}</td>
              </tr> */}
            {/* {this.props.userInfo.role=="3"&&  <tr>
                 <td>Moderate post</td>
          <td>{breakUpResult.moderatePost||0}</td>
              </tr>} */}

              <tr>
                 <td>Reported or flagged comment or post</td>
          <td>{breakUpResult.flaggedPost||0}</td>
              </tr>
              <tr>
                 <td>Total</td>
          <td>{breakUpResult.total||0}</td>
              </tr>

</tbody>
           </Table>}

          
           
           </>}
       
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.toggle}>Close</Button>{' '}
        </ModalFooter>
      </Modal>

   </div>
   


          
        ) }

}

const mapStateToProps = state => ({
   userInfo: state.profileReducer.result,
   
   userLoader:state.userDetailsReducer.loader,
   myDealsCount: state.userDetailsReducer.myDealsCount,
   myCommentsCount: state.userDetailsReducer.myCommentsCount,
   myVotesCount: state.userDetailsReducer.myVotesCount,
   reputation: state.userDetailsReducer.reputation,

   breakUpLoader: state.reputationBreakUpReducer.loader,
   breakUpResult: state.reputationBreakUpReducer.result

 
 })
 const mapDispatchToProps = dispatch => ({
  
 getReputation : (userId)=>dispatch(getReputationAction(userId))
   
 
 })
 export default connect(mapStateToProps, mapDispatchToProps)(ProfileOverview)