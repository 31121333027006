import React, { Component } from 'react';
import Header from '../components/auth/Header';
import Footer from '../components/auth/Footer';
import {connect} from 'react-redux' 

class About extends Component {


  componentDidMount(){

		window.scrollTo(0, 0)

  }
    render(){

        return(

         <div>
<Header {...this.props} />
<section class="main">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="wrpBox privacy_content">
                     <h2>About Us</h2>
                   <p>
                   Easyrun is a community based deal website. Curated by a team of expert deal hunters 24/7, Easyrun provides a treasure trove of current deals throughout the year. Our team of deal editors uses their deep experience and category knowledge to moderate and review the deals, researching the price and price history of products from a variety of merchants, selecting only the best deals to post as boosted deals in the home page. The collaboration of the community and deal editors guarantees the best prices around and will help you save money and make better shopping decisions.



                   </p>
                     
                  </div>
               </div>
            </div>
            
         </div>
      </section>
      <Footer />
         </div>
          
        ) }

    

}

const mapStateToProps = state => ({
 
 
 })
 const mapDispatchToProps = dispatch => ({

   
 
 })
 export default connect(mapStateToProps, mapDispatchToProps)(About)