import React, { Component } from 'react';
import { connect } from 'react-redux';
import CKEditor from "react-ckeditor-component";
import { saveCommentAction} from '../actions/dealCommentsAction';

import { Collapse} from 'reactstrap';

let config = {
    toolbarGroups: [
        { name: "document", groups: ["mode", "document", "doctools"] },
        {
            name: "editing",
            groups: ["find", "selection", "spellchecker", "editing"]
        },
        { name: "forms", groups: ["forms"] },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
        },
        "/",
        { name: "links", groups: ["links"] },
    ],
    removeButtons:
        "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo,Source",
    fontSize_sizes: "16/16px;24/24px;48/48px;",
    font_names:
        "Arial/Arial, Helvetica, sans-serif;" +
        "Times New Roman/Times New Roman, Times, serif;" +
        "Verdana",
        tabFocus : true,
	allowedContent: true,

	
};
class AddDealComments extends Component {


	constructor(props) {

		super(props)

		this.state = {
			isOpen: false,
			commentText:'',
			parentPostId:'',
			dealId:'',
		}

	this.toggle= this.toggle.bind(this);
	this.onChangeComment= this.onChangeComment.bind(this);
	this.postReply= this.postReply.bind(this);
	this.saveReply= this.saveReply.bind(this);

	

	}

	componentDidMount(){


	}

	toggle =(e)=>{

		if(!this.props.userInfo){
			this.props.history.push('/login')
		}
e.preventDefault();
		this.setState({
			isOpen:!this.state.isOpen,
			commentText:'',
			parentPostId:this.props.parent,
			dealId:this.props.dealId,
		})
	}

	postReply =(e)=>{
		if(!this.props.userInfo){
			this.props.history.push('/login')
		}
e.preventDefault();

let replyContent = `<div class="rplyDv" style="background-color:#f4f4f4!important;height:100%; padding:1%">
<b>Quoted from: ${this.props.quotedFrom}</b> <a href="#post-${this.props.type=='og'?'og':this.props.parent}">View post</a>
<div>${this.props.post}</div></div>
<span style="white-space: pre-line">&nbsp;</span>`;
		this.setState({
			isOpen:!this.state.isOpen,
			commentText:replyContent,
			parentPostId:this.props.parent,
			dealId:this.props.dealId,
		})
	}
	onChangeComment =(e)=>{
		let newContent = e.editor.getData();

		
        this.setState({
       
                commentText: newContent
            
        })
	}
	saveReply =(e)=>{
		e.preventDefault();

	const {dealId, parentPostId, commentText} = this.state

let formData = {
	dealId, 
	parentPostId, 
	comment: commentText
}
	if(commentText){
		this.props.saveComment(formData);

		setTimeout(() => {

			this.setState({
				dealId, 
				parentPostId:0, 
				commentText: '',
				isOpen:!this.state.isOpen,
			 })
			  
		  },2000);
	}
	}






	render() {

const {isOpen, commentText} = this.state
const {submitLoader} = this.props

		return (

		
		                      	<div className="row">
		                      		<div className="col-md-6 col-7">
										  <span><a href="#" onClick={this.toggle} className="btn add-comment" >Add Comment</a>
									  </span>
									  </div>
		                      		<div className="col-md-6 col-5">
		                      			<div className="add_comment_header_text text-right">
		                          		{/* <a href="#"><i className="fa fa-flag" aria-hidden="true"></i></a>  */}
										  <span className="dot"></span> <a href="#" onClick={this.postReply}>Reply</a>
		                          		</div>
		                          	</div>
									  <div className="clearfix mb-5"></div>
									  <div className="col-sm">

									  <Collapse isOpen={isOpen}>
       
		  <CKEditor
                   
				   config={config}
				   activeClass="p10"
				   content={commentText}
				   events={{

					   "change":this.onChangeComment,
					  
					 
					   
				   }}
				 
				   
				   onInit={editor => {
					editor.editing.view.focus()
				  }}
				  

			   />

{commentText&&<span><a href="#"  className="btn add-comment mt-2 pull-right"   onClick ={this.saveReply} >{!submitLoader?`Post Reply`:`Posting...`}</a>
									  </span>}

			   
        
      </Collapse>
									  </div>
									
		                      	</div>
		                 
		)
	}
}
const mapStateToProps = state => ({
	loader: state.dealDetailsReducer.loader,
	userInfo:state.profileReducer.result,
	submitLoader:state.dealCommentsReducer.submitLoader,
  
  
  })
  const mapDispatchToProps = dispatch => ({
	saveComment: (formData)=>dispatch(saveCommentAction(formData))
  
  })

export default connect(mapStateToProps,mapDispatchToProps) (AddDealComments)
