import React, { Component} from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

let config = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    heading: {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        ]
    }
    
  
};

class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.updateContent = this.updateContent.bind(this);
        this.onChange = this.onChange.bind(this);
        this.state = {
            content: this.props.content,
        }
    }

    componentWillReceiveProps(nextProps){

        if (nextProps.content && this.props.content != nextProps.content){
          
            this.updateContent(nextProps.content)
        }
    }

    

    updateContent(newContent) {
        this.setState({
            content: newContent
        })
    }
    
    onChange(editor){
    
      var newContent = editor.getData();
      this.setState({
        content: newContent
      })
      this.props.handleChange(newContent)

     
    }
    

    
 

    render() {
        return (
            <>
           
             <CKEditor

             onReady={editor => {
                this.state.content && editor.setData(this.state.content)
            }}
             config={config}
             editor={ClassicEditor}
            
             data={this.state.content}

             onChange={(event, editor) => this.onChange(editor)
             }
            
            
         />


         </>
        )
    }
}
export default TextEditor