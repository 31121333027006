
export default (state = {
  likeIds: [],
  dislikeIds: [],
  likeCountId: '',
  like: false,
  dislike: false,
  count: null
}, action) => {
  switch (action.type) {


    case 'HUDDLE_LIKE_DISLIKE_CLEAR':
    
      return {
      
  likeIds: [],
  dislikeIds: [],
  likeCountId: '',
  like: false,
  dislike: false,
  count: null
      }

    case 'HUDDLE_LIKE_SUCCESS':
      let tempDisLikeIds = [...state.dislikeIds]
      let dislikeIds = tempDisLikeIds.filter((item) => item != action.payload.id)

      let tempLikeIds1 = [...state.likeIds]
      let likeIds1 = [...state.likeIds, action.payload.id]

      if(tempLikeIds1.includes(action.payload.id)){
        likeIds1 = tempLikeIds1.filter((item) => item != action.payload.id)
 
       }

      return {

        like: true,
        dislike: false,
        likeIds:likeIds1,
        dislikeIds,
        likeCountId: action.payload.id,
        count: action.payload.count
      }
    case 'HUDDLE_DISLIKE_SUCCESS':
      let tempLikeIds = [...state.likeIds]
      let likeIds = tempLikeIds.filter((item) => item != action.payload.id)

      let tempDisLikeIds1 = [...state.dislikeIds]
      let dislikeIds1 = [...state.dislikeIds, action.payload.id]

      if(tempDisLikeIds1.includes(action.payload.id)){
       dislikeIds1 = tempDisLikeIds1.filter((item) => item != action.payload.id)

      }

      return {
        like: false,
        dislike: true,
        likeCountId: action.payload.id,
        dislikeIds: dislikeIds1,
        likeIds,
        count: action.payload.count

      }
    default:
      return {
        ...state

      }
  }
}