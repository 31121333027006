import React, { Component } from 'react';
import Header from '../components/auth/Header';
import Footer from '../components/auth/Footer';
import {connect} from 'react-redux' 
import DealAlertForm from '../components/dealAlertForm';
import DealAlertList from '../components/dealAlertList';
import { fetchDealAlertAction } from '../actions/dealAlertAction';

class DealAlerts extends Component {

	constructor(props){
		super(props)

		this.state = {
			selectedAlert:{}
		}

		this.getAlert = this.getAlert.bind(this)
		this.clearSelectedDeal = this.clearSelectedDeal.bind(this)
	}


  componentDidMount(){

		window.scrollTo(0, 0)
		this.props.fetchDealAlert();
if(!this.props.userInfo){

	// this.props.history.push('/login')
}
   
  }

  getAlert = (alert)=>{

	this.setState({
		selectedAlert:alert
	})
  }
  clearSelectedDeal = ()=>{

	this.setState({
		selectedAlert:{}
	})
  }
    render(){

    
      const {selectedAlert} = this.state
        return(
          <div>
<Header {...this.props} />
<section className="main">
	<div className="container-fluid">
		<div className="row">
			<div className="col-md-12 deal_alert_container">
				<h2>Deal Alerts</h2>
				<div className="wrpBox searchDealAlerts">
					<div className="row">
                  <DealAlertForm  editData={selectedAlert} clearSelected = {this.clearSelectedDeal} />
						
						{/* <div className="col-md-4 col-sm-12">	
							<div className="guide text-center">
					          <div className="book_icon"><i className="fa fa-book" aria-hidden="true"></i></div>
					          <div className="headline"><strong>Ready for more?</strong></div>
					          <div className="contents">
					            Our community created <a href="/forums/showpost.php?p=67718300&amp;postcount=540" className="howItWorksLink" target="_blank">this helpful guide</a> to help you get the most out of Deal Alerts.
					          </div>
					        </div>
						</div>	 */}
					</div>		
				</div>
				{this.props.dealAlertList&&this.props.dealAlertList.length>0 && <DealAlertList getAlert = {this.getAlert} />}
			</div>
		</div>
		
	</div>
</section>
<Footer />
          </div>
          
        ) }

    

}

const mapStateToProps = state => ({
  userInfo:state.profileReducer.result,
  dealAlertList:state.dealAlertReducer.result,
 
 })
 const mapDispatchToProps = dispatch => ({
	fetchDealAlert: () => dispatch(fetchDealAlertAction()),
   
 
 })
 export default connect(mapStateToProps, mapDispatchToProps)(DealAlerts)