
export default (state = {}, action) => {
  switch (action.type) {
    case 'POPULAR_DEALS_FETCH_BEGINS':
      return {
        loader: true,
        error: false,
        result: null
      }

    case 'POPULAR_DEALS_FETCH_SUCCESS':
      return {
        loader: false,
        error: false,
        result: action.payload.message
      }

    case 'POPULAR_DEALS_FETCH_FAILURE':
      return {
        loader: false,
        error: true,
        result: null
      }
    default:
      return state
  }
}