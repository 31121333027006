import {perPage} from '../helpers/constants';

export default (state = {loader: false, error: false,result: [], skip: 0, limit: perPage, hasMore: true, commentCount:0}, action) => {
  switch (action.type) {
    case 'HUDDLE_COMMENTS_FETCH_BEGINS':
      return {
        ...state,
        loader: true,
        error: false,
      
        commentCount:state.commentCount,
        submitLoader:false,

        skip: action.skip,
        limit: action.limit,
        hasMore: true
      }

    case 'HUDDLE_COMMENTS_FETCH_SUCCESS':
      return {
        loader: false,
        error: false,
        result: state.result.concat(action.payload.message),
        commentCount:action.payload?action.payload.total:0,
        submitLoader:false,

        skip: state.skip,
        limit: state.limit,
        hasMore: action.hasMore
      }

    case 'HUDDLE_COMMENTS_FETCH_FAILURE':
      return {
        ...state,
        loader: false,
        error: true,
        // result: null,
        commentCount:0,
        submitLoader:false,

        hasMore: false,
        skip: state.skip,
        limit: state.limit,
      }
    case 'HUDDLE_COMMENT_POST_BEGINS':
      return {
     
    ...state,
        submitLoader:true
      }
    case 'HUDDLE_COMMENT_POST_SUCCESS':
      return {
        ...state,
     
        submitLoader:false
      }
    case 'HUDDLE_COMMENT_POST_FAILURE':
      return {
        ...state,
        submitLoader:false
      }
    case 'HUDDLE_COMMENT_REDUCER_CLEAR':
      return {
      
        loader: false,
        error: false,
      result:[],
        commentCount:0,
        submitLoader:false,

        skip: 0,
        limit:perPage,
        hasMore: true
     
      }
    default:
      return state
  }
}