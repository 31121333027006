import React, { Component } from 'react';
import Header from '../components/auth/Header';
import Footer from '../components/auth/Footer';
import {Link} from 'react-router-dom'
import SideBar from '../components/sideBar';
import { connect } from 'react-redux';
import { getHuddleDetailsAction, sendLikeAction, clearHuddleLikesReducer} from '../actions/huddleAction';
import { clearAction } from '../actions/postDealAction'
import {  commentsReducerClear} from '../actions/huddleCommentsAction';
import {imagePath, likeStyle, dislikeStyle, dealEditablePeriod} from '../helpers/constants'
import DealImageCarousel from '../components/dealImageCarousel';
import { browserHistory } from 'react-router';
import {returnUserType} from '../helpers/functions'
import Share from '../components/share';
import AddHuddleComments from '../components/AddHuddleComments';
import HuddleCommentList from '../components/HuddleCommentList';
import jwt from 'jwt-decode'

class HuddleDetails extends Component {


	constructor(props) {

		super(props)

	this.getLikeDislike= this.getLikeDislike.bind(this);
	this.calculateCount= this.calculateCount.bind(this);


	}

	componentDidMount(){

		this.backListener = browserHistory.listen(location => {
		
			if (location.action === "POP") {
			  window.location.href = '/huddle';   
			}
		  });



		let args = {
			forumId: this.props.match.params.id,
			userId:'',
			sessionId: sessionStorage.getItem('easySession')
		}

		let token = localStorage.getItem('myToken');
		if(token){

		   let decodedData = jwt(token); // decode your token here
		   args.userId = decodedData.sub
		 



		}	 

	
		

this.props.getHuddleDetails(args)




	}


	

	componentWillUnmount(){

		this.props.commentsReducerClear()
		this.props.clearHuddleLikesReducer()


		setTimeout(() => {
			this.backListener();
			
		},1000);
	}


	getLikeDislike=(status,id)=>{
		if(this.props.userInfo){

			

		
		  this.props.sendLike(status,id);
	
		}else{
	
		  this.props.history.push('/login')
		}
	
	  }

	  calculateCount(count_dislike, count_like){

		const {likeCount} = this.props 
		


		if(likeCount!=null){

			return likeCount
		}else return (count_like - count_dislike)


	}


	  




	render() {

const {loader, result, likeIds, dislikeIds, dealImages, huddleUser, dealBrands, dealCategories, dealStores, dealTags, userInfo, commentCount} = this.props

let shwEditForUser = false
let shwEditForAdmin = false
let d1;
let d2;
if(result && userInfo && huddleUser){
	 d1 = new Date();
	 d2 = new Date(result.for_created_at);
	 d2.setDate(d2.getDate() + dealEditablePeriod);



	 if((d1.getTime() <= d2.getTime()) && userInfo.id ===huddleUser.id){
		shwEditForUser = true
	 }
	 if(userInfo.role ===2 || userInfo.role ===3){
		shwEditForAdmin = true
	 }

}

		return (

			<div>
				<Header {...this.props} />
				<section class="main">
	<div class="container-fluid">
	{!loader && result?(<div class="row">
			<div class="col-lg-9 col-md-8">
		
				<div class="deal_section frontpageDeal">
					<div class="deal_box topline">

						<div class="deal_header">
						<div class="deal_header_top" id="post-og" >
{/* <span className="badges">

{huddleUser.role!=4? <span className="bg-usrType">{returnUserType(huddleUser.role)}</span>:''}

{result.for_active==0? 
        <span className="bg-expired ml-2">Expired</span>
       :''}
</span> */}
{/* <span className="badges pull-right d-flex">

{dealTags&& dealTags.map((tag, idx)=> <div id={`color-${tag.id}`} className="bg-status  ml-1">{tag.tag_name}</div>  )}

     
</span> */}

						</div>
	        				<div id="dealTitle" class="">
	        					<h1>{result.for_title}</h1>
	        				</div>
	        				<span id="op_reputationmenu_133266635x"></span>
	        			</div>
	        			<div class="deal_detailarea">
	        				<div class="row">
	        					<div class="col-lg-12 col-sm-12">
	        						<div class="deal_detail_left_content">
	        						
	        							<div class="deal_score_section">
					                      
					                        <div class="deal_view_num">
					                        	<span><i class="fa fa-eye" aria-hidden="true"></i></span>
					                      		<span> {result.for_count_view} Views</span>
					                        </div>
											<div class="dealScore" data-action="showThreadStats" data-forum-id="9" data-thread-id="13711040">
											  
					                          <span><i class="fa fa-thumbs-up" aria-hidden="true"></i></span><span> {this.calculateCount(result.for_count_dislike ,result.for_count_like)} Likes</span>
					                        </div>
					                        <div class="deal_comments_num">
												
					                        	
							                       <span><i class="fa fa-commenting" aria-hidden="true"></i></span>
							                       <span> {commentCount>0?commentCount :result.for_count_comments} Comments</span>
						                      	
					                        </div>
					                    </div>
					                    <div class="deal_description" dangerouslySetInnerHTML={{__html: result.for_descr}}>
					                    
											
					                    </div>
					                   
	        							
	        						</div>
	        					</div>	
	        					
	        				</div>
	        			</div>
	        			{huddleUser&& <div class="deal_notes">
	        				<div class="deal_notes_title">
	        					<div class="row">
		        					
		        					<div class="col-md-12">
		        						<div class="deal_notes_header_text">
		        							<span>Posted by </span>{huddleUser.firstName}


											{shwEditForUser?<span className="pl-2"><Link to={`/edit-huddle/${result.id}`} ><i className="fa fa-pencil"></i> Edit Huddle</Link></span>:shwEditForAdmin?<span className="pl-2"><Link to={`/edit-huddle/${result.id}`} ><i className="fa fa-pencil"></i> Edit Huddle</Link></span>:''}
		        						</div>
		        					</div>
		        				</div>
		                    </div>
        				


        					
	        			</div>}
	        			


	        			<div class="add_comment_container">
	        				<div class="add_comment_title">
								<AddHuddleComments quotedFrom={huddleUser.firstName} {...this.props} post = {result.for_descr} parent={0} huddleId={result.id} key={`${result.id}main`} type="og" />
		                      
		                    </div>
		                   
		               		
	        			</div>
	        			
					</div>
					<div class="comment_disclaimer_container">
	        				<span><i class="fa fa-info-circle" aria-hidden="true"></i></span>Terms and conditions apply
	        			</div>
				</div>

				
				<div class="deal_status">
				
		          	<div class="deal_vote_btn_group">
		          		<span class="like_vote"  onClick = {()=>this.getLikeDislike('1',result.id)}>
							  <i class="fa fa-thumbs-up" aria-hidden="true"  style={likeIds.includes(result.id)?likeStyle:{'border':'0','cursor':'pointer'}}></i></span>
		          		<span class="dislike_vote" onClick = {()=>this.getLikeDislike('0',result.id)} >
							  <i class="fa fa-thumbs-down" aria-hidden="true"  style={dislikeIds.includes(result.id)?dislikeStyle:{'border':'0','cursor':'pointer'}}></i></span>
		          	</div>
		          	<div class="deal_views_comments">
		          		<span class="deal_status_view_num">
                        	<span><i class="fa fa-eye" aria-hidden="true"></i></span>
                      		<span> {result.for_count_view} Views</span>
                        </span>
						<span class="dealScore" data-action="showThreadStats" data-forum-id="9" data-thread-id="13711040">
											  
					                          <span><i class="fa fa-thumbs-up" aria-hidden="true"></i></span>
											  <span> {this.calculateCount(result.for_count_dislike ,result.for_count_like)} Likes</span>
					                        </span>
						
                        <span class="deal_status_comments_num">
                        
		                       <span id="comments"><i class="fa fa-commenting" aria-hidden="true"></i></span>
		                       <span> {this.props.commentCount} Comments</span>
	                      
                        </span>
		          	</div>
		          
		          	<div class="clearfix"></div>
				</div>

			<HuddleCommentList huddleId = {result.id}  {...this.props} />




			</div>
		<SideBar {...this.props} />
		</div>):<div className="spinner-border"></div>}
		
	</div>
</section>
		
				<Footer />
			</div>
		)
	}
}
const mapStateToProps = state => ({
	loader: state.huddleDetailsReducer.loader,
	result:state.huddleDetailsReducer.result &&state.huddleDetailsReducer.result.forum,

	huddleUser:state.huddleDetailsReducer.result && state.huddleDetailsReducer.result.user,
	error:state.huddleDetailsReducer.error,
	userInfo:state.profileReducer.result,
	likeIds:state.huddleLikeReducer.likeIds,
	dislikeIds:state.huddleLikeReducer.dislikeIds,
	likeCount:state.huddleLikeReducer.count,
	commentCount:state.huddleCommentsReducer &&state.huddleCommentsReducer.commentCount,
	
  
  
  })
  const mapDispatchToProps = dispatch => ({
	getHuddleDetails: (args) => dispatch(getHuddleDetailsAction(args)),
	sendLike: (status,id) => dispatch(sendLikeAction(status,id)),
	commentsReducerClear:() => dispatch(commentsReducerClear()),
	clearHuddleLikesReducer:() => dispatch(clearHuddleLikesReducer())
  
  })

export default connect(mapStateToProps,mapDispatchToProps) (HuddleDetails)
