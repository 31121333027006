import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../components/auth/Footer';
import Header from '../components/auth/Header';

function NotFound(props) {



  return (

    <>
      <Header {...props} />


      <section className="main">
        <div className="container-fluid">

          <div className="container mails" id="mails">
            <div className="text-center mt-5" >
            <h1>404!</h1>
            <h1>Page Not Found</h1>
            </div>
           
          
          </div>



        </div>
      </section>

      <Footer />
    </>
  )



}

export default NotFound