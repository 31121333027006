import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {connect} from 'react-redux' 
import {getUserAction, getUserDetailsAction} from '../../actions/auth/authAction'
import {getPopularCatsAction} from '../../actions/masterAction'
import $ from "jquery";
import { Collapse, Col } from 'reactstrap';
import HeaderSearchForm from '../headerSearchForm';
import { avatarPath} from '../../helpers/constants'

class Header extends Component {
  constructor() {
    super();

    this.state = {
      isOpen:false,
      isOpen2:false
    }
    this.logout = this.logout.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
  }

  toggle =()=>{

    this.setState({
      isOpen:!this.state.isOpen
    })
  }
  toggle2 =()=>{

    this.setState({
      isOpen2:!this.state.isOpen2
    })
  }

  componentDidMount(){
    //set browser session
    if(!sessionStorage.getItem('easySession')){
      sessionStorage.setItem('easySession', new Date().getTime())
    }
// this.props.getPopularCats();
this.props.getUser();
this.props.getUserDetails();


//jquery
$('.dropdown-toggle').click(function() {

  $(this).next('.dropdown-menu').slideToggle();
    

});



  }

  logout() {
    localStorage.clear();
    window.location.href = '/';
  }

  render() {
const cat = this.props.match.params.cat
    const {isOpen, isOpen2} = this.state
    const {userInfo} = this.props
    return (
      <header>
      <div className="header-top">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-1 col-sm-2">
              <div className="logo"><a href="/"><img src={require("../../images/Easy-Run-Logo-Header.png")}/></a></div>
            </div>
            {/* <div className="col-md-6 col-sm-5"> 
              <div className="searchbar mtb-5">
                <form className="mb-0">
                  <input type="text" className="form-control" name="q" value="" placeholder="Search here" />
                  <span className="icon-search">
                          <input type="submit" value="" className="submit-icon"/>
                        </span>
                </form>
              </div>
            </div> */}
            <HeaderSearchForm {...this.props} />
            <div className="col-md-5 col-sm-12">
              <div className="user_profile">
              {localStorage.getItem('myStatus') === 'logged_in' &&  <div className="user_container  text-right">
                  
                    <div className="user">
                    {userInfo && userInfo.avatar? <img src={`${avatarPath}/${userInfo.avatar}`} className="userAvatar" />: ''}
                   
                   
                    </div>
                    <div className="user_name" id="mainmenu">
                      <div className="dropdown">
                        <a className="dropdown-toggle" data-toggle="dropdown" href="#" >{userInfo&&userInfo.email}</a>
                         <ul className="dropdown-menu dropdown-menu-right"  role="menu" aria-labelledby="dLabel">  
                         <li><Link to={`/profile/${this.props.userInfo&&this.props.userInfo.id}`}>Profile</Link></li>
                         
                            <li className="dropdown-divider"></li>
                           
                            {/* <li><Link  to={{ pathname: `/profile/${this.props.userInfo&&this.props.userInfo.id}`, query: { catch: 'mydeals' } }} >My Deals</Link></li> */}
                            <li><Link to={'/deal-alerts'}>My Deal Alerts</Link></li>
                            <li className="dropdown-divider"></li>

                            <li><Link to={'/mails'}>Inbox</Link></li>
                            <li className="dropdown-divider"></li>
                            {/* <li className="dropdown-divider"></li>
                            <li  className="dropdown"><a href="#" className="dropdown-toggle1" onClick = {this.toggle2}>Saved Items</a>

                            <Collapse isOpen={isOpen2}>
                            <ul  >      
                                <li><a href="#">Subscribed Threads</a></li>
                                <li><a href="#">Ignored Threads</a></li>
                                <li><a href="#">Saved Searches</a></li>
                                <li><a href="#">Thread Stickies</a></li>
                                <li><a href="#">Reminders</a></li>
                              </ul>
                            </Collapse>
                            
                            </li> */}
                            {/* <li className="dropdown-divider"></li>
                            <li><a href="#">Notifications</a></li>
                            <li className="dropdown-divider"></li>
                            <li><a href="#">Settings & Options</a></li>
                            <li className="dropdown-divider"></li> */}
                            <li><a href="#" onClick={this.logout} >Logout</a></li>
  
                        </ul>
                      </div>
                      
                    </div>
                  
                </div>}
             
                {(localStorage.getItem('myStatus') === 'logged_in') ? (
                    <div className="login-btn-container text-right">
                      <Link to="/post-deal" className="btn btn-success login-btn">Post Deal</Link>
                      <a href="/huddle" className="btn btn-success login-btn  ml-2">Huddle</a>
                      
                    </div>
                  ) : (
                      <div className="login-btn-container text-right">
                        <Link to="/login" className="btn btn-primary login-btn mr-10">Log In</Link>
                        <Link to="/signup" className="btn btn-primary sign-up-btn">Sign Up</Link>
                        <Link to="/huddle" className="btn btn-success login-btn ml-2">Huddle</Link>

                        
                      </div>
                    )


                  }
              </div>
            </div>	
          </div>	
        </div>
      </div>
   
      <div className="menu">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-sm navbar-dark ">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
  
            <div className="collapse navbar-collapse in" id="navbarSupportedContent">
              <ul className="nav navbar-nav mr-auto">
                  <li className="nav-item">
                    <a href= "/deals/electronics" className={cat =='electronics'?"nav-link active":'nav-link'} >ELECTRONICS</a>
                  </li>
                  <li className="nav-item">
                    <a href= "/deals/home" className={cat =='home'?"nav-link active":'nav-link'} >HOME & LIVING</a>
                  </li>
                  <li className="nav-item">
                    <a href= "/deals/clothing-and-accessories" className={cat =='clothing-and-accessories'?"nav-link active":'nav-link'} >CLOTHING & ACCESSORIES </a>
                  </li>
                  <li className="nav-item">
                    <a href= "/deals/tech" className={cat =='tech'?"nav-link active":'nav-link'} >TECHNOLOGY</a>
                  </li>
                  <li className="nav-item">
                    <a href= "/deals/cameras" className={cat =='cameras'?"nav-link active":'nav-link'} >CAMERAS</a>
                  </li>
                  <li className="nav-item">
                    <a href="/deals/grocery" className={cat =='grocery'?"nav-link active":'nav-link'} >GROCERY</a>
                  </li>
                  <li className="nav-item">
                    <a href ="/deals/games" className={cat =='games'?"nav-link active":'nav-link'} >TOYS & GAMES</a>
                  </li>
                  <li className="nav-item">
                    <a href = "/deals/tv" className={cat =='tv'?"nav-link active":'nav-link'} >TV</a>
                  </li>
                  <li className="nav-item">
                    <a href= "/deals/sports-and-outdoor" className={cat =='sports-and-outdoor'?"nav-link active":'nav-link'} >SPORTS & OUTDOOR</a>
                  </li>
                  <li className="nav-item">
                    <a href= "/deals/health-and-beauty" className={cat =='health-and-beauty'?"nav-link active":'nav-link'} >HEALTH & BEAUTY</a>
                  </li>
                  <li className="nav-item">
                    <a href= "/deals/babies-and-kids" className={cat =='babies-and-kids'?"nav-link active":'nav-link'} >KIDS & BABY </a>
                  </li>
                  <li className="nav-item">
                    <a href= "/deals/entertainment" className={cat =='entertainment'?"nav-link active":'nav-link'} >ENTERTAINMENT</a>
                  </li>
                  <li className="nav-item">
                    <a href= "/deals/other" className={cat =='other'?"nav-link active":'nav-link'} >OTHER</a>
                  </li>                  
                </ul>
              
            </div>
          </nav>
        </div>	
      </div>
    
  </header>

    );
  }
}

const mapStateToProps = state => ({
 userInfo:state.profileReducer.result,
 userDetailsInfo: state.userDetailsReducer.result,


})
const mapDispatchToProps = dispatch => ({
  getUser: () => dispatch(getUserAction()),
  getPopularCats: () => dispatch(getPopularCatsAction()),
  getUserDetails: () => dispatch(getUserDetailsAction()),

})
export default connect(mapStateToProps, mapDispatchToProps)(Header);