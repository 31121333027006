import axios from 'axios';
import {url, ACCESS_TOKEN} from '../../helpers/constants';

const headers = {
  'Content-Type': 'application/json',
  'accessToken': ACCESS_TOKEN,
  'Access-Control-Allow-Origin': 'http://localhost:3000/'
}

export const registerAction = (data) => dispatch => {     
     axios.post(url+'/api/register',
     {'firstName':data.firstName,'lastName':data.lastName,'email':data.email,'password':data.password} 
  
              )
               .then(res=>{
                 if(res.data.success === true){
               
                   dispatch({
                    type: 'REGISTER_SUCCESS',
                    payload: 'success'
                   })                   
                 }else{                 
                  localStorage.clear();
                    dispatch({
                        type: 'REGISTER_FAILURE',
                        payload: res.data
                       })
                 }
        })


  
   }

   export const loginAction = (data) => dispatch => { 
         dispatch({ type: 'CHECKING_LOGIN' });

         const formData = new FormData
         formData.append('email', data.email)
         formData.append('password', data.password)


         axios.post(url + 'api/login', formData)
              .then(res=>{
                if(res.data.status == 'success'){
                  localStorage.setItem('myToken',res.data.token);
                  localStorage.setItem('myStatus',"logged_in");                
                  dispatch({
                   type: 'LOGIN_SUCCESS',
                   payload: 'login_success'
                  })     
                  window.location.href = '/';             
                }else{
                  localStorage.setItem('myStatus','logged_out');
                   dispatch({
                       type: 'LOGIN_FAILURE',
                       payload: res.data
                    })
                }
           }) 
  }
   export const getUserAction = () => dispatch => { 

    let token = localStorage.getItem('myToken');
    if(token){

      dispatch({
        type: 'GET_USER_BEGINS',
     
       })

   axios.get(url+'/api/profile',{
    headers:{Authorization:`Bearer ${token}`}
})
              .then(res=>{
          
                if(res.data.response == "success"){
                          
                  dispatch({
                   type: 'GET_USER_SUCCESS',
                   payload: res.data
                  })

                  //dealLikeReducer setting
                  
                  if(res.data && res.data.mylikes && res.data.mydislikes){

                    dispatch({
                      type: 'SET_LIKE_DISLIKE_IDS',
                      payload:{likeids: res.data.mylikes, dislikeids: res.data.mydislikes}
                     })
   
                 
                    // Object.keys(res.data.mylikes).map(function(key, index) {
                    //   dispatch({
                    //     type: 'DEAL_LIKE_SUCCESS',
                    //     payload:  {'id':res.data.mylikes[key],'count':null}
    
                    // })
                    // });
              

                  }
                 
                    //huddleLikeReducer setting
                  
                    if(res.data && res.data.myforumlikes){
                     
                       Object.keys(res.data.myforumlikes).map(function(key, index) {
                         dispatch({
                           type: 'HUDDLE_LIKE_SUCCESS',
                           payload:  {'id':res.data.myforumlikes[key],'count':null}
       
                       })
                       });
                 
   
                     }
                     if(res.data && res.data.myforumdislikes){
                  
                       Object.keys(res.data.myforumdislikes).map(function(key, index) {
                         dispatch({
                           type: 'HUDDLE_DISLIKE_SUCCESS',
                           payload:  {'id':res.data.myforumdislikes[key],'count':null}
       
                       })
                       });
                 
   
                     }
                         
                }else{
                  localStorage.clear();

                   dispatch({
                       type: 'GET_USER_FAILURE',
                       payload: res.data
                    })
                   
                }
           }) 

    }else{
      localStorage.clear();
      dispatch({
        type: 'GET_USER_FAILURE',
        payload: 'Session timed out'
     })

    }
      
  }
   export const getUserDetailsAction = () => dispatch => { 

    let token = localStorage.getItem('myToken');
    if(token){

      dispatch({
        type: 'GET_USER_DETAILS_BEGINS',
     
       })

   axios.get(url+'/api/profile',{
    headers:{Authorization:`Bearer ${token}`}
})
              .then(res=>{
            // console.log('profileresponse', res)
                if(res.data.response == "success"){
                          
                  dispatch({
                   type: 'GET_USER_DETAILS_SUCCESS',
                   payload: res.data
                  })
                  
                 
                         
                }else{
                  localStorage.clear();

                   dispatch({
                       type: 'GET_USER_DETAILS_FAILURE',
                       payload: res.data
                    })
                   
                }
           }) 

    }else{
      localStorage.clear();
      dispatch({
        type: 'GET_USER_DETAILS_FAILURE',
        payload: 'Session timed out'
     })

    }
      
  }

  export const verifyTokenAction = (token) => dispatch => {     
  
 
    axios.post(url+'/api/activation',
    {token}
             )
              .then(res=>{
                if(res.data.response === 'success'){
                  localStorage.clear();
                  dispatch({
                   type: 'TOKEN_VERIFICATION_SUCCESS',
                   payload: 'success'
                  })

                 
                   
                }else{                 
                  
                   dispatch({
                       type: 'TOKEN_VERIFICATION_FAILURE',
                       payload: res.data
                      })
                }
       })


 
  }
  export const unsubscribeAction = (token) => dispatch => {     

      
    dispatch({
      type: 'UNSUBSCRIBE_BEGINS',
      payload: ''
     })
  
 
    axios.post(url+'api/unsubscribe',
    {token}
             )
              .then(res=>{
                if(res.data.response === 'success'){
                
                  dispatch({
                   type: 'UNSUBSCRIBE_SUCCESS',
                   payload: res.data.message
                  })

                 
                   
                }else{                 
                  
                   dispatch({
                       type: 'UNSUBSCRIBE_FAILURE',
                       payload: res.data.message
                      })
                }
       })


 
  }
   export const clearReducerAction = () => dispatch => { 
         dispatch({ type: 'CLEAR_RESULTS' });
       
  }
   export const clearTokenVerificationMsgAction = () => dispatch => { 
         dispatch({ type: 'TOKEN_VERIFICATION_CLEAR' });
       
  }
   export const clearUnsubscribeMEssage = () => dispatch => { 
         dispatch({ type: 'UNSUBSCRIBE_CLEAR' });
       
  }