import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Header from '../components/auth/Header';
import Footer from '../components/auth/Footer';
import {unsubscribeAction, clearUnsubscribeMEssage} from '../actions/auth/authAction'
import {  Card, Button, CardTitle, CardText } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


function Unsubscribe (props) {
  const dispatch = useDispatch()

  const actionResult = useSelector(state=> state.unsubscribeReducer.result)
  const actionLoader = useSelector(state=> state.unsubscribeReducer.loader)

  useEffect(() => {


    const token = props.match.params.token

    if(token){
          //console.log("token", token)
 
          dispatch(unsubscribeAction(token));
 
    }

    return (()=> dispatch(clearUnsubscribeMEssage()))


  }, []);


        return(
         <div>
            <Header {...props} />
      <section className="main">
{!actionLoader?<div className="row">

  <div className="col-12">

  <Card body className="text-center">
        <CardTitle>Subscription</CardTitle>
        <CardText>{actionResult && actionResult}</CardText>
        <Link to= "/"  > <Button>Go Home</Button></Link>
      </Card>

  </div>


</div>

: <div className="row">

<div className = "spinner-border"></div>
        </div>}

</section>

<Footer />
         </div>
        ) }

    

 export default Unsubscribe