import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';




const MasterTrashPopup = (props) => {

 
  const {
   isOpentrash,
   trashId
  } = props;

  const [modal, setModal] = useState(isOpentrash);
  const [submitted, setSubmitted] = useState(false);
 




  const toggle = () =>{
    
    
  
    if(modal){
   
      props.closeModal()
      
    }
    setModal(!modal);
    setSubmitted(false)
    


  
  }
  const onSubmit = (e) =>{

    setSubmitted(true)

    e.preventDefault();
    if(trashId){
props.onDelete(trashId)
  
    }

  

 
  

  
  }


  return (
    <div>
     
      {/* <button type="button" className="btn small"  >Change Password</button> */}
      <Modal isOpen={modal} toggle={toggle} className={"modal-dialog-centered"}>
      <ModalHeader toggle={toggle}>Suspend</ModalHeader>
     

        <ModalBody>
      Are you sure , you want to suspend this item?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onSubmit}>{submitted ?"Please wait":"Yes"}</Button>
          {!submitted&&<Button color="secondary" onClick={toggle}>{"No"}</Button>}
        </ModalFooter>
      </Modal>
      </div>
  
  );
}

export default MasterTrashPopup;