
export default (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_USER_BEGINS':
      return {
        error: false,
        loader: true,
        result: false
      }
    case 'UPDATE_USER_SUCCESS':
      return {
        error: false,
        loader: false,
        result: true
      }
    case 'UPDATE_USER_FAILURE':
      return {
        error: true,
        loader: false,
        result: false
      }

    case 'UPDATE_USER_CLEAR':
      return {
        error: false,
        loader: false,
        result: false
      }
    default:
      return state
  }
}