import axios from 'axios';
import {url} from '../helpers/constants';



export const getEmailList = () => dispatch => {

  

    dispatch({
        type: 'LIST_EMAILS_BEGINS',
       

    })
    
    axios.post(url+'/api/emailList',{}, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.data.response === 'success') {
        
                dispatch({
                    type: 'LIST_EMAILS_SUCCESS',
                    payload: res.data.data

                })

             
            } else {
                dispatch({
                    type: 'LIST_EMAILS_FAILURE',
                    payload: res.data.message

                })

            }
        })

     
}
export const listInbox = (formData) => dispatch => {

  

    dispatch({
        type: 'MAIL_LIST_BEGINS',
       

    })
    
    axios.post(url+'/api/getMessages',formData, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.data.response === 'success') {
        
                dispatch({
                    type: 'MAIL_LIST_SUCCESS',
                    payload: res.data

                })

             
            } else {
                dispatch({
                    type: 'MAIL_LIST_FAILURE',
                    payload: res.data.message

                })

            }
        })

     
}
export const listSentItems = (formData) => dispatch => {

    dispatch({
        type: 'MAIL_LIST_BEGINS',
       

    })
    
    axios.post(url+'/api/sentMessages',formData, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.data.response === 'success') {
        
                dispatch({
                    type: 'MAIL_LIST_SUCCESS',
                    payload: res.data

                })

             
            } else {
                dispatch({
                    type: 'MAIL_LIST_FAILURE',
                    payload: res.data.message

                })

            }
        })

     
}
export const sendMail = (formData) => dispatch => {

    dispatch({
        type: 'MAIL_ACTION_BEGINS',
       

    })
    
    axios.post(url+'/api/sendMessage',formData, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.data.response === 'success') {
        
                dispatch({
                    type: 'MAIL_ACTION_SUCCESS',
                    payload: res.data.message

                })

                setTimeout(() => {
                    dispatch(clearMailsAction())
                    
                },2000);

             
            } else {
                dispatch({
                    type: 'MAIL_ACTION_FAILURE',
                    payload: res.data.message

                })

                setTimeout(() => {
                    dispatch(clearMailsAction())
                    
                },2000);


            }
        })

     
}

export const deleteMails = (id) => dispatch => {

    dispatch({
        type: 'MAIL_ACTION_BEGINS',
        payload: ''

    })

  

    axios.post(url+'/api/deleteMessages',{id}, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.data.response === 'success') {
            
                dispatch({
                    type: 'MAIL_DELETE_SUCCESS',
                    payload: id

                })
                dispatch({
                    type: 'MAIL_ACTION_SUCCESS',
                    payload: res.data.message

                })
                setTimeout(() => {
                    dispatch(clearMailsAction())
                    
                },2000);

              
            } else {
                dispatch({
                    type: 'MAIL_ACTION_FAILURE',
                    payload: res.data.message

                })
                setTimeout(() => {
                    dispatch(clearMailsAction())
                    
                },2000);

               
            }
        })

      
}
export const readMessage = (id) => dispatch => {

    dispatch({
        type: 'MAIL_READ_BEGINS',
        payload: ''

    })

   

    axios.post(url+'/api/updateMessageStatus',{id}, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.data.response === 'success') {

                dispatch({
                    type: 'MAIL_READ_SUCCESS',
                    payload: id
            
                })
            
              
            } else {

                dispatch({
                    type: 'MAIL_READ_FAILURE',
                    payload: id
            
                })
            
            }
        })

      
}


export const clearMailsAction = () => dispatch => {

    dispatch({
                   type: 'MAIL_ACTION_CLEAR'
                 

             })
}

