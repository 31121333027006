import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import DatePicker from "react-datepicker";
import {subDays, addDays} from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import {saveUserAction, clearUserUpdateMsg} from '../actions/profileAction'
import {connect} from 'react-redux' 

import validateUserData from '../components/validation/userValidation';
import ChangeAvatar from './changeAvatar';
import ChangePassword from './changePassword';

let current_datetime=null
class EditUser extends Component {

   constructor(props){

      super(props)
this.handleChangeDob = this.handleChangeDob.bind(this)
this.onChangeDobShow = this.onChangeDobShow.bind(this)
this.onChangeTimeZone = this.onChangeTimeZone.bind(this)
this.onChangeText = this.onChangeText.bind(this)
this.onChangeRole = this.onChangeRole.bind(this)
this.onChangeStatus = this.onChangeStatus.bind(this)
this.handleDateChangeRaw = this.handleDateChangeRaw.bind(this)
this.saveUser = this.saveUser.bind(this)
this.state = {
   isLoading:false,
   formData:{
      id:'',
      firstName:'',
      lastName:'',
      email:'',
      dob:null,
      avatar:'',
      address:'',
      hideDate: false,
      timeZone:'',
      zipCode:'',
      role:'',
      status:'',
      adminMode:true

   },
   errors:{}
}


   }

   handleChangeDob = date => {
      this.setState({
         formData:{
            ...this.state.formData,
            dob: date

         }
      });
    };
    onChangeDobShow = (e) => {
      this.setState({
         formData:{
            ...this.state.formData,
            hideDate: e.target.value

         }
      });
    };
    handleDateChangeRaw = (e) => {
      e.preventDefault();
    
    };
    onChangeTimeZone = (e) => {
      this.setState({
         formData:{
            ...this.state.formData,
            timeZone: e.target.value

         }
      });
    };
    onChangeRole = (e) => {
      this.setState({
         formData:{
            ...this.state.formData,
            role: e.target.value

         }
      });
    };
    onChangeStatus = (e) => {
      this.setState({
         formData:{
            ...this.state.formData,
            status: e.target.value

         }
      });
    };
    onChangeText = (e) => {

    
      this.setState({
         formData:{
            ...this.state.formData,
            [e.target.name]: e.target.value

         }
      });
    };
    saveUser = (e) => {
       e.preventDefault()
       if(this.isValid()){
      
         this.setState({
           isLoading:true,
         })

        

         this.props.saveUserAction(this.state.formData);     
         
       }else{
         // console.log(this.state.errors);
       }

       setTimeout(() => {

         window.scrollTo(0, 0)

          
       },1000);
    };

    isValid(){
      const {errors, isValid} =  validateUserData(this.state.formData);
     
      if(!isValid){
         this.setState({errors:errors});
      }   
      return isValid;
    }

 


  componentDidMount(){

   this.nameInput.focus();
  current_datetime=null
   if(this.props.userDetailsInfo.dob){
      current_datetime = new Date(this.props.userDetailsInfo.dob)
      // current_datetime = current_datetime.getDate() + "/" + (current_datetime.getMonth() + 1) + "/" + current_datetime.getFullYear()
      

   }


   this.setState({

formData:{
   id:this.props.userDetailsInfo.id,
   firstName:this.props.userDetailsInfo.firstName,
   lastName:this.props.userDetailsInfo.lastName,
   email:this.props.userDetailsInfo.email,
   dob:current_datetime!=null?addDays(current_datetime,1):null,
   address:this.props.userDetailsInfo.address,
   hideDate: this.props.userDetailsInfo.hideDate,
   timeZone:this.props.userDetailsInfo.timeZone,
   zipCode:this.props.userDetailsInfo.zipCode,
   city:this.props.userDetailsInfo.city,
   role:this.props.userDetailsInfo.role,
   status:this.props.userDetailsInfo.status,
   adminMode:true,
   avatar:this.props.userDetailsInfo.avatar,
}

   })



  }
  componentWillReceiveProps(nextProps){

   if(nextProps.updateUserReducer && nextProps.updateUserReducer.result)
   this.setState({

    isLoading:false
      
         })


  }
  componentWillUnmount(){

// this.props.clearUserUpdateMsg()
  }
    render(){

      const { formData, isLoading} = this.state
      const {userInfo} = this.props
return(
  
<div>
  
{!this.props.loader?<div className="account_basic_settings">
      <div className="row pb10 mb10">
         <div className="col-md-5">
            <label>Avatar</label>
         </div>
         <div className="col-md-7">
            <ChangeAvatar myAvatar = {formData.avatar}  disabled = {userInfo.role=='2'?true:false}  />
         
           
          
         </div>
      </div>

     {userInfo.role !=2 && <div className="row pb10 mb10">
         <div className="col-md-5">
            <label>Password</label>
         </div>
         <div className="col-md-7">
         <ChangePassword userId={formData.id} />
      </div>
      </div>}
       <div className="row pb10 mb10">
         <div className="col-md-5">
            <label>Date of Birth</label>
         </div>
         <div className="col-md-7">
            <div className="dob">
                   <div className="row">
                   <div className="col-md-12">

                   <DatePicker
        selected={formData.dob}
        onChange={this.handleChangeDob}
        onChangeRaw={this.handleDateChangeRaw}
        dateFormat="dd/M/yyyy"
        placeholderText="  Click to select a date"
        maxDate={subDays(new Date(), 2555)} //7 years
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        className = "textinput"
       autofocus

       disabled = {userInfo.role=='2'?true:false}
      />
        {this.state.errors.dob && <p className="help-block">{this.state.errors.dob}</p> }
                   </div>
  
           
          
             <div className="col-md-12">
                  <select name="hideDate" onChange = {(e)=>this.onChangeDobShow(e)}
                   disabled = {userInfo.role=='2'?true:false}
                  >
               <option value="1" selected={formData.hideDate==1?'selected':''}>Hide Age and Date of Birth</option>
               <option value="0" selected={formData.hideDate==0?'selected':''}>Display Age and Date of Birth</option>
             </select>
             </div> 

                   </div>
            
            
             
           
                 </div>
         </div>
      </div>
      <div className="row pb10 mb10">
         <div className="col-md-5">
            <label>Personal Information</label>
         </div>
         <div className="col-md-7">
      <label>First Name</label>
      <input id="firstName" name="firstName" value={formData.firstName} type="text" placeholder="First Name" className="textinput" disabled="" onChange={(e)=>this.onChangeText(e)}
       ref={(input) => { this.nameInput = input; }} 
       disabled = {userInfo.role=='2'?true:false}
      />
      {this.state.errors.firstName && <span className="help-block">{this.state.errors.firstName}</span> }
      <label>Last Name</label>
      <input id="lastName" name="lastName" value={formData.lastName} type="text" placeholder="Last Name" className="textinput" disabled="" onChange={(e)=>this.onChangeText(e)}  disabled = {userInfo.role=='2'?true:false} />
      {this.state.errors.lastName && <span className="help-block">{this.state.errors.lastName}</span> }
  
      

      <label>Email</label>
        <input type="text" name="email" value={formData.email} placeholder="Email" className="textinput" disabled="true"/>
        <label>Address</label><br/>
        <textarea name="address" style={{'width':'100%'}} value={formData.address}  placeholder="Address..." className="textinput" onChange={(e)=>this.onChangeText(e)}
         disabled = {userInfo.role=='2'?true:false}
        >
           {formData.address}
        </textarea>
        <label>City</label>
        <input type="text" name="city" value={formData.city} placeholder="City" className="textinput" onChange={(e)=>this.onChangeText(e)}  disabled = {userInfo.role=='2'?true:false} />
      
         </div>	
      </div>
      
      <div className="row pb10 mb10">
         <div className="col-md-5">
            <label>Location Information</label>
         </div>
         <div className="col-md-7">
            <label>Zip Code</label>
            <input type="text" name="zipCode" value={formData.zipCode} className="textinput" onChange={(e)=>this.onChangeText(e)}  disabled = {userInfo.role=='2'?true:false} />
            {/* <button type="button" className="btn small"  disabled="">Save Zip Code</button> */}
         </div>
      </div>
      <div className="row pb10 mb10">
         <div className="col-md-5">
            <label>Time Zone</label>
         </div>
         <div className="col-md-7">
            <p>All timestamps displayed on the forums can be automatically corrected to show the correct time for your location in the world. Simply select the appropriate time zone from the list below.</p>
            <select className="" name="timeZone" onChange={(e)=>this.onChangeTimeZone(e)}
             disabled = {userInfo.role=='2'?true:false}
            >
            <option value="10" selected={formData.timeZone=='10'?"selected":''} >(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
             <option value="-12" selected={formData.timeZone=='-12'?"selected":''} >(GMT -12:00) Eniwetok, Kwajalein</option>
             <option value="-11" selected={formData.timeZone=='-11'?"selected":''} >(GMT -11:00) Midway Island, Samoa</option>
             <option value="-10" selected={formData.timeZone=='-10'?"selected":''} >(GMT -10:00) Hawaii</option>
             <option value="-9" selected={formData.timeZone=='-9'?"selected":''} >(GMT -9:00) Alaska</option>
             <option value="-8" selected={formData.timeZone=='-8'?"selected":''} >(GMT -8:00) Pacific Time (US &amp; Canada)</option>
             <option value="-7" selected={formData.timeZone=='-7'?"selected":''}>(GMT -7:00) Mountain Time (US &amp; Canada)</option>
             <option value="-6" selected={formData.timeZone=='-6'?"selected":''} >(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
             <option value="-5" selected={formData.timeZone=='-5'?"selected":''} >(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
             <option value="-4.5" selected={formData.timeZone=='-4.5'?"selected":''} >(GMT -4:30) Caracas</option>
             <option value="-4" selected={formData.timeZone=='-4'?"selected":''} >(GMT -4:00) Atlantic Time (Canada), La Paz, Santiago</option>
             <option value="-3.5" selected={formData.timeZone=='-3.5'?"selected":''} >(GMT -3:30) Newfoundland</option>
             <option value="-3" selected={formData.timeZone=='-3'?"selected":''} >(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
             <option value="-2" selected={formData.timeZone=='-2'?"selected":''} >(GMT -2:00) Mid-Atlantic</option>
             <option value="-1" selected={formData.timeZone=='-1'?"selected":''} >(GMT -1:00 hour) Azores, Cape Verde Islands</option>
             <option value="0" selected={formData.timeZone=='0'?"selected":''} >(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
             <option value="1" selected={formData.timeZone=='1'?"selected":''} >(GMT +1:00 hour) Brussels, Copenhagen, Madrid, Paris</option>
             <option value="2" selected={formData.timeZone=='2'?"selected":''} >(GMT +2:00) Kaliningrad, South Africa</option>
             <option value="3" selected={formData.timeZone=='3'?"selected":''} >(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
             <option value="3.5" selected={formData.timeZone=='3.5'?"selected":''} >(GMT +3:30) Tehran</option>
             <option value="4" selected={formData.timeZone=='4'?"selected":''} >(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
             <option value="4.5" selected={formData.timeZone=='4.5'?"selected":''}  >(GMT +4:30) Kabul</option>
             <option value="5" selected={formData.timeZone=='5'?"selected":''} >(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
             <option value="5.5" selected={formData.timeZone=='5.5'?"selected":''} >(GMT +5:30) Mumbai, Kolkata, Chennai, New Delhi</option>
             <option value="5.75" selected={formData.timeZone=='5.75'?"selected":''} >(GMT +5:45) Kathmandu</option>
             <option value="6" selected={formData.timeZone=='6'?"selected":''} >(GMT +6:00) Almaty, Dhaka, Colombo</option>
             <option value="6.5" selected={formData.timeZone=='6.5'?"selected":''} >(GMT +6:30) Yangon, Cocos Islands</option>
             <option value="7" selected={formData.timeZone=='7'?"selected":''} >(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
             <option value="8" selected={formData.timeZone=='8'?"selected":''} >(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
             <option value="9" selected={formData.timeZone=='9'?"selected":''} >(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
             <option value="9.5" selected={formData.timeZone=='9.5'?"selected":''} >(GMT +9:30) Adelaide, Darwin</option>
            
             <option value="11" selected={formData.timeZone=='11'?"selected":''} >(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
             <option value="12" selected={formData.timeZone=='12'?"selected":''} >(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
         </select>
            {/* <button type="button" className="btn small"  >Save Time Zone Options</button> */}
         </div>
      </div>
      <div className="row pb10 mb10">
         <div className="col-md-5">
            <label>Role</label>
         </div>
         <div className="col-md-7">

         <select  name="role" onChange={(e)=>this.onChangeRole(e)}  disabled = {userInfo.role!='2'?true:false}  >
             {/* <option selected={formData.role==1?"selected":''} disabled value="1" >Super Admin</option> */}
             <option selected={formData.role==2?"selected":''} value="2" >Admin</option>
             <option selected={formData.role==3?"selected":''} value="3" >Moderator</option>
             <option selected={formData.role==4?"selected":''} value="4" >Normal User</option>
             <option selected={formData.role==5?"selected":''} value="5" >Store Rep.</option>
             </select>
            </div>
           
            </div>
      <div className="row pb10 mb10">
         <div className="col-md-5">
            <label>Status</label>
         </div>
         <div className="col-md-7">

         <select  name="role" onChange={(e)=>this.onChangeStatus(e)}  >
           
             <option selected={formData.status==1?"selected":''} value="1" >Active</option>
             <option selected={formData.status==3?"selected":''} value="3" >Disabled</option>
            
             </select>
            </div>
           
            </div>
          


      <div className="row">
         <div className="col-md-12">
            <button onClick={(e)=>this.saveUser(e)} type="submit" className="btn save_btn primary small">{this.props.updateLoader?'Updating...':'Save Settings'}</button>
         </div>
      </div>
   </div>:<div className="spinner-border"></div>}
</div>
  


          
        ) }

}

const mapStateToProps = state => ({
 
  updateResult:state.updateUserReducer.result,
  updateLoader:state.updateUserReducer.loader,
  userInfo: state.profileReducer.result,
 
 })
 const mapDispatchToProps = dispatch => ({
  

   saveUserAction:(formData)=>dispatch(saveUserAction(formData)),
   clearUserUpdateMsg:()=>dispatch(clearUserUpdateMsg()),
 
 })
 export default connect(mapStateToProps, mapDispatchToProps)(EditUser)