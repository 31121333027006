import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../components/auth/Footer';
import Header from '../components/auth/Header';
import CKEditor from "react-ckeditor-component";
import  classnames from 'classnames';
import  {Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { isEmpty } from 'lodash';
import TextEditor from '../components/TextEditor';
import axios from 'axios';
import {url} from '../helpers/constants';
function CreateHuddle(props) {
const inputRef = useRef(null)
  

const [formData, setFormData] = useState({ huddle_title: '', huddle_description: '' })
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(false)
  const [loader, setLoader] = useState(false)
  const [alert, setAlert] = useState("")
  

  const dispatch = useDispatch()

  const userInfo = useSelector(state => state.profileReducer.result)
  const userLoader = useSelector(state => state.profileReducer.loader)
 

  useEffect(() => {
    if (!localStorage.getItem('myToken')) {
      props.history.push('/login')
   }

    inputRef && inputRef.current.focus()


  }, []);
  useEffect(() => {


    if(!userLoader && !userInfo){
      props.history.push('/login')
    
    }
    
      }, [userInfo]);
 



  const handleChange = (e) => {

  let newfmDt = {
    ...formData,
    huddle_title: e.target.value  
  }

    setFormData(newfmDt)
   

    if(submitted){
      isValid({ ...formData, huddle_title: e.target.value})
    }
  }
  const onChangeDescription=(newContent)=> {

   

 
   
    let preFormData ={
      ...formData,
      huddle_description: newContent 
    }
    setFormData(preFormData)
if(submitted){
  isValid({...formData, huddle_description:newContent})
}

}
  const saveHuddle = async(e) => {
    e.preventDefault()
    setSubmitted(true)

    if(isValid(formData)){

      setLoader(true)

//action
await axios.post(url+'/api/addForum', formData,{
  headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
}
)
  .then(res => {

      if (res.data.success) {
        setFormData({ huddle_title: '', huddle_description: ''})
        setSubmitted(false)
        setLoader(false)
        setAlert("success")
       
        setErrors({})
        
      } else {

        setLoader(false)
        setSubmitted(false)
        setAlert("error")

      }
  })


    }



  }
  const cancelForm = (e) => {
    e.preventDefault()
    setSubmitted(false)
    setErrors({})

  props.history.push("/huddle")



  }
  const toggle = (e) => {
    e.preventDefault()
    setFormData({ huddle_title: '', huddle_description: ''})
    setSubmitted(false)
    setLoader(false)
    setAlert("")
    setErrors({})



  }
  const goTolist = (e) => {
    e.preventDefault()
   props.history.push("/huddle")



  }

  const isValid =(data)=>{

    let errors = {}
    

    if(!data.huddle_title){
      errors.huddle_title = "Please enter title"
    }else if(data.huddle_title.length<3 || data.huddle_title.length>100){
      errors.huddle_title = "Minimum 3 and maximum 100 characters allowed"
    }
    if(!data.huddle_description){
      errors.huddle_description = "Please enter description"
  
    }
    setErrors(errors)
    if(isEmpty(errors)){
     
      return true
    }else return false
  }

  






  


  return (

    <>
      <Header {...props} />


      <section className="main">
        <div className="container-fluid">

          <div className="container dealPosterContainer huddle" id="huddle">
          <div className="row">
							
              <div className="post_deal col-md-12">
                <div className="postdeals_header">
                  <h2>Create New Huddle</h2>
                
                </div>

                <form  onSubmit={saveHuddle} encType="multipart/form-data" >
                
               <div className={classnames("form-group",{'has-error':errors.huddle_title})}>
                    <label  for="huddle_title">Title</label>
                    <input 
                    ref={inputRef}
                    type="text" 
                    name="huddle_title" className="form-control" placeholder="Title" id="huddle_title" value={formData.huddle_title} onChange={handleChange} 
                    />
                  
                    {errors.huddle_title && <p  className="help-block">{errors.huddle_title}</p>}
                </div>

                <div className={classnames("form-group",{"has-error":errors.huddle_description})}>
                    <label   for="dealescription">Description</label>
                  <TextEditor handleChange={onChangeDescription} />
                    {errors.huddle_description  ? <p className="help-block" >{errors.huddle_description }</p> : ''}
                </div>
                <div className="form-group">
                {loader ? <div className="spinner-border"></div>:<> <input type="submit" name="" value={"Save"} className="btn btn-primary" /><a href="#" onClick= {cancelForm}  className="btn btn-secondary">Cancel</a></>}
                </div>

                </form>

                </div>
                </div>
          
        
          </div>



        </div>
      </section>

      <Footer />

      {/* AlertModal */}
      <Modal isOpen={alert}  backdrop={"static"} keyboard={false}>
        <ModalHeader >{alert=="success"?"Success":"Sorry"}</ModalHeader>
        <ModalBody>
        {alert=="success"?"Huddle created successfully":"Some error occurred. Please try after some time"}
        </ModalBody>
        <ModalFooter>
          {/* <Button color="secondary" onClick={toggle}>Add New</Button>{' '} */}
          <Button color="primary" onClick={goTolist}>Go to List</Button>
        </ModalFooter>
      </Modal>
    </>
  )



}

export default CreateHuddle