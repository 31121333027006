import React, { Component } from 'react';
import Header from '../components/auth/Header';
import Footer from '../components/auth/Footer';
import DealProgress from './dealProgress';
import PostDealForm from '../components/postDealForm';
import { StickyContainer, Sticky } from 'react-sticky';

class PostDeal extends Component {


	constructor(props) {

		super(props)

	

	

	}

	componentDidMount(){

		window.scrollTo(0, 0)

	}




	render() {


		return (

			<div>
				<Header {...this.props} />

				<section className="main">
					<div className="container-fluid">
							<div className="container dealPosterContainer">
							<StickyContainer>
								<div className="row">
							
									<div className="post_deal col-md-8">
										<div className="postdeals_header">
											<h2>Post a New Deal</h2>
											<p>Help others save money by finding the lowest price, best deals, and hot coupons.</p>
										</div>
										<PostDealForm editMode={false} dealData={null} dealCategories={null} dealBrands={null} dealStores={null}  dealImages={null} {...this.props} />
										<div className="deal_note">
											<p><strong>Helpful Deal Posting Tips:</strong>
												Do a quick search on Eassyrun to make sure this is not a repost or that your deal has been posted in the past at a much lower price. Include a breakdown of the price, savings, deal/coupon expiration date, product details as well as merchant information. Also, include steps that may be required to get the deal. Are you in any way related to the seller? (partner, employee, brother?) If so, then do not post, instead please visit our <a href="/corp/advertising.html">Advertising Opportunities</a> page and fill out the form. If you are caught violating this rule, your account will be banned and the selling site will be blocked from Easyrun, possibly permanently.</p>
										</div>
									</div>
									<DealProgress />
								</div>
								</StickyContainer>

							</div>
						
					</div>
				</section>
				<Footer />
			</div>
		)
	}
}

export default PostDeal
