
export default (state = {}, action) => {
  switch (action.type) {
    case 'DEAL_ALERT_STATUSCHANGE_BEGINS':
      return {
        loader: action.payload,
        error: false,
        result: null
      }

    case 'DEAL_ALERT_STATUSCHANGE_SUCCESS':
      return {
        loader: false,
        error: false,
        result: action.payload
      }

    case 'DEAL_ALERT_STATUSCHANGE_FAILURE':
      return {
        loader: false,
        error: action.payload,
        result: null
      }
    default:
      return state
  }
}