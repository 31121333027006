import React, {Component}  from 'react';

import {imagePath} from '../helpers/constants'

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css'; 


class AvatarCrop extends Component {

    constructor(props){

super(props)
this.cropper = null;
this.setCropperRef = element => {
    this.cropper = element;
  };
this._crop = this._crop.bind(this)
    }


    _crop =()=>{

        const dataUrl = this.cropper.getCroppedCanvas().toDataURL();

        this.props.doCrop(dataUrl)
        //console.log("refssss",dataUrl);
   
   }
  
 

 

  render(){



    return (


        <div>{this.props.avtrImage ?  <Cropper
            ref={this.setCropperRef}
             src={this.props.avtrImage}
            style={{height: 300, width: '100%'}}
            // Cropper.js options
            aspectRatio={2 / 2}
            guides={false}
            crop={this._crop.bind(this)}
            zoomable={false}
            // modal = {false}
            // highlight = {false}
            
            />:''}
            
        </div>
       
          
    
    );
}
}
export default AvatarCrop