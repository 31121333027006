import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './../../css/style.css';
import  classnames from 'classnames';
import resetPwdValidateInputs from '../validation/resetPasswordValidation';

import {resetPasswordAction, clearPasswordReducerAction} from '../../actions/auth/passwordAction'
import { connect } from 'react-redux';
import FpwdResult from './fpwdResult'

class ResetPassword extends Component{
  constructor(props){
    super(props);
      this.state ={
     password:'',
     repassword:'',
     token:'',
        errors:{},
        isLoading:false,
      
    };   
    this.onChange =  this.onChange.bind(this);
    this.onSubmit =  this.onSubmit.bind(this);
  }

  isValid(){
    const {errors, isValid} =  resetPwdValidateInputs(this.state);
    if(!isValid){
      this.setState({errors:errors});
   }   
   return isValid;   
   
  }

  componentDidMount(){
    this.nameInput.focus();
    const {token} = this.props.match.params
    this.setState({
      token
    })
  }


  onChange(e){   
    this.setState({errors:''});
    this.setState({[e.target.name]:e.target.value});
    
  }

  onSubmit(e){  
    e.preventDefault();
    const {password, repassword} = this.state


    if(this.isValid()){ 
      this.setState({errors:{},isLoading:true});     

    
      this.props.resetPassword(this.state);      
    }
     
  }


  componentWillUnmount(){

    this.props.clearForgotPasswordMsg()
  }


    render(){
      let {isResetpwdPending, resetPwdError, result} = this.props;
        return (
            <div>
        
        <Header {...this.props}/>
  
  {/* end header */}
  {/* main body */}
  <section className="main">
    <div className="container-fluid">
      <div className="signup_wrap">
        <div className="signup_icon" />
        <h3>Reset password</h3>  
        {result && <FpwdResult {...this.props}  type={"reset"} />}     
        { resetPwdError && (<div className="alert alert-danger text-center" > { resetPwdError } </div>) }
        <form className="needs-validation"  onSubmit={this.onSubmit}>        
       
          <div className="form-group row">
            <div className={classnames("col-sm-12",{'has-error':this.state.errors.password})}>
              <input 
                  name="password" 
                  type="password" 
                  className="form-control" 
                  id="password" 
                  placeholder="Password*" 
                  value={this.state.password}
                  onChange={this.onChange}
                  ref={(input) => { this.nameInput = input; }} 
                 
              />
              {this.state.errors.password && <span className="help-block">{this.state.errors.password}</span> }
            </div>            
          </div>
          <div className="form-group row">
            <div className={classnames("col-sm-12",{'has-error':this.state.errors.repassword})}>
              <input 
                  name="repassword" 
                  type="password" 
                  className="form-control" 
                  id="repassword" 
                  placeholder="Confirm password*" 
                  value={this.state.repassword}
                  onChange={this.onChange}
                  autoComplete={"off"}
                 
              />
              {this.state.errors.repassword && <span className="help-block">{this.state.errors.repassword}</span> }
            </div>            
          </div>
         
         
          <div className="form-group row">
            <div className="col-sm-12">
              <input type="submit" className="btn btn-primary" value={ isResetpwdPending ? "Please wait...":"Submit" } disabled = { isResetpwdPending } />
            </div>
          </div>
        </form>
       
      </div>
    </div>
  </section>
  {/* end main body */}
  {/* footer */}
  <Footer/>
</div>


          
        )
    }
}
const mapStateToProps = state => ({   
     resetPwdError:state.passwordReducer.fpwdError ,
     isResetpwdPending:  state.passwordReducer.isFpwdPending,
     result:state.passwordReducer.result,
     pwdToken:state.pwdTokenAuthReducer.token

 })

const mapDispatchToProps = dispatch => ({
  resetPassword: (data) => dispatch(resetPasswordAction(data)) , 
     clearForgotPasswordMsg: () => dispatch(clearPasswordReducerAction())  
     
 })
 export default connect(mapStateToProps, mapDispatchToProps) (ResetPassword);
