import React, {Component} from 'react';
import validateInput from './../validation/validation';
import Footer from './Footer';
import './../../css/style.css';
import  classnames from 'classnames';
import {Link} from 'react-router-dom'
import Header from './Header';
import { connect } from 'react-redux';
import {registerAction,clearReducerAction} from '../../actions/auth/authAction'
import ReCAPTCHA from "react-google-recaptcha";
import {reCaptchaSiteKey} from '../../helpers/constants'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
const recaptchaRef = React.createRef();
class Signup extends Component{

  constructor(props){
    super(props);  
        this.state ={
            firstName:"",
            lastName:"",
            email: "",
            password: "",
            keepLogged:'',
            subscribe:'',
            recaptcha:'',
            errors:{},
            isLoading:false,
            success:false,
            successModal:false
      };
      this.onSubmit =  this.onSubmit.bind(this);
      this.onChange =  this.onChange.bind(this);
      this.toggle =  this.toggle.bind(this);
      this.onChangeCheckBox =  this.onChangeCheckBox.bind(this);
      this.onChangeCaptcha =  this.onChangeCaptcha.bind(this);
  
  }

  isValid(){
    const {errors, isValid} =  validateInput(this.state);
   
    if(!isValid){
       this.setState({errors:errors});
    }   
    return isValid;
  }

  onSubmit(e){  
    e.preventDefault();
    if(this.isValid()){
      
      this.setState({
        isLoading:true,
      })
      this.props.registerAction(this.state);     
      
    }else{
      // console.log(this.state.errors);
    }
     
  }

  onChange(e){
    this.setState({
      [e.target.name]:e.target.value,
    errors:{
...this.state.errors,
[e.target.name]:false
    }
    });
  }
  toggle(e){
    this.setState({
      successModal:false
    });

    this.props.clearReducer();
     this.props.history.push('/login')


  }
  onChangeCheckBox(e){

   
    if(e.target.checked){
      

      this.setState({
        [e.target.name]:e.target.value,
 
      });
    }else{


      this.setState({
        [e.target.name]:''
  
      });
    }

  }
  
  onChangeCaptcha = (value)=>{
    if(value){
      

      this.setState({
        recaptcha:value,
      errors:{
  ...this.state.errors,
  recaptcha:false
      }
      });
    }else{


      this.setState({
        recaptcha:''
  
      });
    }

  }

  componentWillReceiveProps(nextProps){

    if(nextProps.authReducer.result && nextProps.authReducer.result.email){
     this.setState({
      isLoading:false,
       errors:{
         ...this.state.errors,
         email:nextProps.authReducer.result.email[0]
       }
     })
    }else if(nextProps.authReducer.result && nextProps.authReducer.result.password){
      this.setState({
       isLoading:false,
        errors:{
          ...this.state.errors,
          password:nextProps.authReducer.result.password[0]
        }
      })
     }else if(nextProps.authReducer.result && nextProps.authReducer.result == 'success'){
      this.setState({
       success:true,
       isLoading:false,
       successModal:true
      })

    //  this.props.history.push('/')

    }
  }

  componentDidMount(){
    this.nameInput.focus();

  }
  componentWillUnmount(){


  }


    render(){
  const {success, isLoading} = this.state
  const {regResult} = this.props

        return (
            <div>
      
        <Header {...this.props}/>

 
  {/* end header */}
  {/* main body */}
  
<section className="main">
  <div className="container-fluid">
    <div className="signup_wrap">
      <div className="signup_icon" />
      <h3>Sign Up</h3>
      {/* { success && (<div className="alert alert-success text-center" > { "Successfully registered. Please check your mail for the verification link" } </div>) } */}
      <form className="needs-validation"  onSubmit={this.onSubmit}>
        <div className= "form-group row">
          <div className={classnames("col-sm-6",{'has-error':this.state.errors.firstName})}>
            <input 
                name="firstName" 
                type="text" 
                className="form-control" 
                id="firstName" 
                placeholder="First Name*" 
                value={this.state.firstname}
                onChange={this.onChange}
                ref={(input) => { this.nameInput = input; }} 
             />
            {this.state.errors.firstName && <span className="help-block">{this.state.errors.firstName}</span> }
          </div>
          <div className={classnames("col-sm-6",{'has-error':this.state.errors.lastName})}>           
            <input
                name="lastName"   
                type="text" 
                className="form-control" 
                id="lastName" 
                placeholder="Last Name*" 
                value={this.state.lastname}
                onChange={this.onChange}
             />
           
              {this.state.errors.lastName && <span className="help-block">{this.state.errors.lastName}</span> }
          </div>
        </div>
        <div className="form-group row">
        
          <div className={classnames("col-sm-12",{'has-error':this.state.errors.email})}>     
                         
            <input
              name="email"   
              type="email"
              className="form-control" 
              id="email" 
              placeholder="Email*" 
              value={this.state.email}
              onChange={this.onChange}
             />
           {this.state.errors.email && <span className="help-block">{this.state.errors.email}</span> }
        
          </div>
        </div>
        <div className="form-group row">
          <div className={classnames("col-sm-12",{'has-error':this.state.errors.password})}>             
            <input 
              name="password" 
                type="password"
                className="form-control" 
                id="password" 
                placeholder="Password*" 
                value={this.state.password}
                onChange={this.onChange}
                maxLength={100}
             />         
        
         {this.state.errors.password && <span className="help-block">{this.state.errors.password}</span> }
          </div>
        </div>
        {/* <div className="form-group row">
		            <div className="col-sm-12">
		            	<div className="custom-control checkbox-inline custom-checkbox">
					        <input name="keepLogged" value="1" type="checkbox" className="custom-control-input" id="customCheck" onChange={this.onChangeCheckBox} />
					        <label className="custom-control-label" for="customCheck">Keep me signed in on this computer</label>
				      	</div>
		                
		            </div>
		        </div> */}
        
        <div className="form-group row">
		            <div className="col-sm-12">
		            	<div className="custom-control checkbox-inline custom-checkbox">
                  <input type="checkbox" 
                  className="custom-control-input" 
                  id="customCheck1"
                  value={'1'}
                  name="subscribe"
                  onChange={this.onChangeCheckBox}
                   
                  />
					        <label className="custom-control-label" for="customCheck1">Yes, I want to receive easyrun emails with awesome deals</label>
				      	</div>
		                
		            </div>
		        </div>
		        <div className="form-group row">
            <div className={classnames("col-sm-12",{'has-error':this.state.errors.recaptcha})}>
		        	
                        <div className="g-recaptcha">  <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={reCaptchaSiteKey}
        onChange={this.onChangeCaptcha}
      /></div>
                       
                  
                    {this.state.errors.recaptcha && <span className="help-block">{this.state.errors.recaptcha}</span> }
                </div>
                </div>
        <div className="form-group row">
          <div className="col-sm-12">
            <input type="submit" className="btn btn-primary"  value={!isLoading?"SIGN UP":"Please wait.."} disabled = { (isLoading || !this.state.firstName || ! this.state.lastName || !this.state.password || !this.state.email || !this.state.recaptcha ) } />
          </div>
        </div>
      </form>
      <Link to="/login" className="signin_note">Already have an account? Sign In</Link>
      <div className="disclaimerCopy">By creating an account, you agree to our <a href="/privacy-policy" target="_blank">Privacy Policy</a>.</div>
    </div>
  </div>
</section>


  {/* end main body */}
  {/* footer */}
<Footer/>


{/* success modal */}
<Modal isOpen={this.state.successModal}  backdrop={"static"} keyboard={false}>
        <ModalHeader toggle={this.toggle}>Success</ModalHeader>
        <ModalBody>
        { regResult =='success' && 'Successfully registered. Please check your mail for the verification link' }
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.toggle}>OK</Button>{' '}
        </ModalFooter>
      </Modal>
</div>


           
        )
    }
}



const mapStateToProps = state => ({
...state,
regResult:state.authReducer.result,

 })
const mapDispatchToProps = dispatch => ({
  registerAction: (data) => dispatch(registerAction(data)),
  clearReducer: () => dispatch(clearReducerAction())
 })
export default connect(mapStateToProps, mapDispatchToProps) (Signup);