import React, { Component } from 'react';

import uuid from "uuid";

import $ from "jquery";

class PostDealUrlImages extends Component {

    constructor(props){
super(props)

this.state = {
images:[]

}

this.selectImage = this.selectImage.bind(this);
this.makePrimary = this.makePrimary.bind(this);

    }

    componentDidMount(){
        if(!this.props.primaryStatus){
            $(".image-radio").removeClass('image-radio-checked');
         

        }

    }

    componentWillReceiveProps(nextProps){

        if(!nextProps.primaryStatus){
            $(".image-radio").removeClass('image-radio-checked');
         
       

        }
    }

    makePrimary = (event,img)=>{
        event.preventDefault();

     
        let filename =''
        const {images} = this.state
        let newArr = [...images]
        for(let i = 0; i < newArr.length; i++) {
            if (newArr[i].url == img) {
                newArr[i].primary = true
                filename = img.substring(img.lastIndexOf('/')+1);
            
            }else{

                newArr[i].primary = false
            }
        }

        this.setState({
            images:newArr
           
        })
      

this.props.getUrlImages(newArr)
this.props.dragPrimarySet('url',filename)

    }
    selectImage = (event,img)=>{
event.preventDefault();
        const {images} = this.state

        let newArr = [...images]
    let found = false
        for(let i = 0; i < images.length; i++) {
            if (images[i].url == img) {
                found = true
          let resultArr =  newArr.filter((item)=>{
return item != images[i]
           })

           this.setState({
            images:resultArr
        })
                break;
            }
        }

        if(!found){
           
            newArr.push({'primary':false, 'url':img})

            this.setState({
                images:newArr
            })
        }else{
            this.props.dragPrimarySet('drag','')

        }

      
        

        this.props.getUrlImages(newArr)

    }


    render(){
        const {imageData} = this.props
        const {wrapperClass, radioClass} = this.props
const {images} = this.state



if(!this.props.primaryStatus){
    
    let newArr2 = [...images]
    for(let i = 0; i < newArr2.length; i++) {
     
            newArr2[i].primary = false
          
        
       
    }

}


        return(
            <div className="row urlImgs">
               {     imageData && imageData.map((img, id)=>{

let foundImg = false;
let isPrimary = false;
for(let i = 0; i < images.length; i++) {
    if (images[i].url == img) {
        foundImg = true;
        if(images[i].primary == true){
            isPrimary = true;
         }else isPrimary = false
      
        break;
    }
}

// 
return( <div className="col-lg-4 col-md-6 col-6 text-xs-center" key={id}>
                
<label className={foundImg?`image-checkbox image-checkbox-checked`:`image-checkbox`} onClick={(e)=>this.selectImage(e,img)}>
   <img src={img} />
   
</label>
<label className={isPrimary?`image-radio image-radio-checked`:`image-radio`}  onClick={(e)=>this.makePrimary(e,img)} >
    <i className="fa fa-star" aria-hidden="true" id={img}></i><span className="primary_text">Primary image</span>
   
</label>
</div>)

        })}
        </div>
        )
    }
}
export default PostDealUrlImages