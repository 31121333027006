
export default (state = {}, action) => {
  switch (action.type) {
    case 'POST_DEAL_BEGINS':
      return {
        error: false,
        loader: true,
        result: false,
        updateResult:false
      }
    case 'POST_DEAL_SUCCESS':
      return {
        error: false,
        loader: false,
        result: true,
        updateResult:false

      }
    case 'POST_DEAL_FAILURE':
      return {
        error: true,
        loader: false,
        result: false,
        updateResult:false

      }
    case 'UPDATE_DEAL_SUCCESS':
      return {
        error: false,
        loader: false,
        result: false,
        updateResult:true

      }
    case 'UPDATE_DEAL_FAILURE':
      return {
        error: true,
        loader: false,
        result: false,
        updateResult:false

      }

    case 'POST_DEAL_CLEAR':
      return {
        error: false,
        loader: false,
        result: false,
        updateResult:false

      }
    default:
      return state
  }
}