
export default (state = {}, action) => {
  switch (action.type) {
    case 'POPULAR_CATEGORY_FETCH_BEGINS':
      return {
        loader:true,
        category: [],
        error: false

      }
    case 'POPULAR_CATEGORY_FETCH_SUCCESS':
      return {
        loader:true,
        category: action.payload,
        error: false

      }
    case 'POPULAR_CATEGORY_FETCH_FAILURE':
      return {
        loader:false,
        category: [],
        error: true
      }
    default:
      return state
  }
}