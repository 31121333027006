import React, { Component } from 'react';

class GoogleAd extends Component {
  constructor(props) {
    super(props)
  
  }

  componentDidMount() {

    window.adsbygoogle = window.adsbygoogle || []
    window.adsbygoogle.push({})



  }

  render() {
    return (   
            
                
<ins class="adsbygoogle"
     style={{"display":"block"}}
     data-ad-client="ca-pub-1474025743943452"
     data-ad-slot="3747020819"
     data-ad-format="auto"
     data-full-width-responsive="true">

     </ins>
    )}

}

export default GoogleAd


