import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../components/auth/Footer';
import Header from '../components/auth/Header';
import CKEditor from "react-ckeditor-component";
import  classnames from 'classnames';
import  {Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { isEmpty } from 'lodash';
import TextEditor from '../components/TextEditor';
import axios from 'axios';
import {url} from '../helpers/constants';
import {  getHuddleDetailsAction } from '../actions/huddleAction';
import { dealEditablePeriod} from '../helpers/constants'
import jwt from 'jwt-decode'

function EditHuddle(props) {
const inputRef = useRef(null)
  

const [formData, setFormData] = useState({id:'', huddle_title: '', huddle_description: '' })
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(false)
  const [loader, setLoader] = useState(false)
  const [alert, setAlert] = useState("")
  

  const dispatch = useDispatch()

  const userInfo = useSelector(state => state.profileReducer.result)
  const userLoader = useSelector(state => state.profileReducer.loader)

  const huddleData = useSelector(state => state.huddleDetailsReducer.result &&state.huddleDetailsReducer.result.forum)
  const huddlUser = useSelector(state => state.huddleDetailsReducer.result &&state.huddleDetailsReducer.result.user)
  const huddleLoader = useSelector(state => state.huddleDetailsReducer.loader)
 

  useEffect(() => {
    if (!localStorage.getItem('myToken')) {
      props.history.push('/login')
   }

    inputRef &&inputRef.current && inputRef.current.focus()

    let args = {
			forumId: props.match.params.id,
      userId:'',
      sessionId: sessionStorage.getItem('easySession')
		}

		let token = localStorage.getItem('myToken');
		if(token){

		   let decodedData = jwt(token); // decode your token here
		   args.userId = decodedData.sub
		 



		}	
    dispatch(getHuddleDetailsAction(args))


  }, []);
  useEffect(() => {


    if(!userLoader && !userInfo){
      // props.history.push('/login')
    
    }
    
      }, [userInfo]);

  useEffect(() => {

if(huddleData)
 {  setFormData({
   id: huddleData.id,
     huddle_title: huddleData.for_title,
     huddle_description:huddleData.for_descr
   })}
    
      }, [huddleData]);
 



  const handleChange = (e) => {

  let newfmDt = {
    ...formData,
    huddle_title: e.target.value  
  }

    setFormData(newfmDt)
   

    if(submitted){
      isValid({ ...formData, huddle_title: e.target.value})
    }
  }
  const onChangeDescription=(newContent)=> {

   

 
   
    let preFormData ={
      ...formData,
      huddle_description: newContent 
    }
    setFormData(preFormData)
if(submitted){
  isValid({...formData, huddle_description:newContent})
}

}
  const saveHuddle = async(e) => {
    e.preventDefault()
    setSubmitted(true)

    if(isValid(formData)){

      setLoader(true)

//action
await axios.post(url+'/api/updateForum', formData,{
  headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
}
)
  .then(res => {

      if (res.data.success) {
        setFormData({ huddle_title: '', huddle_description: ''})
        setSubmitted(false)
        setLoader(false)
        setAlert("success")
       
        setErrors({})
        
      } else {

        setLoader(false)
        setSubmitted(false)
        setAlert("error")

      }
  })


    }



  }
  const cancelForm = (e) => {
    e.preventDefault()
    setSubmitted(false)
    setErrors({})

  props.history.push("/huddle")



  }
  const toggle = (e) => {
    e.preventDefault()
    setFormData({ huddle_title: '', huddle_description: ''})
    setSubmitted(false)
    setLoader(false)
    setAlert("")
    setErrors({})



  }
  const toggleGoto = (e) => {
    e.preventDefault()
    setFormData({ huddle_title: '', huddle_description: ''})
    setSubmitted(false)
    setLoader(false)
    setAlert("")
    setErrors({})
    props.history.push("/huddle/"+props.match.params.id)



  }
  const goTolist = (e) => {
    e.preventDefault()
    setFormData({ huddle_title: '', huddle_description: ''})
    setSubmitted(false)
    setLoader(false)
    setAlert("")
    setErrors({})
   props.history.push("/huddle")



  }

  const isValid =(data)=>{

    let errors = {}
    

    if(!data.huddle_title){
      errors.huddle_title = "Please enter title"
    }else if(data.huddle_title.length<3 || data.huddle_title.length>100){
      errors.huddle_title = "Minimum 3 and maximum 100 characters allowed"
    }
    if(!data.huddle_description){
      errors.huddle_description = "Please enter description"
  
    }
    setErrors(errors)
    if(isEmpty(errors)){
     
      return true
    }else return false
  }

  



//check for edit period
let shwEditForUser = false
let shwEditForAdmin = false
let d1;
let d2;
if(huddleData && userInfo && huddlUser){
	 d1 = new Date();
	 d2 = new Date(huddleData.for_created_at);
	 d2.setDate(d2.getDate() + dealEditablePeriod);



	 if((d1.getTime() <= d2.getTime()) && userInfo.id ===huddlUser.id){
		shwEditForUser = true
	 }
	 if(userInfo.role ===2 || userInfo.role ===3){
		shwEditForAdmin = true
	 }

}
//check for edit period ends here




  


  return (

    <>
      <Header {...props} />


      <section className="main">
        <div className="container-fluid">

          <div className="container dealPosterContainer huddle" id="huddle">
          <div className="row">
							
             {(!huddleLoader && huddleData)? <div className="post_deal col-md-12">
                <div className="postdeals_header">
                  <h2>Edit Huddle</h2>
                
                </div>

                {(shwEditForAdmin || shwEditForUser)?<form  onSubmit={saveHuddle} encType="multipart/form-data" >
                
               <div className={classnames("form-group",{'has-error':errors.huddle_title})}>
                    <label  for="huddle_title">Title</label>
                    <input 
                    ref={inputRef}
                    type="text" 
                    name="huddle_title" className="form-control" placeholder="Title" id="huddle_title" value={formData.huddle_title} onChange={handleChange} 
                    />
                  
                    {errors.huddle_title && <p  className="help-block">{errors.huddle_title}</p>}
                </div>

                <div className={classnames("form-group",{"has-error":errors.huddle_description})}>
                    <label   for="dealescription">Description</label>
                  <TextEditor handleChange={onChangeDescription} content={formData.huddle_description} key={"1"} />
                    {errors.huddle_description  ? <p className="help-block" >{errors.huddle_description }</p> : ''}
                </div>
                <div className="form-group">
                {loader ? <div className="spinner-border"></div>:<> <input type="submit" name="" value={"Update"} className="btn btn-primary" /><a href="#" onClick= {cancelForm}  className="btn btn-secondary">Cancel</a></>}
                </div>

                </form>: <p className="text-center">Sorry! You can't edit this huddle</p>}

                </div>:<div className="spinner-border"></div>}
                </div>
          
        
          </div>



        </div>
      </section>

      <Footer />

      {/* AlertModal */}
      <Modal isOpen={alert}  backdrop={"static"} keyboard={false}>
        <ModalHeader >{alert=="success"?"Success":"Sorry"}</ModalHeader>
        <ModalBody>
        {alert=="success"?"Huddle updated successfully":"Some error occurred. Please try after some time"}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleGoto}>View Huddle</Button>{' '}
          <Button color="primary" onClick={goTolist}>Go to List</Button>
        </ModalFooter>
      </Modal>
    </>
  )



}

export default EditHuddle