import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux' 
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { clearUserUpdateMsg } from '../actions/profileAction'
import EditFlaggedComments from './editFlaggedComments';


const { SearchBar } = Search;

const text_truncate = (str, length, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};


class AllCommentsList extends Component {

   constructor(props){

      super(props)

      this.state = {
        isOpen:false,
        editData:[],
        modalId:'',
        currentPage: 1,
        perPage: 10
      }


this.formatAction = this.formatAction.bind(this)
this.editClick = this.editClick.bind(this)
this.closeModal = this.closeModal.bind(this)
this.formatSlno = this.formatSlno.bind(this)
this.formatComment = this.formatComment.bind(this)
this.onPageChange = this.onPageChange.bind(this)


   }

   onPageChange = (page, perPage) => {

 

    this.setState({
      currentPage: page,
      perPage: perPage
    })


  };

   formatAction=(row,e)=>{

      return (<a title="edit" onClick ={(event)=>{event.preventDefault(); this.editClick(row,e)}} href="#"> <i className="fa fa-pencil"></i></a>)
   }
   formatSlno = (cell, row, rowIndex, ) => {
    const { currentPage, perPage } = this.state

   let slNo=rowIndex+1

  
    slNo = (currentPage * perPage + slNo) - perPage
 
   
     

    
    return slNo


  }
   formatComment=(cell, e)=>{


let tmp = document.createElement("DIV");
   tmp.innerHTML = cell;
   return    text_truncate(tmp.textContent||tmp.innerText, 10, '...') ;

    
   }
   editClick=(row, e)=>{

this.setState({
isOpen:true,
editData:row,
  modalId:e+1
})

    
   }
   closeModal=()=>{

this.setState({
isOpen:false,
editData:{},
  modalId:''
})

    
   }
 



  componentDidMount(){



  }

  componentWillReceiveProps(nextProps){



  }
    render(){
      const columns = [{
         dataField: 'id',
         text: '#',
         formatter: this.formatSlno

       }, {
         dataField: 'del_title',
         text: 'Deal Title'
       },
        {
         dataField: 'dcm_comment',
         text: 'Comment',
         formatter: (cell) => this.formatComment(cell)
         
       },
        {
         dataField: 'id',
         text: 'Action',
         formatter: (cell, row,e) => this.formatAction(row,e)
       },
      
      ];
const {commentsListLoader,commentsListResult, cmntUpdateResult, cmntUpdateError } = this.props
const {isOpen,editData, modalId } = this.state
const options = {
  onPageChange: this.onPageChange

};
    
return(
  

  <div>
          {cmntUpdateResult &&  <div class="alert alert-success alert-dismissible">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                   Comment Updated successfully
  </div>}
          {cmntUpdateError &&  <div class="alert alert-danger alert-dismissible">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                   Some error occurred
  </div>}
{isOpen&&<EditFlaggedComments openStatus ={isOpen} editData = {editData} closeModal ={this.closeModal} key = {modalId}   />}
{!commentsListLoader? commentsListResult && commentsListResult.length>0 ?<ToolkitProvider
 bootstrap4 keyField='id'
 data={ commentsListResult }
 columns={ columns } 

 search
>
{props =>
(<div>
<SearchBar { ...props.searchProps } />
        <hr />
<BootstrapTable 
classes="table-responsive"
 { ...props.baseProps }  pagination={ paginationFactory(options) }
/>
</div>)}

 </ToolkitProvider>: "No flagged Comments":<div className="spinner-border"></div>}


 

  </div>


          
        ) }

}

const mapStateToProps = state => ({
  userInfo:state.profileReducer.result,
  commentsListResult: state.commentsListReducer.result,
  commentsListLoader: state.commentsListReducer.loader,

  cmntUpdateLoader:state.unflagCommentsReducer.loader,
  cmntUpdateResult:state.unflagCommentsReducer.result,
  cmntUpdateError:state.unflagCommentsReducer.error,
 
 })
 const mapDispatchToProps = dispatch => ({
  
  clearUserUpdateMsg:()=>dispatch(clearUserUpdateMsg()),
 
 
 })
 export default connect(mapStateToProps, mapDispatchToProps)(AllCommentsList)