import React, { useState, useRef, useEffect } from 'react';
import {Helmet} from "react-helmet";
import {

  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon



} from "react-share";
import {imagePath} from '../helpers/constants'

const shareUrl = window.location.href;

const Share = (props) => {
 
  const {
    data,
    images
  } = props;

  const title = data.del_title
  const ogImg = images.length>0&&images[0].preview


  return (
    <div>
      <Helmet>
        <title>{`EasyRun | ${title}`}</title>
        <meta name="description" content="Find some nice deals around you" />
		<meta name="og:image" content={imagePath+'/'+data.id+'/'+ogImg}></meta>
		<meta name="og:url" content={shareUrl}></meta>
		<meta name="og:type" content="article"></meta>
	

{/* <meta property="og:url"                content="http://www.nytimes.com/2015/02/19/arts/international/when-great-minds-dont-think-alike.html" />
<meta property="og:type"               content="article" />
<meta property="og:title"              content="When Great Minds Don’t Think Alike" />
<meta property="og:description"        content="How much does culture influence creative thinking?" />
<meta property="og:image"              content="http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg" /> */}
    </Helmet>
     <FacebookShareButton
            url={shareUrl}
            quote={title}
            className=""
          >

<FacebookIcon size={25} round />
       
          </FacebookShareButton>
          &nbsp;


          <TwitterShareButton
            url={shareUrl}
            title={title}
            className=""
          >
            <TwitterIcon size={25} round />
          </TwitterShareButton>
          &nbsp;
          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
            className=""
          >
            <WhatsappIcon size={25} round />
          </WhatsappShareButton>
    </div>
  );
}

export default Share;