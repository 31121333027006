
export default (state = {}, action) => {
  switch (action.type) {


    case 'POST_DEAL_URL_BEGINS':

      return {
        loader: true,
        result: [],
        title: ''
      }


    case 'POST_DEAL_URL_SUCCESS':

      return {
        loader: false,
        result: action.payload.image,
        title: action.payload.title
      }



    case 'POST_DEAL_URL_CLEAR':

      return {
        loader: false,
        result: [],
        title: ''
      }


    default:
      return state
  }
}



