import React, { Component } from 'react';
import { connect } from 'react-redux';
import CKEditor from "react-ckeditor-component";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { updateCommentAction} from '../actions/huddleCommentsAction';


let config = {
    toolbarGroups: [
        { name: "document", groups: ["mode", "document", "doctools"] },
        {
            name: "editing",
            groups: ["find", "selection", "spellchecker", "editing"]
        },
        { name: "forms", groups: ["forms"] },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
        },
        "/",
        { name: "links", groups: ["links"] },
    ],
    removeButtons:
        "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo,Source",
    fontSize_sizes: "16/16px;24/24px;48/48px;",
    font_names:
        "Arial/Arial, Helvetica, sans-serif;" +
        "Times New Roman/Times New Roman, Times, serif;" +
        "Verdana",
        tabFocus : true,
    allowedContent: true
};
class EditFlaggedHuddleComments extends Component {


	constructor(props) {

		super(props)

		this.state = {
		id:'',
			fcm_comment:'',
			fcm_forum:'',
			flg_comment:'',
			flag_status:1,
			trash_status:0
		}


	this.toggle= this.toggle.bind(this);
	this.onChangeComment= this.onChangeComment.bind(this);
	this.removeComment= this.removeComment.bind(this);
	this.saveReply= this.saveReply.bind(this);
	this.changeFlag= this.changeFlag.bind(this);

	

	}

	componentDidMount(){

const { openStatus, editData} = this.props

this.setState({
			id:editData.id,
			fcm_comment:editData.fcm_comment,
			fcm_forum:editData.fcm_forum,
			flg_comment:editData.flg_comment,
			flag_status:1,
			trash_status:0

})
	}


	toggle =(e)=>{

	this.props.closeModal()
	}
	onChangeComment =(e)=>{
		let newContent = e.editor.getData();
        this.setState({
       
			fcm_comment: newContent
            
        })
	}
	changeFlag =(e)=>{


	this.setState({
		flag_status:e.target.checked?0:1
	})
	}
	removeComment =(e)=>{


	this.setState({
		trash_status:e.target.checked?1:0
	})
	}
	saveReply =(e)=>{
		e.preventDefault();


	if(this.state.fcm_comment){
		this.props.updateComment(this.state);

		setTimeout(() => {
			this.toggle();
			
		}, 2000);

		

	}
	}






	render() {

const {isOpen, fcm_comment} = this.state
const {openStatus} = this.props
const {submitLoader} = this.props

		return (

		
		                      	<div >


									  <Modal isOpen={openStatus} toggle={this.toggle} size={'lg'} >
									  <ModalBody>
		  <CKEditor
                   
				   config={config}
				   activeClass="p10"
				   content={fcm_comment}
				   events={{

					   "change":this.onChangeComment,
					  
					 
					   
				   }}

				  

			   />
			   <br/>
			   <input name="unflag_cmnt" type="checkbox" value={1} onChange={(e)=>this.changeFlag(e)} /> Unflag this comment
			   <br/>
			   <input name="trash_cmnt" type="checkbox" value={1} onChange={(e)=>this.removeComment(e)} /> Remove this comment



			   
									  </ModalBody>
              <ModalFooter>
              {fcm_comment&&<span><a href="#"  className="btn add-comment pull-right"   onClick ={this.saveReply} >{!submitLoader?`Update`:`Updating...`}</a>
									  </span>}
                <Button color="secondary" onClick={this.toggle}>Cancel</Button>
              </ModalFooter>
            </Modal>
									
									
		                      	</div>
		                 
		)
	}
}
const mapStateToProps = state => ({
	userInfo:state.profileReducer.result,
	submitLoader:state.unflagCommentsReducer.loader,
  
  
  })
  const mapDispatchToProps = dispatch => ({
	updateComment: (formData)=>dispatch(updateCommentAction(formData))
  
  })

export default connect(mapStateToProps,mapDispatchToProps) (EditFlaggedHuddleComments)
