import axios from 'axios';
import {url} from '../helpers/constants';


export const postDealAlertAction = (formdata)  =>dispatch => {  

    // let finalData =  new FormData();    
    // finalData.append('shortName',formdata.shortName); 
    // for (let i = 0; i < formdata.tags.length; i+=1) {
    //     finalData.append('tags[]',formdata.tags[i]); 
    // }
   
    dispatch({
        type: 'DEAL_ALERT_POST_BEGINS'
        
    })
    
 axios.post(url+'/api/addDealAlert', formdata,{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    }
    )
        .then(res => {
            if (res.data.response == 'success') {
                

                dispatch({
                    type: 'DEAL_ALERT_POST_SUCCESS',
                    payload: res.data
                })
                setTimeout(() => {
                    dispatch(fetchDealAlertAction())
                });
       
               

            } else {

                dispatch({
                    type: 'DEAL_ALERT_POST_FAILURE',
                    payload: res.data
                })

            }
        })


}
export const pauseResumeAction = (status, id)  =>dispatch => {  

   
    dispatch({
        type: 'DEAL_ALERT_STATUSCHANGE_BEGINS',
        payload:id
        
    })

    // setTimeout(() => {

    //     dispatch({
    //         type: 'DEAL_ALERT_STATUSCHANGE_SUCCESS',
    //         payload:id
    //     })
        
    // }, 2000);

   
    
 axios.post(url+'/api/updateStatus', {status,id},{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    }
    )
        .then(res => {
            if (res.data.response == 'success') {
                

                dispatch({
                    type: 'DEAL_ALERT_STATUSCHANGE_SUCCESS',
                    payload:id
                })
              
       
               

            } else {

                dispatch({
                    type: 'DEAL_ALERT_STATUSCHANGE_FAILURE',
                    payload:id
                })

            }
        })


}
export const deleteAlertAction = (status, id)  =>dispatch => {  

   
    dispatch({
        type: 'DEAL_ALERT_DELETE_BEGINS',
        payload:id
        
    })

    // setTimeout(() => {

    //     dispatch({
    //         type: 'DEAL_ALERT_DELETE_SUCCESS',
    //         payload:id
    //     })
        
    // }, 2000);

   
    
 axios.post(url+'/api/updateStatus', {status,id},{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    }
    )
        .then(res => {
            if (res.data.response == 'success') {
                

                dispatch({
                    type: 'DEAL_ALERT_DELETE_SUCCESS',
                    payload:id
                })
              
       
               

            } else {

                dispatch({
                    type: 'DEAL_ALERT_DELETE_FAILURE',
                    payload:id
                })

            }
        })


}

export const fetchDealAlertAction = () => dispatch => {


   

    dispatch({
        type: 'DEAL_ALERT_FETCH_BEGINS'

    })


    axios.post(url+'/api/myDealAlerts',{},{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    }
    )
        .then(res => {
           
            if (res.data.response == 'success') {

                dispatch({
                    type: 'DEAL_ALERT_FETCH_SUCCESS',
                    payload: res.data

                })
            } else {

                dispatch({
                    type: 'DEAL_ALERTS_FETCH_FAILURE'

                })

            }
        })
}


