import axios from 'axios';
import {url} from '../helpers/constants';




export const listHuddle = (formData) => dispatch => {

  

    dispatch({
        type: 'HUDDLE_LIST_BEGINS',
       

    })
    
    axios.post(url+'/api/listForum',formData, {
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
            if (res.data.response === 'success') {
        
                dispatch({
                    type: 'HUDDLE_LIST_SUCCESS',
                    payload: res.data

                })

             
            } else {
                dispatch({
                    type: 'HUDDLE_LIST_FAILURE',
                    payload: res.data.message

                })

            }
        })

     
}
export const getHuddleDetailsAction = (args) => dispatch => {


    dispatch({
        type: 'HUDDLE_DETAILS_FETCH_BEGINS'

    })


    axios.post(url+'/api/forumDetails',args )
        .then(res => {
         
            if (res.data.response == "success") {

                dispatch({
                    type: 'HUDDLE_DETAILS_FETCH_SUCCESS',
                    payload: res.data

                })
            } else {

                dispatch({
                    type: 'HUDDLE_DETAILS_FETCH_FAILURE',
                    payload: 'Error'

                })

            }
        })
}
export const sendLikeAction = (status,id) => dispatch => {

  
    axios.post(url+'/api/forumlike', {'forumId':id, 'like':status},{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
           // console.log("like response", res)
            if (res.status == 200) {//
                if (status == 1) {
                dispatch({
                    type: 'HUDDLE_LIKE_SUCCESS',
                    payload: {'id':id,'count':res.data.likecount - res.data.dislikecount}
                   

                })
            } else {

                dispatch({
                    type: 'HUDDLE_DISLIKE_SUCCESS',
                   
                    payload: {'id':id,'count':res.data.likecount - res.data.dislikecount}

                })

            }
        }
        else {

            dispatch({
                type: 'HUDDLE_LIKEDISLIKE_FAILURE',
                payload: {id,'count':null}
            })

        }
        })
}


export const clearMailsAction = () => dispatch => {

    dispatch({
                   type: 'MAIL_ACTION_CLEAR'
                 

             })
}
export const clearHuddleLikesReducer = () => dispatch => {

    dispatch({
                   type: 'HUDDLE_LIKE_DISLIKE_CLEAR'
                 

             })
}

