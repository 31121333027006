import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function fpwdValidateInputs(data){    
    let errors = {};     
    
    if(Validator.isEmpty(data.email)){       
        errors.email ="This field cannot be null";           
    } 

    if(!Validator.isEmail(data.email)){       
        errors.email ="Please enter a valid email";           
    } 
  
    
      
    return {
        errors,
        isValid:isEmpty(errors)
    };
}