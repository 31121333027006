import {perPage} from '../helpers/constants';

export default (state = { loader: false, error: false,result: [], skip: 0, limit: perPage, hasMore: true}, action) => {
  switch (action.type) {
    case 'ALL_DEAL_FETCH_BEGINS':
      return {
        ...state,
        loader: true,
        error: false,
        skip: action.skip,
        limit: action.limit,
        hasMore: true
      }

    case 'ALL_DEAL_SUCCESS':
      return {
        skip: state.skip,
        limit: state.limit,
        loader: false,
        error: false,
        result: state.result.concat(action.payload.message), 
        hasMore: action.hasMore,
        count: action.payload.totalCount,
      }

    case 'ALL_DEAL_FAILURE':
      return {
        skip: state.skip,
        limit: state.limit,
        loader: false,
        error: true,
        // result: [],
        hasMore: false,
        count:0
      }
    case 'CLEAR_MYDEALS_RESULTS':
      return {
        loader: false,
        error: false,
        result: [],
        count:0
      }
    default:
      return state
  }
}