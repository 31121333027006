
export default (state = {
  loader:false,
  likeIds: [],
  dislikeIds: [],
  likeCountId: '',
  like: false,
  dislike: false,
  count: []
}, action) => {

  switch (action.type) {
  
    case 'DEAL_LIKE_DISLIKE_CLEAR':
    
      return {
        loader:false,
  likeIds: [],
  dislikeIds: [],
  likeCountId: '',
  like: false,
  dislike: false,
  count: []
      }
    case 'SET_LIKE_DISLIKE_IDS':

    let likeids =[]
    let dislikeids =[]

      Object.keys(action.payload.likeids).map(function(key, index) {
         likeids.push(action.payload.likeids[key])
                    });
      Object.keys(action.payload.dislikeids).map(function(key, index) {
         dislikeids.push(action.payload.dislikeids[key])
                    });

    
      return {
        loader:false,
  likeIds: likeids || [],
  dislikeIds: dislikeids || [],
  likeCountId: '',
  like: false,
  dislike: false,
  count: []
      }
    case 'DEAL_LIKE_SUCCESS':
      let tempDisLikeIds = [...state.dislikeIds]
      let dislikeIds = tempDisLikeIds.filter((item) => item != action.payload.id)

      let tempLikeIds1 = [...state.likeIds]
      let likeIds1 = [...state.likeIds, action.payload.id]

      if(tempLikeIds1.includes(action.payload.id)){
       likeIds1 = tempLikeIds1.filter((item) => item != action.payload.id)

      }

  

  let countState = [...state.count]
    let count= countState.filter((citem) => citem.id != action.payload.id)
      count.push({ id: action.payload.id, lcount: action.payload.count })
      return {
loader:false,
        like: true,
        dislike: false,
        likeIds:likeIds1,
        dislikeIds,
        likeCountId: action.payload.id,
        count
      }
    case 'DEAL_DISLIKE_SUCCESS':
      let tempLikeIds = [...state.likeIds]
      let likeIds = tempLikeIds.filter((item) => item != action.payload.id)

      let tempDisLikeIds1 = [...state.dislikeIds]
      let dislikeIds1 = [...state.dislikeIds, action.payload.id]

      if(tempDisLikeIds1.includes(action.payload.id)){
       dislikeIds1 = tempDisLikeIds1.filter((item) => item != action.payload.id)

      }

  let countState2 = [...state.count]

     let count2 = countState2.filter((citem) => citem.id != action.payload.id)
       count2.push({ id: action.payload.id, lcount: action.payload.count })

      return {
        loader:false,
        like: false,
        dislike: true,
        likeCountId: action.payload.id,
        dislikeIds: dislikeIds1,
        likeIds,
        count: count2


      }
    default:
      return {
        ...state

      }
  }
}