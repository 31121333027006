import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './../../css/style.css';
import  classnames from 'classnames';
import fpwdValidateInputs from '../validation/forgotpasswordValidation';
import {forgotPasswordAction, clearPasswordReducerAction} from '../../actions/auth/passwordAction'
import { connect } from 'react-redux';
import FpwdResult from './fpwdResult'

class ForgotPassword extends Component{
  constructor(props){
    super(props);
      this.state ={
        email: "",
     
        errors:{},
        isLoading:false,
        errorStatus:""
    };   
    this.onChange =  this.onChange.bind(this);
    this.onSubmit =  this.onSubmit.bind(this);
  }

  isValid(){
    const {errors, isValid} =  fpwdValidateInputs(this.state);
    if(!isValid){
      this.setState({errors:errors});
   }   
   return isValid;   
   
  }

  onChange(e){   
    this.setState({errors:''});
    this.setState({[e.target.name]:e.target.value});
    
  }

  onSubmit(e){  
    e.preventDefault();
    if(this.isValid()){ 
      this.setState({errors:{},isLoading:true});     
      this.props.forgotPassword(this.state);      
    }
     
  }


  componentWillUnmount(){

    this.props.clearForgotPasswordMsg()
  }

  componentDidMount(){

    this.nameInput.focus();
  }

    render(){
      let {isFpwdPending, fpwdError, result} = this.props;
        return (
            <div>
        
        <Header {...this.props}/>
  
  {/* end header */}
  {/* main body */}
  <section className="main">
    <div className="container-fluid">
      <div className="signup_wrap">
        <div className="signup_icon" />
        <h3>Forgot password?</h3>  
        {result && <FpwdResult {...this.props} type={"forgot"} />}     
        { fpwdError && (<div className="alert alert-danger text-center" > { fpwdError } </div>) }
        <form className="needs-validation"  onSubmit={this.onSubmit}>        
          <div className="form-group row">
            <div className={classnames("col-sm-12",{'has-error':this.state.errors.email})}>
              <input 
                  name="email" 
                  type="email" 
                  className="form-control" 
                  id="email" 
                  placeholder="Email*" 
                  value={this.state.email}
                  onChange={this.onChange}
                  ref={(input) => { this.nameInput = input; }} 
              />
              {this.state.errors.email && <span className="help-block">{this.state.errors.email}</span> }
            </div>            
          </div>
         
         
          <div className="form-group row">
            <div className="col-sm-12">
              <input type="submit" className="btn btn-primary" value={ isFpwdPending ? "Please wait...":"Submit" } disabled = { (isFpwdPending || !this.state.email) } />
            </div>
          </div>
        </form>
        <div className="note">
        
          <Link to='/login' className="signin_note">Sign in</Link>
          
        </div>
      </div>
    </div>
  </section>
  {/* end main body */}
  {/* footer */}
  <Footer/>
</div>


          
        )
    }
}
const mapStateToProps = state => ({   
     fpwdError:state.passwordReducer.fpwdError ,
     isFpwdPending:  state.passwordReducer.isFpwdPending,
     result:state.passwordReducer.result

 })

const mapDispatchToProps = dispatch => ({
     forgotPassword: (data) => dispatch(forgotPasswordAction(data)) , 
     clearForgotPasswordMsg: () => dispatch(clearPasswordReducerAction())  
     
 })
 export default connect(mapStateToProps, mapDispatchToProps) (ForgotPassword);
