
export default (state = {}, action) => {
  switch (action.type) {
    case 'MAIL_LIST_BEGINS':
      return {
        loader: true,
        error: false,
        result: null,
        count:0
      
      }
    case 'MAIL_LIST_SUCCESS':
      return {
        loader: false,
        error: false,
        result: action.payload.data,
        count:action.payload.totalCount
    
      }
    case 'MAIL_LIST_FAILURE':
      return {
        loader: false,
        error: false,
        result: null,
        count:0
      

      }
    case 'MAIL_DELETE_SUCCESS':
      return {
        loader: false,
        error: false,
        result: state.result.filter(item =>!action.payload.includes(item.id)),
        count: state.count -1>=0?state.count -1:0
      

      }
    case 'MAIL_READ_SUCCESS':



state.result.map((item)=>{

  if(action.payload == item.id){
    item.read =1
  }
})
      return {
        loader: false,
        error: false,
        result: state.result,
        count:state.count
      

      }
    default:
      return state
  }
}