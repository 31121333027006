export const returnUserType = (role) =>{

switch (role) {
    case 1:
        return "Super Admin"
        
        break;
    case 2:
        return "Admin"
        
        break;
    case 3:
        return "Moderator"
        
        break;
    case 4:
        return "Normal User"
        
        break;
    case 5:
        return "Store Rep."
        
        break;

    default:
        return ""
        break;
}



}

export const trimText = (message, length) => {

    if (message && message.length > length) {
       return message.substring(0, length) + '...';
    } else return message


 }

 export const range = (start, end) => {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }

