import React, { useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {imagePath} from '../helpers/constants'

function DealImageCarousel(props) {
   const {imageData, dealId} = props
    


    return (
        // <Carousel showIndicators={false} autoPlay={true} interval={2000} infiniteLoop={true} showStatus={true} thumbWidth="60">
        //         <div>
        //         <img src={require("../images/Ads-01.png")}/>
                  
        //         </div>
        //         <div>
        //         <img src={require("../images/Ads-01.png")}/>
                  
        //         </div>
        //         <div>
        //         <img src={require("../images/Ads-01.png")}/>
                   
        //         </div>
        //     </Carousel>

        <Carousel 
        showIndicators={false} 
        autoPlay={true} 
        interval={2000} 
        infiniteLoop={true} 
        showStatus={true}
       
        >
        {imageData&& imageData.map((img, id)=>{

            return(

                <div key ={id} >
                    <img src={`${imagePath}/${dealId}/${img.preview}`} />
                   
                </div>
                
          

            )
        })}

</Carousel>
    );
}
export default DealImageCarousel