import React, { useEffect, useState, useReducer } from 'react';
import { useDropzone } from 'react-dropzone';
import uuid from "uuid";
import {imagePath} from '../helpers/constants';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'

};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};






function EditDealImages(props) {
  
    const {primaryStatus} = props
   const [files, setFiles] = useState([]);
   const [toggle, setTogle] = useState(false);
    const { getRootProps, getInputProps,isDragReject,
        rejectedFiles } = useDropzone({
        accept: 'image/*,text/*, application/*',
        minSize: 0,
        maxSize: 1000000,
        onDrop: acceptedFiles => {           
            const newFiles = [...files];           
            let prev = acceptedFiles.map(file => {
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    id: uuid.v4(),
                    primaryStatus: false

                })
                newFiles.push(file)
            }

            )
            // newFiles.map((file, id) => {
            //     if (id === 0) {
            //         file.primaryStatus = true
            //     }
            // })
            setFiles(newFiles)
            props.getFiles(newFiles)
        }
    });
    const isFileTooLarge =
    rejectedFiles.length > 0 && rejectedFiles[0].size > '1000000';

    const thumbs = files.map(file => (
        <div className="col-lg-4 col-md-6 col-6" key={file.name} >

<div  className="postImgWrp"  >
              
              <span className="postImg_DltWrp"  onClick={() => {
console.log("filedt ",file)
           if(primaryStatus && file.primaryStatus){
            setTogle(true)

          
           }else {

            let filtered =
            files.filter(function (item) {
                return item.id !== file.id;
            });
        let primaryFlag = false;
        filtered.map((fileitem) => {
            if (fileitem.primaryStatus === true) {
                primaryFlag = true
            }
            if (!primaryFlag) {
                // filtered[0].primaryStatus = true
            }
        })

        setFiles(filtered)
        props.getFiles(filtered)

           
           }
             

          }}   >
              
              
              <img
                  src={file.preview}
                
              />
              
              
              
              <i className="fa fa-trash  pointer"  title="click to delete"></i>
          
          
          </span>
              
              <div className="primaryRadio" >


<div className="custom-control custom-radio">
<input name="primary" type="radio" className="custom-control-input" checked={file.primaryStatus && primaryStatus} type="radio" id={file.id}  onClick={(event) => {
      event.stopPropagation();

      files.map((item) => {
          item.primaryStatus = false

      })
      file.primaryStatus = true;
      props.dragPrimarySet('drag',  file.name)

      props.getFiles(files);
  }

  } />
<label className="custom-control-label" for={file.id}>
<span className="primary_text">Primary image</span>
</label>
</div>
  
</div>
          </div>
        </div>
    ));

    if(props.imageData){
        const oldThumbs = props.imageData.map(file => (
            <div style={thumb} key={file.id} >
    
                <div style={thumbInner} onClick={() => {
                    let filtered =
                        files.filter(function (item) {
                            return item.id !== file.id;
                        });
                    let primaryFlag = false;
                    filtered.map((fileitem) => {
                        if (fileitem.primaryStatus === true) {
                            primaryFlag = true
                        }
                        if (!primaryFlag) {
                            filtered[0].primaryStatus = true
                        }
                    })
                    setFiles(filtered)
                    props.getFiles(filtered)
    
                }} >
                    <img
                        src={file.preview}
                        style={img}
                        title="click to delete"
                        className="pointer"
                    />
                    <div style={{ 'position': 'absolute', 'fontSize': '10px' }}>
    
                        <input name="primary" checked={file.primaryStatus} type="radio" id={file.id} onClick={(event) => {
                            event.stopPropagation();
    
                            files.map((item) => {
                                item.primaryStatus = false
    
                            })
                            file.primaryStatus = true;
    
                            props.getFiles(files);
                        }
    
                        } /><div>Make Primary</div>
                    </div>
    
                </div>
            </div>
        ));

    }
   

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <section className="box" style={{ 'border': '1px dotted #ccc', 'minHeight': '30px', 'padding': '5px' }}  onMouseEnter={props.clicked}>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
                {isFileTooLarge && (
          <div className="text-danger mt-2">Please upload image files of size less than 1 MB.</div>
        )}
            </div>
            <aside style={thumbsContainer}>
                {thumbs}
            </aside>

              {/* confirm modal */}
        <Modal isOpen={toggle}  className={"modal-dialog-centered"}>

         
     

<ModalBody>

<p className="pt-2">Sorry, you cannot delete a primary image</p>


 
</ModalBody>
<ModalFooter>
  <Button color="secondary" onClick={(e)=>setTogle(false)}>OK</Button>
</ModalFooter>
</Modal>
{/* /confirm modal */}
        </section>
    );
}
export default EditDealImages