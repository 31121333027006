import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux' 
import { getAllHuddleAction} from '../actions/profileAction'
import ReduxLazyScroll from 'redux-lazy-scroll'
import moment from 'moment';

class AllHuddle extends Component {

   constructor(props){

      super(props)

      this.loadPosts = this.loadPosts.bind(this);
      this.convertUTCToLocal=this.convertUTCToLocal.bind(this);




   }
   convertUTCToLocal =(utcDt, utcDtFormat) => {
      var toDt = moment.utc(utcDt, utcDtFormat).toDate();
      return moment(toDt).format('DD-MM-YYYY hh:mm:ss A');
    }
   loadPosts() {
      const {skip, limit} = this.props

      this.props.getAllHuddle(skip,limit);
  
    }

 


  componentDidMount(){



  }
    render(){

      const {huddleLoader, huddleResult, huddleError, hasMore} = this.props
return(


<div>
<div className="container posts-lazy-scroll">
   <ReduxLazyScroll
   initialLoad = {false}
     isFetching={huddleLoader}
     errorMessage={huddleError}
     loadMore={this.loadPosts}
     hasMore={hasMore}
   >
<div className="contribution_list_header">
</div>
<div className="contributionsList">
         <ul>
         {this.renderhuddles()}
            </ul>
            </div>






      </ReduxLazyScroll>
      </div>
 {huddleLoader && <div className="spinner-border"> </div>}
 {!huddleLoader && huddleResult && huddleResult.length<=0 && <div>No Data</div>}
</div>

  
 





   ////
  


          
        ) }



        renderhuddles(){
         const { huddleResult} = this.props

      return  huddleResult.map((huddle, idx)=>{

            // className="expired"
            return ( <li  key ={idx}>
            <Link to={`/huddle/${huddle.id}`} className="title">{huddle.for_title}
         
            </Link>
 
            <div className="dateline">Created at {this.convertUTCToLocal(huddle.for_created_at)} by {huddle.user&& huddle.user.firstName} &nbsp;&nbsp; <Link  title="edit" to={`/edit-huddle/${huddle.id}`}><i className="fa fa-pencil"></i></Link>  </div>
            <div className="bottom">
               <span className="icon">
               <i className="fa fa-thumbs-up" aria-hidden="true"></i>
             
               </span>&nbsp; 
               {(huddle.for_count_like - huddle.for_count_dislike)}
               
               {/* <span className="icon"><i className="fa fa-commenting" aria-hidden="true"></i></span>
               22 */}
               {/* <span className="expiredBadge">Expired</span> */}
            </div>
            <div id="accordion">
               <div className="card">
                  <div className="card-header">
                     <a className="card-link collapsed" data-toggle="collapse" href={`#collapse${idx}`}>
                     Description
                     </a>
                  </div>
                  <div id={`collapse${idx}`} className="collapse" data-parent="#accordion">
                     <div className="card-body"   dangerouslySetInnerHTML={{__html: huddle.for_descr}} >
                    
                     </div>
                  </div>
               </div>
            </div>
         </li>)
         })



        }

}

const mapStateToProps = state => ({
   userInfo:state.profileReducer.result,
   huddleLoader:state.allHuddleReducer.loader,
   huddleResult:state.allHuddleReducer.result,
   myhuddleCount:state.allHuddleReducer.count,
   huddleError:state.allHuddleReducer.error,
   hasMore: state.allHuddleReducer.hasMore,
   skip: state.allHuddleReducer.skip,
  limit: state.allHuddleReducer.limit,

 
 })
 const mapDispatchToProps = dispatch => ({
   getAllHuddle: (skip, limit) => dispatch(getAllHuddleAction(skip, limit)),

   
 
 })
 export default connect(mapStateToProps, mapDispatchToProps)(AllHuddle)