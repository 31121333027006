import axios from 'axios';
import {url} from '../../helpers/constants';



   export const forgotPasswordAction = (data) => dispatch => { 
         dispatch({ type: 'CHECKING_FORGOT_PASSWORD' });

         axios.post(url+'/api/forgotPasswordRequest',{'email':data.email})
              .then(res=>{
                if(res.data.response === 'success'){
                    
                  dispatch({
                   type: 'FORGOT_PASSWORD_SUCCESS',
                   payload: 'forgot_password_success'
                  })     
                         
                }else{
                
                   dispatch({
                       type: 'FORGOT_PASSWORD_FAILURE',
                       payload: res.data
                    })
                }
           }) 
  }

  export const verifyResetPwdTokenAction = (token) => dispatch => {     
  
 
    axios.post(url+'/api/pwdResetTokenVerification',
    {token}
             )
              .then(res=>{
                if(res.data.response === 'success'){
                  localStorage.clear();
                  dispatch({
                   type: 'PWD_TOKEN_VERIFICATION_SUCCESS',
                   payload: res.data
                  })

                 
                   
                }else{                 
                  
                   dispatch({
                       type: 'PWD_TOKEN_VERIFICATION_FAILURE',
                       payload: res.data
                      })
                }
       })


 
  }
  export const resetPasswordAction = (data) => dispatch => {     

    let formData = new FormData(); 

    formData.append('password', data.password);
    formData.append('repassword', data.repassword);
    formData.append('token', data.token);
    
  

    dispatch({ type: 'CHECKING_FORGOT_PASSWORD' });


    axios.post(url+'/api/resetPassword',
    formData
             )
              .then(res=>{
                if(res.data.response === 'success'){
                  localStorage.clear();
                  dispatch({
                    type: 'FORGOT_PASSWORD_SUCCESS',
                    payload: 'forgot_password_success'
                   })  

                 
                   
                }else{                 
                  
      dispatch({
        type: 'FORGOT_PASSWORD_FAILURE',
        payload: res.data
              })
                    }

       })


 
  }
  export const changePasswordAction = (data) => dispatch => {     
  
    let formData = new FormData(); 

    formData.append('password', data.password);
    formData.append('repassword', data.repassword);
    formData.append('userId', data.userId);
    dispatch({ type: 'CHECKING_CHANGE_PASSWORD' });


    axios.post(url+'/api/changePassword',
    formData,{
      headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
  }
             )
              .then(res=>{
                if(res.data.response === 'success'){
                  // localStorage.clear();
                  dispatch({
                    type: 'CHANGE_PASSWORD_SUCCESS',
                    payload: 'change_password_success'
                   })  

                 
                   
                }else{                 
                  
      dispatch({
        type: 'CHANGE_PASSWORD_FAILURE',
        payload: res.data
              })
                    }

       })


 
  }
  
   export const clearPasswordReducerAction = () => dispatch => { 
         dispatch({ type: 'CLEAR_FORGOT_PASSWORD_RESULTS' });
       
  }
   export const clearChangePasswordReducerAction = () => dispatch => { 
         dispatch({ type: 'CLEAR_CHANGE_PASSWORD_RESULTS' });
       
  }
   export const clearResetPwdTokenVerificationMsgAction = () => dispatch => { 
         dispatch({ type: 'PWD_TOKEN_VERIFICATION_CLEAR' });
       
  }