
export default (state = {}, action) => {
  switch (action.type) {
    case 'GET_USER_DETAILS_BEGINS':
      return {
        loader: true,
        error: false,
        result: null,
        myCommentslikes: null,
        myCommentsdislikes: null,
        myCommentsFlags: null,
        myDealsCount: null,
        myCommentsCount: null,
        myVotesCount: null,
        reputation: null,
      }
    case 'GET_USER_DETAILS_SUCCESS':
      return {
        loader: false,
        error: false,
        result: action.payload.user,
        myCommentslikes: action.payload.myCommentslikes,
        myCommentsdislikes: action.payload.myCommentsdislikes,
        myCommentsFlags: action.payload.myFlags,
        myDealsCount: action.payload.myDealsCount,
        myCommentsCount: action.payload.myCommentsCount,
        myVotesCount: action.payload.myVotesCount,
        reputation: action.payload.reputation,
      }
    case 'GET_USER_DETAILS_FAILURE':
      return {
        loader: false,
        error: false,
        result: null,
        myCommentslikes:{},
        myCommentsdislikes:{},
        myCommentsFlags: {},
        myDealsCount: null,
        myCommentsCount: null,
        myVotesCount: null,
        reputation: null,


      }
    default:
      return state
  }
}