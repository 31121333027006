import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, PaginationItem, PaginationLink,  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';

import {sendMail, getEmailList} from '../actions/mailAction'

function ReplyMail(props){
  
  const convertUTCToLocal =(utcDt, utcDtFormat) => {
    var toDt = moment.utc(utcDt, utcDtFormat).toDate();
    return moment(toDt).format('DD-MM-YYYY hh:mm:ss A');
  }

  const textareaRef = useRef(null)

  const {data, isOpen, closeReplyModal } = props


    const [modal, setModal] = useState(false);
   
    const [formData, setFormData] = useState({userId:'', toAdmin:0, toAddress:data.userEmail, subject:data.subject?'RE: '+data.subject:"RE: (no subject)", message:data.message, parent_id:data.id})
    const [submitted, setSubmitted] = useState(false)

    const dispatch = useDispatch()


    const userData = useSelector(state => state.userDetailsReducer.result)
   
  const sendResult = useSelector(state => state.mailActionReducer.result)
  const sendLoader = useSelector(state => state.mailActionReducer.loader)
  const sendError = useSelector(state => state.mailActionReducer.error)

  const setSubject =(subject, parent_id)=>{

    let newSubject = subject

    if(parent_id!= null){

      return (newSubject?newSubject:"(no subject)");
    }else{

      return (newSubject?'RE: '+newSubject:"RE: (no subject)");
    }


  }


  useEffect(() => {
    if(modal){

      setTimeout(() => {
        textareaRef && textareaRef.current.focus()
      },1000);
     
     
    }

 }, [modal]);
  useEffect(() => {
    // let newFormData = {...formData, userId:userData?userData.id:''}
    let oldFormData = {...formData}

    let oldMessage=` \n\n------------------------------------------------------------------------------------------\nOn ${convertUTCToLocal(data.date)},  <${data.userEmail}>  wrote: \n\n${data.message}\n`;
    let newFormData = {...oldFormData, userId:userData?userData.id:'', toAddress:data.userEmail, subject: setSubject(data.subject, data.parent_id),
    message:oldMessage, parent_id:data.id}

    setFormData(newFormData)  


 }, [userData, data]);

  useEffect(() => {

    
   
    setModal(isOpen)

 }, [isOpen]);

//   useEffect(() => {

//     let oldFormData = {...formData}

//     let oldMessage=` \n\n------------------------------------------------------------------------------------------\nOn ${data.date},  <${data.userEmail}>  wrote: \n\n${data.message}\n`;
//     let newFormData = {...oldFormData, toAddress:data.userEmail, subject:data.subject?'RE: '+data.subject:"RE: (no subject)", message:oldMessage, parent_id:data.id}

//     setFormData(newFormData)  

//  }, [data]);

  useEffect(() => {
  
    if(sendResult){
        closeModal()

    }

 }, [sendResult]);


const handleChange = (e) =>{

    let newFormData = {...formData, [e.target.name]:e.target.value}

    setFormData(newFormData)  

}
const handleSubmit = (e) =>{

    setSubmitted(true)

    e.preventDefault()

    if((formData.toAdmin || formData.toAddress) && formData.message){
        dispatch(sendMail(formData))
    }

  

  


}

  const toggle = () =>{
    let newFormData = {...formData, toAdmin:0, toAddress:'', subject:"", message:""}

    setFormData(newFormData)    
    setSubmitted(false)
    setModal(!modal);
  closeReplyModal();
  
  }


  const closeModal = () =>{
    let newFormData = {...formData, toAdmin:0, toAddress:'', subject:"", message:""}

    setFormData(newFormData)    
    setSubmitted(false)
    setModal(false);
    closeReplyModal();
  
  }



    return(

        <>
        

            <Modal isOpen={modal} toggle={toggle}  backdrop={"statis"} className="composeModal" keyboard={false}>
        <ModalHeader toggle={toggle}>	<i className="fa fa-envelope"></i> Reply</ModalHeader>
        <ModalBody>


        <form action="#" method="post">
									
										
											
										<div className="form-group">
                      <label htmlFor="toAddress">To</label>
                                                <input name="toAddress" type="email" className="form-control" placeholder="To*"
                                                value={formData.toAddress}
                                                id={"toAddress"}
                                               readOnly ={true}
                                                />
                                          

{!formData.toAddress && submitted&& <p className="help-block">This field is required</p>}
											</div>
											<label htmlFor="subject">Subject</label>
										
											<div className="form-group">
                                                <input name="subject" type="text" 
                                                id="subject"
                                                className="form-control" placeholder="Subject"
                                                value={formData.subject}
                                                onChange={handleChange}
                                               
                                                />
											</div>
                      <label htmlFor="email_message">Message</label>
											<div className="form-group">
                                                <textarea name="message" id="email_message"
                                               
                                                className="form-control" placeholder="Message"
                                                value={formData.message}
                                                onChange={handleChange} 
                                                ref={textareaRef}
                                                style={{height: '120px'}}></textarea>
{!formData.message && submitted&& <p className="help-block">This field is required</p>}

											</div>
										
									
									
									</form>
        </ModalBody>
       <ModalFooter>
       {!sendLoader? 	<><button type="button" className="btn btn-default" onClick={toggle} ><i className="fa fa-times"></i> Discard</button>
											<button type="submit" className="btn btn-primary pull-right"
                                            onClick={handleSubmit}
                                            ><i className="fa fa-envelope"></i> Send Message</button></>:<button type="submit" className="btn btn-primary pull-right"
                                           disabled
                                            ><i className="fa fa-envelope"></i> Sending...</button>}
        </ModalFooter>
      </Modal>
        </>
    )


}

export default ReplyMail