import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';	
// import 'moment-timezone';
import { getDealCommentsAction, likeCommentAction, commentsReducerClear} from '../actions/dealCommentsAction';
import ReplyDealComments from './replyDealComments';
import DealCommentLike from './dealCommentLike';
import DealCommentFlag from './dealCommentFlag';
import ReduxLazyScroll from 'redux-lazy-scroll'
import { avatarPath} from '../helpers/constants'
import moment from 'moment';


class DealCommentList extends Component {


	constructor(props) {

		super(props)

this.state = {
	likedComments:[],
	dislikedComments:[],

}

this.likeHandler = this.likeHandler.bind(this)
this.loadPosts = this.loadPosts.bind(this);
this.convertUTCToLocal = this.convertUTCToLocal.bind(this);
this.convertUTCToLocalTime = this.convertUTCToLocalTime.bind(this);
	

	}
	convertUTCToLocal =(utcDt, utcDtFormat) => {
		var toDt = moment.utc(utcDt, utcDtFormat).toDate();
		return moment(toDt).format('DD-MM-YYYY');
	  }
	  convertUTCToLocalTime =(utcDt, utcDtFormat) => {
		var toDt = moment.utc(utcDt, utcDtFormat).toDate();
		return moment(toDt).format('hh:mm:ss A');
	  }

	loadPosts() {
    
		const {skip, limit} = this.props
		//console.log("limit", limit);
		//console.log("skip", skip);

		let postData = {

			limit,
			skip,
			id:this.props.dealId
		}
	
		this.props.getDealComments(postData)
	
	  }

	componentDidMount(){

window.scrollTo(0,0)
const {skip, limit} = this.props
let postData = {

	limit,
	skip,
	id:this.props.match.params.id
}
		//  this.props.getDealComments(postData)

		let cmntLikeIds = []
		let cmntdisLikeIds = []
	
	if(this.props.commentLikeIds)
		cmntLikeIds = Object.values(this.props.commentLikeIds);
	if(this.props.commentdisLikeIds)
		 cmntdisLikeIds = Object.values(this.props.commentdisLikeIds);

		this.setState({
			likedComments:cmntLikeIds,
			dislikedComments:cmntdisLikeIds,

		})


	}

	componentWillReceiveProps(nextProps){
		let cmntLikeIds = []
		let cmntdisLikeIds = []
	
	if(nextProps.commentLikeIds)
		cmntLikeIds = Object.values(nextProps.commentLikeIds);
	if(nextProps.commentdisLikeIds)
		 cmntdisLikeIds = Object.values(nextProps.commentdisLikeIds);

		this.setState({
			likedComments:cmntLikeIds,
			dislikedComments:cmntdisLikeIds,

		})

	}


	

	likeHandler =(action, postId) =>{

		const {likedComments, dislikedComments} = this.state



switch (action) {
	case  '1':
		let tempDislikes = [...dislikedComments]
		let tempLikes1 = [...likedComments]
	if(!likedComments.includes(postId)){
		tempLikes1.push(postId)

	}else{

		tempLikes1=likedComments.filter((item)=>item!=postId)


	}
	if(dislikedComments.includes(postId)){
		tempDislikes=dislikedComments.filter((item)=>item!=postId)

	}
	this.props.likeCommentAction(action, postId, this.props.dealId)


	this.setState({
		likedComments:tempLikes1,
		dislikedComments:tempDislikes
	})
		
		break;
	case '0': 
	let tempLikes = [...likedComments]
	let tempDislikes1 = [...dislikedComments]
	if(!dislikedComments.includes(postId)){
		tempDislikes1.push(postId)

	}else{
		tempDislikes1=	dislikedComments.filter((item)=>item!=postId)


	}
	if(likedComments.includes(postId)){
		tempLikes=	likedComments.filter((item)=>item!=postId)

	}
	this.props.likeCommentAction(action, postId, this.props.dealId)


	this.setState({
		likedComments: tempLikes,
		dislikedComments:tempDislikes1
	})
		
		break;

	default:
		break;
}

	}






	render() {

const {result, loader, commentCount} = this.props

		return (

			<div>
	<div class="comment_box">
					<div class="box_header">
				<div class="comment_num floatLeft" ><span>{ commentCount}</span> {result&&result.length==1?`Comment`:`Comments`}</div>
						
						<div class="clearfix"></div>
					</div>
				
					<div class="posts">
						
					{this.renderComments()}
						
					
					</div>
				</div>

		</div> )

			
			




}

renderComments(){
	const {result, loader,hasMore, error} = this.props
	const {dislikedComments, likedComments} = this.state



	return (
<div className="container">
		<ReduxLazyScroll
		isFetching={loader}
		errorMessage={error}
		loadMore={this.loadPosts}
		hasMore={hasMore}
		threshold = {450}
	  >

{result.map((item, idx)=>{

		return (<div class="post_reply_container" key = {idx} id={`post-${item.id}`}>
		<div class="row">
			<div class="col-md-4 col-sm-12">
				<div class="post_avatar_container">
					<div class="user_avatar">
					{item.user.avatar ? <img src={`${avatarPath}/${item.user.avatar}`} />:<img src={require("../images/avatar-150.png")}/>}
						
					</div>
					<div class="user_avatar_details">
						  <div class="post_usename"><a >{item.user.firstName}</a></div>
						<div class="poster_joined"><span><i class="fa fa-calendar" aria-hidden="true"></i></span> Joined {item.user.created_at?
							<Moment format="DD MMM YYYY">
							{item.user.created_at}
							
				</Moment>:''
					
						
						}</div>
						{item.user.reputationPoints && <div class="poster_title"><span><i class="fa fa-star" aria-hidden="true"></i></span><span >{item.user.reputationPoints} Reputation</span></div>}
						{item.user.city && <div class="poster_title"><span><i class="fa fa-location-arrow" aria-hidden="true"></i></span><span >{item.user.city}</span></div>}
						{/* <div class="num_posts"><span><i class="fa fa-commenting" aria-hidden="true"></i></span> 3,296 Posts</div>
						<div class="poster_rep"><span><i class="fa fa-star" aria-hidden="true"></i></span><span>1,143</span>Reputation</div>
						<span class="invertedBadge pro">Pro</span> */}
					</div>
				</div>
			</div>
			<div class="col-md-8 col-sm-12">
				<div class="post_text">
					<div class="post_text_top">
						<span class="post_date_time floatLeft"><span class="date">
						
					
						{this.convertUTCToLocal(item.dcm_created_dts)}
          
							</span> at <span class="time">

						
							{this.convertUTCToLocalTime(item.dcm_created_dts)}
           
								</span></span>
	<span class="post_corner floatRight"><span>#{idx+1}</span>
	<span class="dot">.</span>
<DealCommentFlag postId={item.id} {...this.props}  flaggedComments={this.props.commentFlagIds} />
	</span>
						<div class="clearfix"></div>
					</div>
					<div class="post_text_content" dangerouslySetInnerHTML={{__html: item.dcm_comment}}>
					</div>
					<div class="post_text_bottom">
						<ReplyDealComments quotedFrom={item.user.firstName} {...this.props} post = {item.dcm_comment} parent={item.id} dealId={this.props.dealId} key={`${item.id}reply`} type="reply" />
						{/* <a href="#" >Reply</a><span class="dot ">.</span> */}
						<DealCommentLike {...this.props} postId={item.id}  key={item.id} likedComments={likedComments} dislikedComments={dislikedComments}  getLikeAction={this.likeHandler} likeCount={item.dcm_count_like} dislikeCount={item.dcm_count_dislike} />
					</div>
				</div>
			</div>
		</div>
	</div>)


	})}
		  </ReduxLazyScroll>

		  <div className="row posts-lazy-scroll__messages">
      {loader && <div className="spinner-border"> </div>}

    </div>
		  </div>
	)

	

}
}
const mapStateToProps = state => ({
	loader: state.dealCommentsReducer.loader,
	result:state.dealCommentsReducer.result &&state.dealCommentsReducer.result,
commentLikeIds:state.profileReducer.myCommentslikes,
commentdisLikeIds:state.profileReducer.myCommentsdislikes,
commentFlagIds:state.profileReducer.myCommentsFlags,

error: state.dealCommentsReducer.error,
hasMore: state.dealCommentsReducer.hasMore,
skip: state.dealCommentsReducer.skip?state.dealCommentsReducer.skip:0,
limit: state.dealCommentsReducer.limit?state.dealCommentsReducer.limit:20,

commentCount:state.dealCommentsReducer &&state.dealCommentsReducer.commentCount,
  
  
  })
  const mapDispatchToProps = dispatch => ({
	getDealComments: (postData)=> dispatch(getDealCommentsAction(postData)),
	likeCommentAction: (status, postId, dealId)=> dispatch(likeCommentAction(status, postId, dealId)),
	
  
  })

export default connect(mapStateToProps,mapDispatchToProps) (DealCommentList)
