
export default (state = {}, action) => {
  switch (action.type) {
    case 'USEL_LIST_FETCH_BEGINS':
      return {
        loader: true,
        error: false,
        result: null,
      
      }
    case 'USEL_LIST_FETCH_SUCCESS':
      return {
        loader: false,
        error: false,
        result: action.payload,
    
      }
    case 'USEL_LIST_FETCH_FAILURE':
      return {
        loader: false,
        error: false,
        result: null,
      

      }
    default:
      return state
  }
}