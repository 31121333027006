
export default (state = {}, action) => {
  switch (action.type) {
    case 'DEAL_SEARCH_BEGINS':
      return {
        loader: true,
        error: false,
        result: null,
        count: 0,
      }

    case 'DEAL_SEARCH_SUCCESS':
      return {
        loader: false,
        error: false,
        result: action.payload.message,
        count: action.payload.totalCount,
      }

    case 'DEAL_SEARCH_FAILURE':
      return {
        loader: false,
        error: true,
        result: null
      }
    default:
      return state
  }
}