import axios from 'axios';
import {url, perPage} from '../helpers/constants';


const token = localStorage.getItem('myToken')



export const saveCommentAction = (data) => dispatch => {


    
    dispatch({
        type: 'HUDDLE_COMMENT_POST_BEGINS'
     

    })




    axios.post(url+'/api/addForumComments',data,{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    }
    )
        .then(res => {
            
            if (res.data.response == "success") {

                dispatch({
                    type: 'HUDDLE_COMMENT_POST_SUCCESS',
                    payload: res.data

                })
                setTimeout(() => {
                    dispatch({
        type: 'HUDDLE_COMMENT_REDUCER_CLEAR'

    })
                }, 1000);


            } else {

                dispatch({
                    type: 'HUDDLE_COMMENT_POST_FAILURE'

                })

            }
        })
}
export const updateCommentAction = (data) => dispatch => {
    dispatch({
        type: 'COMMENT_UPDATE_BEGINS'
    })
 

    axios.post(url+'/api/updateForumComments',data,{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    }
    )
        .then(res => {
            
            if (res.data.response == "success") {

                dispatch({
                    type: 'COMMENT_UPDATE_SUCCESS',
                    payload: res.data

                })

                dispatch(getHuddleCommentsListAction())

                setTimeout(() => {
                    dispatch({
            type: 'COMMENT_UPDATE_CLEAR'
            
            })
                }, 4000);
         


            } else {

                dispatch({
                    type: 'COMMENT_UPDATE_FAILURE'

                })

                setTimeout(() => {
                    dispatch({
            type: 'COMMENT_UPDATE_CLEAR'
            
            })
                }, 3000);

            }
        })
        .catch(error => { 
            dispatch({
            type: 'COMMENT_UPDATE_FAILURE',
            payload: error
        })

        dispatch(getHuddleCommentsListAction())



        setTimeout(() => {
            dispatch({
    type: 'COMMENT_UPDATE_CLEAR'
    
    })
        }, 3000);
    
    
    }
        
        );
}
export const getDealCommentsAction = (postData) => dispatch => {

    let formData = new FormData
formData.append('skip',postData.skip)
formData.append('limit',postData.limit)
formData.append('id',postData.id)


    dispatch({
        type: 'HUDDLE_COMMENTS_FETCH_BEGINS',
        skip: postData.skip + postData.limit,
        limit:postData.limit

    })




    axios.post(url+'/api/forumComments',formData
    )
        .then(res => {
            
            if (res.data.response == "success") {

                dispatch({
                    type: 'HUDDLE_COMMENTS_FETCH_SUCCESS',
                    payload: res.data,
                    hasMore:  res.data.message.length > 0

                })

        
            } else {

                dispatch({
                    type: 'HUDDLE_COMMENTS_FETCH_FAILURE'

                })

            }
        })
}

export const likeCommentAction = (status,postId, forumId) => dispatch => {
 
    axios.post(url+'/api/forumcommentslike', {'like':status, 'commentId':postId, 'forumId':forumId},{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
           // console.log("comment like response", res)
            if (res.status == 200) {//
                if (status == 1) {
                dispatch({
                    type: 'HUDDLE_COMMENT_LIKE_SUCCESS',
                    payload: {'id':postId,'count':res.data.likecount}
                   

                })
            } else {

                dispatch({
                    type: 'HUDDLE_COMMENT_DISLIKE_SUCCESS',
                   
                    payload: {'id':postId,'count':res.data.likecount}

                })

            }
        }
        else {

            dispatch({
                type: 'HUDDLE_COMMENT_LIKEDISLIKE_FAILURE',
                payload: {postId,'count':null}
            })

        }
        })
}
export const doFlagAction = (status,commentid) => dispatch => {
axios.post(url+'/api/updateForumFlag', {status, commentid},{
        headers:{Authorization:`Bearer ${localStorage.getItem('myToken')}`}
    })
        .then(res => {
           // console.log("comment like response", res)
            if (res.data.response == "success") {//
                dispatch({
                    type: 'HUDDLE_COMMENT_FLAG_SUCCESS',
                    payload: {'id':commentid}
                   

                })
        }
        else {

            dispatch({
                type: 'HUDDLE_COMMENT_FLAG_FAILURE',
                payload: {'id':commentid}
            })

        }
        })
}

export const getHuddleCommentsListAction = () => dispatch => { 


    dispatch({
      type: 'COMMENTS_LIST_FETCH_BEGINS'

  })
    let token = localStorage.getItem('myToken');
    if(token){
   axios.post(url+'/api/allForumComments',{},{
    headers:{Authorization:`Bearer ${token}`}
})
              .then(res=>{
           
             if (res.data.response == "success") {

              dispatch({
                  type: 'COMMENTS_LIST_FETCH_SUCCESS',
                  payload: res.data.message

              })
          } else {

              dispatch({
                  type: 'COMMENTS_LIST_FETCH_FAILURE'

              })

          }
           }) 

    }
      
  }
  export const commentsReducerClear = () => dispatch => { 
    

    dispatch({
        type: 'HUDDLE_COMMENT_REDUCER_CLEAR'

    })
      
  }