import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function postDealValidation(data){
    let errors = {}; 
    
    if(data.dealUrl){

        if(!Validator.isURL(data.dealUrl,{protocols: ['http','https'], require_protocol:true})){       
            errors.dealUrl ="This field cannot be null";           
        } 
    }
    if(Validator.isEmpty(data.dealTitle)){       
        errors.dealTitle ="This field cannot be null";           
    } 
    if(Validator.isEmpty(data.dealPrice)){       
        errors.dealPrice ="This field cannot be null";           
    } 
    if(Validator.isEmpty(data.dealDescription)){       
        errors.dealDescription ="This field cannot be null";           
    } 
    if(data.dealCategories&&data.dealCategories.length<=0){       
        errors.dealCategories ="This field cannot be null";           
    }
  
      
    return {
        errors,
        isValid:isEmpty(errors)
    };
    

}
 