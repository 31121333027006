import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function resetPwdValidateInputs(data){    
    let errors = {};     
    

   
    if(Validator.isNumeric(data.password)){       
        errors.password ="Password must be alphanumeric";           
    } 
    if(Validator.isAlpha(data.password)){       
        errors.password ="Password must be alphanumeric";           
    } 
    if(Validator.isEmpty(data.repassword)){       
        errors.repassword ="This field cannot be null";           
    }
    else if(data.password.length<6){
        errors.password ="Minimum 6 characters required";   
    } 
    if(data.repassword && data.repassword!= data.password){       
        errors.repassword ="Password mismatch";           
    } 

   
  
    
      
    return {
        errors,
        isValid:isEmpty(errors)
    };
}