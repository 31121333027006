import React, { Component } from 'react';
import Header from '../components/auth/Header';
import { browserHistory } from 'react-router';

import Footer from '../components/auth/Footer';
import {Link} from 'react-router-dom'
import SideBar from '../components/sideBar';
import { connect } from 'react-redux';
import { getDealDetailsAction, sendLikeAction, clearLikesReducerAction} from '../actions/dealsAction';
import { clearAction } from '../actions/postDealAction'
import { commentsReducerClear} from '../actions/dealCommentsAction';
import {imagePath, likeStyle, dislikeStyle, dealEditablePeriod} from '../helpers/constants'
import DealImageCarousel from '../components/dealImageCarousel';
import AddDealComments from '../components/addDealComments';
import DealCommentList from '../components/dealCommentList';
import {returnUserType} from '../helpers/functions'
import Share from '../components/share';
import jwt from 'jwt-decode'
class DealDetails extends Component {


	constructor(props) {

		super(props)

	this.getLikeDislike= this.getLikeDislike.bind(this);
	this.calculateCount= this.calculateCount.bind(this);


	}

	componentDidMount(){


		// this.backListener = browserHistory.listen(location => {
		
		// 	if (location.action === "POP") {
		// 	  window.location.href = '/';   
		// 	}
		//   });


		

		window._epn={campaign:5338582821}
		const script = document.createElement("script");
		script.src = "https://epnt.ebay.com/static/epn-smart-tools.js";
		script.async = true;
		document.head.appendChild(script);


		window.scrollTo(0, 0)
		if(this.props.location.query && this.props.location.query.catch =='comments'){
		window.scrollTo(0, 0)
	
   
		 }else{
			this.nameInput&& this.nameInput.focus();
			
		 }

		 let args = {
			 id: this.props.match.params.id,
			 userId:'',
			 sessionId: sessionStorage.getItem('easySession')
		 }

		 let token = localStorage.getItem('myToken');
		 if(token){

			let decodedData = jwt(token); // decode your token here
			args.userId = decodedData.sub
			



		 }	 
		
		 this.props.getDealDetails(args)




	}


	

	componentWillUnmount(){

		this.props.commentsReducerClear()
		this.props.clearLikesReducer()	

		// setTimeout(() => {
		// 	this.backListener();
			
		// },1000);
	}


	getLikeDislike=(status,id)=>{
		if(this.props.userInfo){

			

		
		  this.props.sendLike(status,id);
	
		}else{
	
		  this.props.history.push('/login')
		}
	
	  }

	  calculateCount(dealId, count_dislike, count_like){

		const {likeCount} = this.props 
		


		if(likeCount && likeCount.length>0){
			let itemCount= null
			likeCount.map((item)=>{
				if(item.id === dealId){
					itemCount = item.lcount
				}
			})

			if(itemCount!=null){
				return itemCount

			}else return (count_like - count_dislike)
			
		}else return (count_like - count_dislike)


	}


	  




	render() {

const {loader, result, likeIds, dislikeIds, dealImages, dealIUser, dealBrands, dealCategories, dealStores, dealTags, userInfo, commentCount} = this.props

let shwEditForUser = false
let shwEditForAdmin = false
let d1;
let d2;
if(result && userInfo && dealIUser){
	 d1 = new Date();
	 d2 = new Date(result.created_at);
	 d2.setDate(d2.getDate() + dealEditablePeriod);



	 if((d1.getTime() <= d2.getTime()) && userInfo.id ===dealIUser.id){
		shwEditForUser = true
	 }
	 if(userInfo.role ===2 || userInfo.role ===3){
		shwEditForAdmin = true
	 }

}

		return (

			<div>
				<Header {...this.props} />
				<section class="main">
	<div class="container-fluid">
	{!loader && result?(<div class="row">
			<div class="col-lg-9 col-md-8">
		
				<div class="deal_section frontpageDeal">
					<div class="deal_box topline">

						<div class="deal_header">
						<div class="deal_header_top" >
<span className="badges">

{dealIUser.role!=4? <span className="bg-usrType">{returnUserType(dealIUser.role)}</span>:''}

{result.del_active==0? 
        <span className="bg-expired ml-2">Expired</span>
       :''}
</span>
<span className="badges pull-right d-flex">

{dealTags&& dealTags.map((tag, idx)=> <div id={`color-${tag.id}`} className="bg-status  ml-1">{tag.tag_name}</div>  )}

     
</span>

						</div>
	        				<div id="dealTitle" class="">
	        					<h1>{result.del_title}</h1>
	        				</div>
	        				<span id="op_reputationmenu_133266635x"></span>
	        			</div>
	        			<div class="deal_detailarea">
	        				<div class="row">
	        					<div class="col-lg-8 col-sm-12">
	        						<div class="deal_detail_left_content">
	        							<div class="deal_price">
	        								<span class="deal_new_price">{result.price && result.price}</span>
	        								<span class="deal_old_price"> {result.stricken_price ?`$${result.stricken_price}`:''}</span>
	        								{/* <span class="deal_price_extra_details">+ Free Shipping</span> */}
	        							</div>
	        							<div class="deal_score_section">
					                      
					                        <div class="deal_view_num">
					                        	<span><i class="fa fa-eye" aria-hidden="true"></i></span>
					                      		<span> {result.del_count_view} Views</span>
					                        </div>
											<div class="dealScore" data-action="showThreadStats" data-forum-id="9" data-thread-id="13711040">
											  
					                          <span><i class="fa fa-thumbs-up" aria-hidden="true"></i></span><span> {this.calculateCount(result.id, result.del_count_dislike ,result.del_count_like)} Likes</span>
					                        </div>
					                        <div class="deal_comments_num">
												<input type="hidden"
											
												 value="" 
												 name="ref" 
												 ref={(input) => { this.nameInput = input; }}
												   />
					                        	
							                       <span><i class="fa fa-commenting" aria-hidden="true"></i></span>
							                       <span> {commentCount>0?commentCount :result.del_count_comments} Comments</span>
						                      	
					                        </div>
					                    </div>
					                    <div id="post-og" class="deal_description" dangerouslySetInnerHTML={{__html: result.del_descr}}>
					                    
											
					                    </div>
					                   
	        							
	        						</div>
	        					</div>	
	        					<div class="col-lg-4 col-sm-12">
	        						<div class="detailImages">
	        							{/* <a href="#"><img src={`${imagePath}/${result.id}/${result.del_image_primary}`}/></a> */}
										<DealImageCarousel imageData={dealImages} dealId={result.id}  />
	        						</div>
	        						{result.del_url?<div class="see_deal_btn">
	        							<a target="_blank" href={result.del_url} class="btn">Get the deal</a>
	        						</div>:''}
	        						<div class="vote_share_container">
	        							<div class="deal_share_container">
	        								<div class="deal_share_title">
	        									Share
	        								</div>
	        								<div class="deal_share_icons">
												<Share data={result} images={dealImages} />
	        									{/* <a href="#"><img src={require("../images/facebook.png")}/></a>
	        									<a href="#"><img src={require("../images/twitter.png")}/></a>
	        									<a href="#"><img src={require("../images/email.png")}/></a>
	        									<a href="#"><img src={require("../images/link.png")}/></a> */}
	        								</div>
	        							</div>
	        							{/* <div class="deal_vote_container">
	        								<div class="deal_vote_title">Good deal?</div> 
	        								<div class="deal_vote_icons">
												<span class="deal_likes_icon" onClick = {()=>this.getLikeDislike('1',result.id)}>
													<i class="fa fa-thumbs-up" aria-hidden="true"  style={likeIds.includes(result.id)?likeStyle:{'border':'0'}}></i></span>
												<span class="deal_dislikes_icon" onClick = {()=>this.getLikeDislike('0',result.id)} >
													
													<i class="fa fa-thumbs-down" aria-hidden="true"  style={dislikeIds.includes(result.id)?dislikeStyle:{'border':'0'}}></i></span>
	        								</div>
	        							</div> */}


	        							<div class="clearfix"></div>
	        						</div>	
	        						
	        					</div>	
	        				</div>
	        			</div>
	        			{dealIUser&& <div class="deal_notes">
	        				<div class="deal_notes_title">
	        					<div class="row">
		        					
		        					<div class="col-md-12">
		        						<div class="deal_notes_header_text">
		        							<span>Posted by </span>{dealIUser.firstName}
		        						</div>
		        					</div>
		        				</div>
		                    </div>
        					<div class="editors_note"><b>Category :</b>&nbsp;
							{dealCategories&& dealCategories.map((cat,idx)=> `${cat.ctg_name}${idx<dealCategories.length-1?',':''} ` )}
							
							
							  &nbsp; <br/><b>Brand :</b>&nbsp; {dealBrands&& dealBrands.map((brand, idx)=>  `${brand.brd_name}${idx<dealBrands.length-1?',':''}`)} 
							&nbsp; <br/>
							<b>Store :</b>&nbsp; {dealStores&& dealStores.map((store, idx)=> `${store.str_name}${idx<dealStores.length-1?',':''} ` )}
							
							</div>


        					
	        			</div>}
	        			


						<div class="deal_header_top" >
											
											{shwEditForUser? <Link to={`/edit-deal/${result.id}`} ><span class="featuredDeal">Edit Deal</span></Link>:shwEditForAdmin?<Link to={`/edit-deal/${result.id}`} ><span class="featuredDeal">Edit Deal</span></Link>:''	}
												{/* <a href="#" class="floatRight">
													<img  class="storeLogo" src={require("../images/outlet.png")}  alt="" title="" style={{"display": "inline"}}/>
												</a> */}
												{/* <div class="clearfix"></div> */}
										</div>

	        			<div class="add_comment_container">
	        				<div class="add_comment_title">
								<AddDealComments quotedFrom={dealIUser.firstName} {...this.props} post = {result.del_descr} parent={0} dealId={result.id} key={`${result.id}main`} type="og" />
		                      
		                    </div>
		                    {/* <div class="add_comment_note floatRight">
                    	  		in <a href="#">Men's Shoes(10)</a> <span class="dot"></span><a href="#">Get Joes New Balance Outlet Coupons</a>
		               		</div> */}
		               		{/* <div class="clearfix"></div> */}
	        			</div>
	        			
					</div>
					<div class="comment_disclaimer_container">
	        				<span><i class="fa fa-info-circle" aria-hidden="true"></i></span>Terms and conditions apply
	        			</div>
				</div>

				
				<div class="deal_status">
					{/* <div class="deal_status_score">
			            <span class="label">Deal<br/>Score</span>
			            <span class="score"><span class="sign" >+</span><span role="thread.score">243</span></span>
		          	</div> */}
		          	<div class="deal_vote_btn_group">
		          		<span class="like_vote"  onClick = {()=>this.getLikeDislike('1',result.id)}>
							  <i class="fa fa-thumbs-up" aria-hidden="true"  style={likeIds.includes(result.id)?likeStyle:{'border':'0','cursor':'pointer'}}></i></span>
		          		<span class="dislike_vote" onClick = {()=>this.getLikeDislike('0',result.id)} >
							  <i class="fa fa-thumbs-down" aria-hidden="true"  style={dislikeIds.includes(result.id)?dislikeStyle:{'border':'0','cursor':'pointer'}}></i></span>
		          	</div>
		          	<div class="deal_views_comments">
		          		<span class="deal_status_view_num">
                        	<span><i class="fa fa-eye" aria-hidden="true"></i></span>
                      		<span> {result.del_count_view} Views</span>
                        </span>
						<span class="dealScore" data-action="showThreadStats" data-forum-id="9" data-thread-id="13711040">
											  
					                          <span><i class="fa fa-thumbs-up" aria-hidden="true"></i></span>
											  <span> {this.calculateCount(result.id, result.del_count_dislike ,result.del_count_like)} Likes</span>
					                        </span>
						
                        <span class="deal_status_comments_num">
                        
		                       <span id="comments"><i class="fa fa-commenting" aria-hidden="true"></i></span>
		                       <span> {this.props.commentCount} Comments</span>
	                      
                        </span>
		          	</div>
		          	<div class="deal_actions">
                        <span class="deal_stat_price" >{result.price && result.price}</span>
                        {/* <span class="deal_stat_old_price">$125.00</span> */}
                       {result.del_url? <div class="buy-now-action" >
                       	<a href={result.del_url} target="_blank" class="btn">Get the deal</a>
                      </div>:''}
                  </div>
		          	<div class="clearfix"></div>
				</div>

			
			<DealCommentList dealId = {result.id}  {...this.props}  />




			</div>
		<SideBar {...this.props} />
		</div>):<div className="spinner-border"></div>}
		
	</div>
</section>
		
				<Footer />
			</div>
		)
	}
}
const mapStateToProps = state => ({
	loader: state.dealDetailsReducer.loader,
	result:state.dealDetailsReducer.result &&state.dealDetailsReducer.result.deals,
	dealCategories:state.dealDetailsReducer.result && state.dealDetailsReducer.result.category,
	dealBrands:state.dealDetailsReducer.result && state.dealDetailsReducer.result.brands,
	dealStores:state.dealDetailsReducer.result && state.dealDetailsReducer.result.stores,
	dealTags:state.dealDetailsReducer.result && state.dealDetailsReducer.result.tags,
	dealImages:state.dealDetailsReducer.result && state.dealDetailsReducer.result.images,
	dealIUser:state.dealDetailsReducer.result && state.dealDetailsReducer.result.user,
	error:state.dealDetailsReducer.error,
	userInfo:state.profileReducer.result,
	likeIds:state.likeReducer.likeIds,
	dislikeIds:state.likeReducer.dislikeIds,
	likeCount:state.likeReducer.count,
	commentCount:state.dealCommentsReducer &&state.dealCommentsReducer.commentCount,
	
  
  
  })
  const mapDispatchToProps = dispatch => ({
	getDealDetails: (args) => dispatch(getDealDetailsAction(args)),
	sendLike: (status,id) => dispatch(sendLikeAction(status,id)),
	clearPostDealResult: () => dispatch(clearAction()),
	commentsReducerClear:() => dispatch(commentsReducerClear()),
	clearLikesReducer: () => dispatch(clearLikesReducerAction()),	
  
  })

export default connect(mapStateToProps,mapDispatchToProps) (DealDetails)
