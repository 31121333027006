
export default (state = {}, action) => {
  switch (action.type) {
    case 'HUDDLE_DETAILS_FETCH_BEGINS':
      return {
        loader: true,
        error: false,
        result: null
      }

    case 'HUDDLE_DETAILS_FETCH_SUCCESS':
      return {
        loader: false,
        error: false,
        result: action.payload
      }

    case 'HUDDLE_DETAILS_FETCH_FAILURE':
      return {
        loader: false,
        error: true,
        result: null
      }
    default:
      return state
  }
}