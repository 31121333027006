import React, { Component } from 'react';
import Header from './auth/Header';
import Footer from './auth/Footer';
import { Link } from 'react-router-dom';
import {getUserAction} from '../actions/auth/authAction'
import { dealEditablePeriod} from '../helpers/constants'
import moment from 'moment';

import {getContributionsAction} from '../actions/profileAction'
import {connect} from 'react-redux' 

class Contributions extends Component {

   constructor(props){

      super(props)


      this.convertUTCToLocal=this.convertUTCToLocal.bind(this);




   }
   convertUTCToLocal =(utcDt, utcDtFormat) => {
      var toDt = moment.utc(utcDt, utcDtFormat).toDate();
      return moment(toDt).format('DD-MM-YYYY hh:mm:ss A');
    }

 


  componentDidMount(){



  }
    render(){

      const {mydealLoader, mydealResult, mydealCount, userInfo} = this.props


  
return(
  

    mydealLoader?<div className="spinner-border"></div>:mydealResult && mydealResult.length>0 ?<div >
     
      <div className="contribution_list_header">
         <div className="row">
            {/* <div className="col-md-6 col-sm-6">You posted <strong>{mydealCount}</strong> posts</div> */}
            {/* <div className="col-md-6 col-sm-6 text-right">
               <span>Sort By </span>
               <span>
                  <div className="contribution_list_sort dropdown">
                     <button type="button" className="btn  dropdown-toggle" data-toggle="dropdown">
                     Last Posted
                     </button>
                     <ul className="dropdown-menu dropdown-menu-right">
                        <li><a href="#"  >Last Post</a></li>
                        <li><a href="#"  >Newest</a></li>
                        <li><a href="#"  >Rating</a></li>
                        <li><a href="#"  >Replies</a></li>
                        <li><a href="#"  >Views</a></li>
                     </ul>
                  </div>
               </span>
            </div> */}
         </div>
      </div>
     <div className="contributionsList">
         <ul>

            {   
            
            mydealResult && mydealResult.map((deal, idx)=>{

               //test for edit
               let shwEditForUser = false
               let shwEditForAdmin = false
               let d1;
               let d2;
               if(userInfo ){
                   d1 = new Date();
                   d2 = new Date(deal.created_at);
                   d2.setDate(d2.getDate() + dealEditablePeriod);
               
               
               
                   if((d1.getTime() <= d2.getTime()) && userInfo.id ===deal.user_id){
                     shwEditForUser = true
                   }
                   if(userInfo.role ===2 || userInfo.role ===3){
                     shwEditForAdmin = true
                   }
               
               }


               // test for edit ends

              
               return ( <li  key ={idx} className={deal.del_active!=1?"expired":""}>
               <Link to={`/deal/${deal.id}`} className="title">{deal.del_title} | {deal.del_price?"$"+deal.del_price.toFixed(2):''}</Link>
              
<div className="store">
   Store:&nbsp;
{deal.dealstores && deal.dealstores.map((str, idx)=>{
                
                return   str.del_store!=null?str.del_store.str_name:''
               })}

</div>
            
               <div className="dateline">Created at { this.convertUTCToLocal(deal.created_at)} by {deal.user&& deal.user.firstName} &nbsp; &nbsp; &nbsp;
               
              {shwEditForUser? <Link  title="edit" to={`/edit-deal/${deal.id}`}><i className="fa fa-pencil"></i></Link>:shwEditForAdmin?<Link  title="edit" to={`/edit-deal/${deal.id}`}><i className="fa fa-pencil"></i></Link>:'' }
               
               
                </div>
               <div className="bottom">
                  <span className="icon">
                  <i className="fa fa-thumbs-up" aria-hidden="true"></i>
                
                  </span>&nbsp;
                  {(deal.del_count_like - deal.del_count_dislike)}
                 
                  {/* <span className="icon"><i className="fa fa-commenting" aria-hidden="true"></i></span>
                  22 */}
                  {/* <span className="expiredBadge">Expired</span> */}
               </div>
               <div id="accordion">
                  <div className="card">
                     <div className="card-header">
                        <a className="card-link collapsed" data-toggle="collapse" href={`#collapse${idx}`}>
                        Description
                        </a>
                     </div>
                     <div id={`collapse${idx}`} className="collapse" data-parent="#accordion">
                        <div className="card-body"   dangerouslySetInnerHTML={{__html: deal.del_descr}} >
                       
                        </div>
                     </div>
                  </div>
               </div>
            </li>)
            })
            
            }
           
         </ul>
      </div>
      </div>:<div>No Deals</div>


          
        ) }

}

const mapStateToProps = state => ({
   userInfo:state.profileReducer.result,
   mydealLoader:state.myDealsReducer.loader,
   mydealResult:state.myDealsReducer.result,
   mydealCount:state.myDealsReducer.count,
 
 })
 const mapDispatchToProps = dispatch => ({
  

   
 
 })
 export default connect(mapStateToProps, mapDispatchToProps)(Contributions)