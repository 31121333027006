import React, { Component } from 'react';
import { connect } from 'react-redux';
import { doFlagAction} from '../actions/dealCommentsAction';



class DealCommentFlag extends Component {


	constructor(props) {

		super(props)

		this.state = {
			flag: false,
			flaggedCommentsArray:[]
		}



this.handleFlag = this.handleFlag.bind(this)
	

	}

	componentDidMount(){
		const {flaggedComments, postId} = this.props
		if(flaggedComments){
		this.setState({
			flaggedCommentsArray: Object.values(flaggedComments)
		})
	}

	}

	componentWillReceiveProps(nextProps){

		const {flaggedComments, postId} = nextProps
		if(flaggedComments){
			this.setState({
				flaggedCommentsArray: Object.values(flaggedComments)
			})

		}
		

	}

	handleFlag = ()=>{

		if(!this.props.userInfo){
			this.props.history.push('/login')
		}
const {flag, flaggedCommentsArray} = this.state

const { postId} = this.props

let status = 0;

if(flaggedCommentsArray.includes(postId)){
let	tempflaggedCommentsArray  =	flaggedCommentsArray.filter((item)=> item!=postId)
this.setState({
	flag:false,
	flaggedCommentsArray:tempflaggedCommentsArray
})

status = 0;

}else{

	flaggedCommentsArray.push(postId)
	this.setState({
		flaggedCommentsArray,
		flag:true
	})

	status = 1;

}
this.props.doFlag(status,postId)

	}






	render() {

// const {postId ,likedComments, dislikedComments, likeCount, dislikeCount} = this.props
const {flag, flaggedCommentsArray} = this.state

const flagStyle = {
	color:'#f1acad'
}

		return (

			
			<span className="btn"><i style={flaggedCommentsArray.includes(this.props.postId)?flagStyle:{'border':'none'}} className="fa fa-flag " aria-hidden="true" onClick={()=> this.handleFlag()} ></i></span>

		)
					





}
}
const mapStateToProps = state => ({
	loader: state.dealCommentsReducer.loader,
	
  
  
  })
  const mapDispatchToProps = dispatch => ({

	doFlag: (status, postId)=> dispatch(doFlagAction(status, postId)) 
  })

export default connect(mapStateToProps,mapDispatchToProps) (DealCommentFlag)
