

export default (state = {
  isLoginSuccess: false,
  isLoginPending: false,
  loginError: null,
  result:{}
}, action) => {
    switch (action.type) {
     case 'REGISTER_SUCCESS':
      return {
       result: action.payload
      }

      case 'REGISTER_FAILURE':
        return {
            result: action.payload.data
        }

        case 'LOGIN_SUCCESS':          
          return {
              result: action.payload
          }
        case 'LOGIN_FAILURE':
          return {
            isLoginSuccess: false,
            isLoginPending: false,
            loginError: action.payload.message
          }
          case 'CHECKING_LOGIN':
            return {
              isLoginSuccess: false,
              isLoginPending: true,            
            }
          case 'CLEAR_RESULTS':
            return {
              result:''        
            }

          
     default:
      return state
    }
   }

   

   