
export default (state = {}, action) => {
  switch (action.type) {
    case 'STORES_FETCH_SUCCESS':
      return {
        stores: action.payload,
        error: false
      }
    case 'STORES_FETCH_FAILURE':
      return {
        stores: [],
        error: true
      }
    default:
      return state
  }
}