import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, PaginationItem, PaginationLink,  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';

import {sendMail, getEmailList} from '../actions/mailAction'

function ComposeNewMail(props){


    const [modal, setModal] = useState(false);
    const [dropDown, setDropDown] = useState(false);
    const [formData, setFormData] = useState({userId:1, toAdmin:0, toAddress:'', subject:"", message:""})
    const [submitted, setSubmitted] = useState(false)

    const dispatch = useDispatch()


    const userData = useSelector(state => state.userDetailsReducer.result)
    const emailData = useSelector(state => state.emailListReducer.result)

  const sendResult = useSelector(state => state.mailActionReducer.result)
  const sendLoader = useSelector(state => state.mailActionReducer.loader)
  const sendError = useSelector(state => state.mailActionReducer.error)

  useEffect(() => {
  
    dispatch(getEmailList())

 }, []);
  useEffect(() => {
    let newFormData = {...formData, userId:userData?userData.id:''}

    setFormData(newFormData)  

 }, [userData]);

  useEffect(() => {
  
    if(sendResult){
        closeModal()

    }

 }, [sendResult]);


const handleChange = (e) =>{

    let newFormData = {...formData, [e.target.name]:e.target.value}

    setFormData(newFormData)  

}
const handleSubmit = (e) =>{

    setSubmitted(true)

    e.preventDefault()

    if((formData.toAdmin || formData.toAddress) && formData.message){
        dispatch(sendMail(formData))
    }

  

  


}

  const toggle = () =>{
    let newFormData = {...formData, toAdmin:0, toAddress:'', subject:"", message:""}

    setFormData(newFormData)    
    setSubmitted(false)
    setModal(!modal);}


  const closeModal = () =>{
    let newFormData = {...formData, toAdmin:0, toAddress:'', subject:"", message:""}

    setFormData(newFormData)    
    setSubmitted(false)
    setModal(false);}


    const handleSelectInputChange =(value)=>{

       if(value && value.length>3){

        setDropDown(true)
       }else{
        setDropDown(false)

       }
    }
    const handleToChange =(value)=>{

       if(value ){

        let newFormData = {...formData, toAddress:value.email}

    setFormData(newFormData)  


       }
    }



    return(

        <>
        	<a className="btn btn-block btn-primary" onClick={toggle} ><i className="fa fa-pencil"></i>&nbsp;&nbsp;NEW MESSAGE</a>

            <Modal isOpen={modal} toggle={toggle}  backdrop={"statis"} className="composeModal" keyboard={false}>
        <ModalHeader toggle={toggle}>	<i className="fa fa-envelope"></i> Compose New Message</ModalHeader>
        <ModalBody>
        <form action="#" method="post">
									
											<div className="form-group row">

                                                <div className="col-6">
                                                <label className="pr-2" htmlFor="toAdmin">To Moderators</label>
                                                <input name="toAdmin" id="toAdmin" type="radio" className="" 
                                                value={1}
                                               checked={formData.toAdmin=="1"?true:false}
                                                onChange={handleChange}
                                                /> 
                                                </div>
                                                <div className="col-6">
                                                <label className="pr-2"  htmlFor="toOthers">To Others</label>
                                                <input name="toAdmin" id="toOthers" 
                                                value={0}
                                                type="radio" className="" 
                                               checked={formData.toAdmin=="0"?true:false}
                                                onChange={handleChange}
                                                />
                                                </div>

                                              
											</div>
											
											{formData.toAdmin==0 ?<div className="form-group">
                                                {/* <input name="toAddress" type="email" className="form-control" placeholder="To*"
                                                value={formData.toAddress}
                                                onChange={handleChange}
                                                /> */}
                                                <Select
                                                isClearable={true}
                                                options={emailData}
                                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                              placeholder="To"
                                              onInputChange={handleSelectInputChange}
     onChange = {handleToChange}
                                                getOptionLabel={(option) => option.email}
                                                getOptionValue={(option) => option.email}
                                                menuIsOpen={dropDown}
                                                />

{!formData.toAddress && submitted&& <p className="help-block">This field is required</p>}
											</div>:''}
											
										
											<div className="form-group">
                                                <input name="subject" type="text" className="form-control" placeholder="Subject"
                                                value={formData.subject}
                                                onChange={handleChange}
                                                />
											</div>
											<div className="form-group">
                                                <textarea name="message" id="email_message" className="form-control" placeholder="Message"
                                                value={formData.message}
                                                onChange={handleChange} 
                                                style={{height: '120px'}}></textarea>
{!formData.message && submitted&& <p className="help-block">This field is required</p>}

											</div>
										
									
									
									</form>
        </ModalBody>
       <ModalFooter>
       {!sendLoader? 	<><button type="button" className="btn btn-default" onClick={toggle} ><i className="fa fa-times"></i> Discard</button>
											<button type="submit" className="btn btn-primary pull-right"
                                            onClick={handleSubmit}
                                            ><i className="fa fa-envelope"></i> Send Message</button></>:<button type="submit" className="btn btn-primary pull-right"
                                           disabled
                                            ><i className="fa fa-envelope"></i> Sending...</button>}
        </ModalFooter>
      </Modal>
        </>
    )


}

export default ComposeNewMail