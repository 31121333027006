import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function validateUserData(data){
    let errors = {}; 
    const regex = /[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/;
    const regex2 = /[a-zA-Z ]$/;
    const regex3 = /^\s/;
    if(Validator.isEmpty(data.firstName)){       
        errors.firstName ="This field cannot be null";           
    }else 
    if(!regex.test(data.firstName)){       
        errors.firstName ="Atleast 3 characters, alphabets only";         
    }
    if(Validator.isEmpty(data.lastName) || regex3.test(data.lastName)){       
        errors.lastName ="This field cannot be null";           
    }else
    if(!regex2.test(data.lastName)){        
        errors.lastName ="Please provide a valid input";           
    }
    if(Validator.isEmpty(data.email)){       
        errors.email ="This field cannot be null";           
    } else 
    if(!Validator.isEmail(data.email)){       
        errors.email ="Please enter a valid email";           
    }
    // if(!data.dob){       
    //     errors.dob ="Date of birth required";           
    // }
      
    return {
        errors,
        isValid:isEmpty(errors)
    };
    

}
 