import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './../../css/style.css';
import  classnames from 'classnames';
import loginvalidateInputs from './../validation/loginValidation';
import {loginAction, clearTokenVerificationMsgAction, clearReducerAction} from '../../actions/auth/authAction'
//import {authReducer} from '../../reducers/auth/authReducer'
import { connect } from 'react-redux';

class Login extends Component{
  constructor(props){
    super(props);
      this.state ={
        email: "",
        password: "",
        errors:{},
        isLoading:false,
        errorStatus:""
    };   
    this.onChange =  this.onChange.bind(this);
    this.onSubmit =  this.onSubmit.bind(this);
  }

  isValid(){
    const {errors, isValid} =  loginvalidateInputs(this.state);
    if(!isValid){
      this.setState({errors:errors});
   }   
   return isValid;   
   
  }

  onChange(e){   
    this.setState({errors:''});
    this.setState({[e.target.name]:e.target.value});
    
  }

  onSubmit(e){  
    e.preventDefault();
    if(this.isValid()){ 
      this.setState({errors:{},isLoading:true});     
      this.props.loginAction(this.state);      
    }
     
  }

  componentWillMount(){


    localStorage.clear()
  }


  componentWillUnmount(){

    this.props.clearTokenVerificationMsg()
    this.props.clearAuthReducer()
  }

  componentDidMount(){

    this.nameInput.focus();
  }

    render(){
      let {isLoginPending, loginError, verified} = this.props;
        return (
            <div>
        
        <Header {...this.props}/>
  
  {/* end header */}
  {/* main body */}
  <section className="main">
    <div className="container-fluid">
      <div className="signup_wrap">
        <div className="signup_icon" />
        <h3>Sign In</h3>       
        { loginError && (<div className="alert alert-danger text-center" > { loginError } </div>) }
        { verified && (<div className="alert alert-success text-center" > { "Successfully verified your account. Please login" } </div>) }
        <form className="needs-validation"  onSubmit={this.onSubmit}>        
          <div className="form-group row">
            <div className={classnames("col-sm-12",{'has-error':this.state.errors.email})}>
              <input 
                  name="email" 
                  type="email" 
                  className="form-control" 
                  id="email" 
                  placeholder="Email*" 
                  value={this.state.email}
                  onChange={this.onChange}
                  ref={(input) => { this.nameInput = input; }} 
              />
              {this.state.errors.email && <span className="help-block">{this.state.errors.email}</span> }
            </div>            
          </div>
          <div className="form-group row">

          <div className={classnames("col-sm-12",{'has-error':this.state.errors.password})}>             
            <input 
              name="password" 
                type="password"
                className="form-control" 
                id="password" 
                placeholder="Password*" 
                value={this.state.password}
                onChange={this.onChange}
             />         
        
         {this.state.errors.password && <span className="help-block">{this.state.errors.password}</span> }
          </div>
           
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <div className="custom-control checkbox-inline custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                <label className="custom-control-label" htmlFor="customCheck1">Remember Me</label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <input type="submit" className="btn btn-primary" value={ isLoginPending ? "Please wait...":"Sign In" } disabled = { (isLoginPending || !this.state.email || !this.state.password)  } />
            </div>
          </div>
        </form>
        <div className="note">
          <Link to='/forgot-password' className="forgot_pw">Forgot Password</Link>
          <Link to='/signup' className="signin_note">Don't have an account? Sign Up</Link>
          
        </div>
      </div>
    </div>
  </section>
  {/* end main body */}
  {/* footer */}
  <Footer/>
</div>


          
        )
    }
}
const mapStateToProps = state => ({   
     loginError:state.authReducer.loginError ,
     isLoginPending:  state.authReducer.isLoginPending,
     verified:state.tokenAuthReducer.result

 })

const mapDispatchToProps = dispatch => ({
     loginAction: (data) => dispatch(loginAction(data)) , 
     clearAuthReducer: () => dispatch(clearReducerAction()) , 
     clearTokenVerificationMsg: () => dispatch(clearTokenVerificationMsgAction())  
     
 })
 export default connect(mapStateToProps, mapDispatchToProps) (Login);
