import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import  classnames from 'classnames';
import resetPwdValidateInputs from '../components/validation/resetPasswordValidation';
import {useSelector, useDispatch} from 'react-redux'
import {changePasswordAction, clearChangePasswordReducerAction} from '../actions/auth/passwordAction'
const ChangePassword = (props) => {

  const changePwdError = useSelector(state => state.changePwdReducer.error)
  const changePwdResult = useSelector(state => state.changePwdReducer.result)
  const changePwdLoading = useSelector(state => state.changePwdReducer.loader)
  const dispatch = useDispatch()
  const {
    userId,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({password:'', userId:userId, repassword:''});
  const [errors, setError] = useState({});

 const isValid=()=>{
    const {errors, isValid} =  resetPwdValidateInputs(formData);
    if(!isValid){
      setError(errors);
   }   
   return isValid;   
   
  }

  const toggle = () =>{
    
    
    setModal(!modal);
    setFormData({password:'', userId:userId, repassword:''})
    setError({})
    if(modal){
      dispatch(clearChangePasswordReducerAction())

    }
  }
  const onSubmit = (e) =>{

    e.preventDefault();
    
    if(isValid()){ 

  //console.log("submitted value", formData)

  dispatch(changePasswordAction(formData))
  
}
  
  }
  const onChange = (e) =>{
    setError({})
    setFormData({
      ...formData,
      [e.target.name]:e.target.value})

  

  
  }



  return (
    <div>
      <Button className="btn small"  onClick={toggle}>Change Password</Button>
      {/* <button type="button" className="btn small"  >Change Password</button> */}
      <Modal isOpen={modal} toggle={toggle} className={"modal-dialog-centered"}>
      <ModalHeader toggle={toggle}>Change Password</ModalHeader>
    {!changePwdResult?  <form className="needs-validation"  onSubmit={onSubmit}>        

        <ModalBody>
          <div className="form-group row">
            <div className={classnames("col-sm-12",{'has-error':errors.password})}>
              <input 
                  name="password" 
                  type="password" 
                  className="form-control" 
                  id="password" 
                  placeholder="Password*" 
                  value={formData.password}
                  onChange={onChange}
                  maxLength={100}
                 
              />
              {errors.password && <span className="help-block">{errors.password}</span> }
            </div>            
          </div>
          <div className="form-group row">

          <div className={classnames("col-sm-12",{'has-error':errors.repassword})}>             
            <input 
              name="repassword" 
                type="password"
                className="form-control" 
                id="repassword" 
                placeholder="Re enter password*" 
                value={formData.repassword}
                onChange={onChange}
             />         
        
         {errors.repassword && <span className="help-block">{errors.repassword}</span> }
          </div>
           
          </div>
        </ModalBody>
        <ModalFooter>
         {changePwdError?'': <Button color="primary" onClick={onSubmit}>{changePwdLoading?'Please wait..':'Save'}</Button>}
          {' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
        </form>:<div> <ModalBody>
        <h4>Your password has been changed</h4>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>OK</Button>
        </ModalFooter></div>}
      </Modal>
    </div>
  );
}

export default ChangePassword;