import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';	
// import 'moment-timezone';
import { getDealCommentsAction, likeCommentAction, commentsReducerClear} from '../actions/huddleCommentsAction';
import ReplyDealComments from './replyDealComments';
import DealCommentLike from './dealCommentLike';
import DealCommentFlag from './dealCommentFlag';
import ReduxLazyScroll from 'redux-lazy-scroll'
import { avatarPath} from '../helpers/constants'
import HuddleCommentFlag from './HuddleCommentFlag';
import ReplyHuddleComments from './ReplyHuddleComments';
import moment from 'moment';
class HuddleCommentList extends Component {


	constructor(props) {

		super(props)

this.state = {
	likedComments:[],
	dislikedComments:[],

}

this.likeHandler = this.likeHandler.bind(this)
this.loadPosts = this.loadPosts.bind(this);
this.convertUTCToLocal = this.convertUTCToLocal.bind(this);
this.convertUTCToLocalTime = this.convertUTCToLocalTime.bind(this);
	

	}

	loadPosts() {
    
		const {skip, limit} = this.props
		//console.log("limit", limit);
		//console.log("skip", skip);

		let postData = {

			limit,
			skip,
			id:this.props.huddleId
		}
	
		this.props.getDealComments(postData)
	
	  }

	componentDidMount(){
window.scrollTo(0,0)

const {skip, limit} = this.props
let postData = {

	limit,
	skip,
	id:this.props.match.params.id
}
		//  this.props.getDealComments(postData)
		// this.props.getDealComments(this.props.huddleId)

		let cmntLikeIds = []
		let cmntdisLikeIds = []
	
	if(this.props.commentLikeIds)
		cmntLikeIds = Object.values(this.props.commentLikeIds);
	if(this.props.commentdisLikeIds)
		 cmntdisLikeIds = Object.values(this.props.commentdisLikeIds);

		this.setState({
			likedComments:cmntLikeIds,
			dislikedComments:cmntdisLikeIds,

		})


	}

	componentWillReceiveProps(nextProps){
		let cmntLikeIds = []
		let cmntdisLikeIds = []
	
	if(nextProps.commentLikeIds)
		cmntLikeIds = Object.values(nextProps.commentLikeIds);
	if(nextProps.commentdisLikeIds)
		 cmntdisLikeIds = Object.values(nextProps.commentdisLikeIds);

		this.setState({
			likedComments:cmntLikeIds,
			dislikedComments:cmntdisLikeIds,

		})

	}

	

	likeHandler =(action, postId) =>{

		const {likedComments, dislikedComments} = this.state



switch (action) {
	case  '1':
		let tempDislikes = [...dislikedComments]
		let tempLikes1 = [...likedComments]
	if(!likedComments.includes(postId)){
		tempLikes1.push(postId)

	}else{

		tempLikes1=likedComments.filter((item)=>item!=postId)


	}
	if(dislikedComments.includes(postId)){
		tempDislikes=dislikedComments.filter((item)=>item!=postId)

	}
	this.props.likeCommentAction(action, postId, this.props.huddleId)


	this.setState({
		likedComments:tempLikes1,
		dislikedComments:tempDislikes
	})
		
		break;
	case '0': 
	let tempLikes = [...likedComments]
	let tempDislikes1 = [...dislikedComments]
	if(!dislikedComments.includes(postId)){
		tempDislikes1.push(postId)

	}else{
		tempDislikes1=	dislikedComments.filter((item)=>item!=postId)


	}
	if(likedComments.includes(postId)){
		tempLikes=	likedComments.filter((item)=>item!=postId)

	}
	this.props.likeCommentAction(action, postId, this.props.huddleId)


	this.setState({
		likedComments: tempLikes,
		dislikedComments:tempDislikes1
	})
		
		break;

	default:
		break;
}

	}



	 convertUTCToLocal =(utcDt, utcDtFormat) => {
		var toDt = moment.utc(utcDt, utcDtFormat).toDate();
		return moment(toDt).format('DD-MM-YYYY');
	  }
	  convertUTCToLocalTime =(utcDt, utcDtFormat) => {
		var toDt = moment.utc(utcDt, utcDtFormat).toDate();
		return moment(toDt).format('hh:mm:ss A');
	  }



	render() {

const {result, loader, commentCount} = this.props

		return (

			<div>
	<div className="comment_box">
					<div className="box_header">
				<div className="comment_num floatLeft" ><span>{ commentCount}</span> {result&&result.length==1?`Comment`:`Comments`}</div>
						
						<div className="clearfix"></div>
					</div>
				
					<div className="posts">
						
					{this.renderComments()}
						
					
					</div>
				</div>

		</div> )

			
			




}

renderComments(){
	const {result, loader,hasMore, error} = this.props
	const {dislikedComments, likedComments} = this.state



	return (
<div className="container">
		<ReduxLazyScroll
		isFetching={loader}
		errorMessage={error}
		loadMore={this.loadPosts}
		hasMore={hasMore}
		threshold = {500}
	  >

{result&& result.map((item, idx)=>{

		return (<div className="post_reply_container" key = {idx} id={`post-${item.id}`}>
		<div className="row">
			<div className="col-md-4 col-sm-12">
				<div className="post_avatar_container">
					<div className="user_avatar">
					{item.user.avatar ? <img src={`${avatarPath}/${item.user.avatar}`} />:<img src={require("../images/avatar-150.png")}/>}
						
					</div>
					<div className="user_avatar_details">
						  <div className="post_usename"><a >{item.user.firstName}</a></div>
						<div className="poster_joined"><span><i className="fa fa-calendar" aria-hidden="true"></i></span> Joined {
							<Moment format="DD MMM YYYY">
							{item.user.created_at}
				</Moment>
					
						
						}</div>
						{item.user.reputationPoints>="0" ? <div className="poster_title"><span><i className="fa fa-star" aria-hidden="true"></i></span><span >{item.user.reputationPoints} Reputation</span></div>:''}
						{item.user.city && <div className="poster_title"><span><i className="fa fa-location-arrow" aria-hidden="true"></i></span><span >{item.user.city}</span></div>}
						
					</div>
				</div>
			</div>
			<div className="col-md-8 col-sm-12">
				<div className="post_text">
					<div className="post_text_top">
						<span className="post_date_time floatLeft"><span className="date">
						

						{this.convertUTCToLocal(item.fcm_created_dts)}
						
           
							</span> at <span className="time">

							{this.convertUTCToLocalTime(item.fcm_created_dts)}
								</span></span>
	<span className="post_corner floatRight"><span>#{idx+1}</span>
	<span className="dot">.</span>
	<HuddleCommentFlag postId={item.id} {...this.props}  flaggedComments={this.props.commentFlagIds} />
	</span>
						<div className="clearfix"></div>
					</div>
					<div className="post_text_content" dangerouslySetInnerHTML={{__html: item.fcm_comment}}>
					</div>
					<div className="post_text_bottom">
						<ReplyHuddleComments quotedFrom={item.user.firstName} {...this.props} post = {item.fcm_comment} parent={item.id} huddleId={this.props.huddleId} key={`${item.id}reply`} type="reply" />
						{/* <a href="#" >Reply</a><span className="dot ">.</span> */}
						<DealCommentLike {...this.props} postId={item.id}  key={item.id} likedComments={likedComments} dislikedComments={dislikedComments}  getLikeAction={this.likeHandler} likeCount={item.fcm_count_like} dislikeCount={item.fcm_count_dislike} />
					</div>
				</div>
			</div>
		</div>
	</div>)


	})}
		  </ReduxLazyScroll>

		  <div className="row posts-lazy-scroll__messages">
      {loader && <div className="spinner-border"> </div>}

    </div>
		  </div>
	)

	

}
}
const mapStateToProps = state => ({
	loader: state.huddleCommentsReducer.loader,
	result:state.huddleCommentsReducer.result &&state.huddleCommentsReducer.result,
commentLikeIds:state.profileReducer.myForumCommentslikes,
commentdisLikeIds:state.profileReducer.myForumCommentsdislikes,
commentFlagIds:state.profileReducer.myForumCommentsFlags,

error: state.huddleCommentsReducer.error,
hasMore: state.huddleCommentsReducer.hasMore,
skip: state.huddleCommentsReducer.skip?state.huddleCommentsReducer.skip:0,
limit: state.huddleCommentsReducer.limit?state.huddleCommentsReducer.limit:20,

commentCount:state.huddleCommentsReducer &&state.huddleCommentsReducer.commentCount,
  
  
  })
  const mapDispatchToProps = dispatch => ({
	getDealComments: (postData)=> dispatch(getDealCommentsAction(postData)),
	likeCommentAction: (status, postId, dealId)=> dispatch(likeCommentAction(status, postId, dealId)),
	
  })

export default connect(mapStateToProps,mapDispatchToProps) (HuddleCommentList)
