
export default (state = {}, action) => {
  switch (action.type) {
    case 'BRANDS_FETCH_SUCCESS':
      return {
        brands: action.payload,
        error: false
      }
    case 'BRANDS_FETCH_FAILURE':
      return {
        brands: [],
        error: true
      }
    default:
      return state
  }
}