

export default (state = {
  result: false,
  loader: false,
  error: false,

}, action) => {
    switch (action.type) {
   

        case 'CHANGE_PASSWORD_SUCCESS':          
          return {
           
            loader: false,
              result: action.payload,
              error:false
          }
        case 'CHANGE_PASSWORD_FAILURE':
          return {
            result: false,
            loader: false,
          
            error: action.payload.message
          }
          case 'CHECKING_CHANGE_PASSWORD':
            return {
              result: false,
             loader: true,
            error:false


            }
          case 'CLEAR_CHANGE_PASSWORD_RESULTS':
            return {
              result: false,
              loader: false,
              error: false,
            }

          
     default:
      return state
    }
   }

   

   