import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';




const MasterEditCatPopup = (props) => {

 
  const {
   isOpen,
   editData,
   catList
  } = props;

  const [modal, setModal] = useState(isOpen);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({name:editData.name, description:editData.description, id:editData.id, status: editData.status, parent:editData.parent});




  const toggle = () =>{
    
    
  
    if(modal){
      setFormData({name:'', id:'', description:'', status: '', parent:''})
      props.closeModal()
      
    }
    setModal(!modal);
    setSubmitted(false)
    


  
  }
  const onSubmit = (e) =>{

    setSubmitted(true)

    e.preventDefault();
    if(formData.name){
props.onUpdated(formData)
      //console.log("submitted value", formData)
    }

  

 
  

  
  }
  const onChange = (e) =>{
   
    setFormData({
      ...formData,
      [e.target.name]:e.target.value})

  

  
  }



  return (
    <div>
     
      {/* <button type="button" className="btn small"  >Change Password</button> */}
      <Modal isOpen={modal} toggle={toggle} className={"modal-dialog-centered"}>
      <ModalHeader toggle={toggle}>Edit</ModalHeader>
     

        <ModalBody>
        <div className="row">
    <div className="col-sm-12 col-md-12 col-lg-12">
    <div className={"form-group"}>
                    <label  for="dealUrl">Name</label>
                    <input 
                    
                    type="text" 
                    name="name" className="form-control"  id="name" value={formData.name} onChange={(e)=>onChange(e)}
                    />
                    {!formData.name && submitted && <p className="help-block">This field is required</p>}
                </div>


    <div className={"form-group"}>
                    <label  for="dealUrl">Description</label>
                  
                   <textarea value={formData.description} className="form-control"  onChange={(e)=>onChange(e)} name="description" id="description" cols="30" rows="2">{formData.description}</textarea>
                </div>

                <div className={"form-group"}>
                    <label  for="dealUrl">Parent Category</label>
                  
                  <select className="form-control" onChange={(e)=>onChange(e)}   name="parent" id="parent">
                    <option value ="0">Parent</option>
{catList && catList.map((cat,idx)=>{

return (cat.ctg_active == 1 && cat.id!=formData.id && (<option selected={cat.id === formData.parent?"selected":""} value ={cat.id}>{cat.ctg_name}</option>))
})}
                  
                  </select>
                </div>


    <div className={"form-group"}>
                    <label  for="dealUrl">Status</label>
                  
                  <select className="form-control" onChange={(e)=>onChange(e)}   name="status" id="status">

                    <option selected={formData.status ===0?"selected":''} value="0">Inactive</option>
                    <option selected={formData.status ===1?"selected":''} value="1">Active</option>
                  
                  </select>
                </div>

               
</div>


    </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onSubmit}>{submitted ?"Please wait":"Save"}</Button>
        </ModalFooter>
      </Modal>
      </div>
  
  );
}

export default MasterEditCatPopup;