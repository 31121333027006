
export default (state = {}, action) => {
  switch (action.type) {
    case 'MY_DEAL_FETCH_BEGINS':
      return {
        loader: true,
        error: false,
        result: null,
        count: 0,
      }

    case 'MY_DEAL_SUCCESS':
      return {
        loader: false,
        error: false,
        result: action.payload.message,
        count: action.payload.totalCount,
      }

    case 'MY_DEAL_FAILURE':
      return {
        loader: false,
        error: true,
        result: null
      }
    case 'CLEAR_MYDEALS_RESULTS':
      return {
        loader: false,
        error: false,
        result: null
      }
    default:
      return state
  }
}