

export default (state = {
  isFpwdSuccess: false,
  isFpwdPending: false,
  fpwdError: null,
  result:null
}, action) => {
    switch (action.type) {
   

        case 'FORGOT_PASSWORD_SUCCESS':          
          return {
            isFpwdSuccess: false,
            isFpwdPending: false,
              result: action.payload,
              fpwdError:null
          }
        case 'FORGOT_PASSWORD_FAILURE':
          return {
            isFpwdSuccess: false,
            isFpwdPending: false,
            result:null,
            fpwdError: action.payload.message
          }
          case 'CHECKING_FORGOT_PASSWORD':
            return {
              isFpwdSuccess: false,
             isFpwdPending: true,
            result:null,
            isFpwdSuccess: false,
            fpwdError:null


            }
          case 'CLEAR_FORGOT_PASSWORD_RESULTS':
            return {
              isFpwdSuccess: false,
              isFpwdPending: false,
             result:null,
             isFpwdSuccess: false,
             fpwdError:null
            }

          
     default:
      return state
    }
   }

   

   