import React, { Component } from 'react';
import Header from '../components/auth/Header';
import Footer from '../components/auth/Footer';
import {verifyTokenAction} from '../actions/auth/authAction'
import {connect} from 'react-redux' 
import { Redirect } from 'react-router-dom';


class AuthenticateReg extends Component {


  componentDidMount(){


   const {token} = this.props.match.params

   if(token){
         //console.log("token", token)

         this.props.verifyToken(token);

   }

  }
    render(){
      const {verified} = this.props
        return(
         <div>
      <section className="main">
{verified =='verified' ? <Redirect to={{
  pathname: '/login'
}} />:verified =='error' ? <Redirect to={{
  pathname: '/'
}}/>: <div className="row">

<div className = "spinner-border"></div>
        </div>}




       {/* {!verified ? <div className="row">

<div className = "spinner-border"></div>
        </div>:<Redirect to={{
                    pathname: '/login'
                }} />} */}
</section>


         </div>
        ) }

    

}

const mapStateToProps = state => ({
verified:state.tokenAuthReducer.result
 
 })
 const mapDispatchToProps = dispatch => ({
  verifyToken: (token) => dispatch(verifyTokenAction(token)),
   
 
 })
 export default connect(mapStateToProps, mapDispatchToProps)(AuthenticateReg)