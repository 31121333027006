import React, { useEffect, useState } from'react'
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import {trimText} from '../helpers/functions'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {readMessage} from '../actions/mailAction'
import ReplyMail from './ReplyMail';

import Moment from 'react-moment';	
import moment from 'moment';
function MailList(props){


const {data, changeItems, selectedItems, menu} = props
const [modal, setModal] = useState(false);
const [replyModal, setReplyModal] = useState(false);
const [message, setMessage] = useState({});
const dispatch = useDispatch()

const openMessage = (msg) =>{

    setModal(true)
    setMessage(msg)

    if(menu =="inbox"){
        dispatch(readMessage(msg.id));

    }

}
const closeModal = () =>{

    setModal(false)
    setMessage({})


}
const closeReplyModal = () =>{

    setReplyModal(false)
    setMessage({})


}
const openReplyModal = () =>{

    setModal(false)
    setReplyModal(true)
   


}
const trimTextCustom =(text, length)=>{

    let newString = text
    let end = text.indexOf('-------');
    if(end>0){
      newString = text.substring(0,end)
    }
 

    if (newString && newString.length > length) {
        return newString.substring(0, length) + '...';
     } else return newString



}
const formatMessage =(text)=>{
    if(text){

        let newString = text
        let end = text.indexOf('-------');
        if(end>0){
        let  newString1 = text.substring(0,end)
        let  newString1EndPart = text.substring(end+1)
        let  newString2 = newString1EndPart.split('-----------------------------------------------------------------------------------------')

        return (<>{newString1} 
        {newString2 && newString2.map((str)=>{

        return (<>{str}<hr/></>)
        })}
        
        </>);
        }else return newString 
   

    }

  
 

   



}

const convertUTCToLocal =(utcDt, utcDtFormat) => {
    var toDt = moment.utc(utcDt, utcDtFormat).toDate();
    return moment(toDt).format('DD-MM-YYYY hh:mm:ss A');
  }



    return(<div className="table-responsive">
    <table className="table">
        <tbody>

            {data && data.map((item, idx)=>{

return ( <tr className ={item.read==1?"read":''} key={idx}>
    <td className="action"><input onChange={()=>changeItems(item.id)} type="checkbox" checked= {selectedItems.includes(item.id)?true:false} /></td>
    {/* <td className="action"><i className="fa fa-star-o"></i></td>
    <td className="action"><i className="fa fa-bookmark-o"></i></td> */}
    <td className="name"><a >{item.userName}</a></td>
    <td className="subject"><Link onClick={()=>openMessage(item)} to="#">{trimTextCustom(item.subject?item.subject+' - '+item.message:item.message,82)}</Link></td>
    <td className="time">
    {/* <Moment locale="de" format="DD-MM-YYYY HH:mm a">
    {item.date}
            </Moment> */}

           { convertUTCToLocal(item.date)}
    
    </td>
</tr>)


            })}
            
        
    </tbody></table>
    <Modal isOpen={modal}   backdrop={"statis"} className="composeModal" keyboard={false}>
        <ModalHeader toggle={closeModal}>Message</ModalHeader>
        <ModalBody>
           <p> <b>{menu=="inbox"?"From":"To"}</b>: {message.userName+' <'+message.userEmail+'>'}</p>
           <p> <b>Subject</b>: {message.subject?message.subject:"(no subject)"}</p>
           <p>  {formatMessage(message.message)}</p>
     
        </ModalBody>
       <ModalFooter>
    {menu =="inbox" && <button type="button" className="btn btn-primary" onClick={openReplyModal} ><i className="fa fa-reply"></i> Reply</button>}
     <button type="button" className="btn btn-default" onClick={closeModal} ><i className="fa fa-times"></i> Close</button>
											
        </ModalFooter>
      </Modal>


      <ReplyMail isOpen ={replyModal}  closeReplyModal={closeReplyModal} data={message}  />


    




</div>)


}

export default MailList