
import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';

const logStatus = localStorage.getItem('myStatus')
const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        logStatus === 'logged_in'
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
  )

  export default PrivateRoute;