import React, { Component } from 'react';
import Header from '../components/auth/Header';
import Footer from '../components/auth/Footer';
import {connect} from 'react-redux' 
import Page from './WebsiteTermsofUse.html';
var htmlDoc = {__html: Page};

class Termsofuse extends Component {


  componentDidMount(){

		window.scrollTo(0, 0)

  }
    render(){

        return(
         <div>

<Header {...this.props} />
<section class="main">
               <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="wrpBox privacy_content">
                  <div dangerouslySetInnerHTML={htmlDoc} ></div>
                  <p>This document was last updated on September 20, 2020</p>

                     
                  </div>
               </div>
            </div>
            
         </div>
               </section>
      <Footer />
         </div>
          
        ) }

    

}

const mapStateToProps = state => ({
 
 
 })
 const mapDispatchToProps = dispatch => ({

   
 
 })
 export default connect(mapStateToProps, mapDispatchToProps)(Termsofuse)