import React, { Component } from 'react'
import axios from 'axios';
import {url} from '../helpers/constants';
import { connect } from 'react-redux';

import { pauseResumeAction, deleteAlertAction } from '../actions/dealAlertAction';


class DealAlertList extends Component {

    constructor(props) {

        super(props)
        this.state = {

			successMsg:'',
			showDel:'',
			delIds:[]
        
          }
       
       
this.editAlert = this.editAlert.bind(this)
this.pauseResume = this.pauseResume.bind(this)
this.showDelHandler = this.showDelHandler.bind(this)
this.deleteAlert = this.deleteAlert.bind(this)

       

    
      
        


    }

    componentDidMount() {

    //  this.props.fetchDealAlert();

    }

   

   
    editAlert(e,alert) {

     
  
this.props.getAlert(alert)



    }
    pauseResume(e,status,id) {
e.preventDefault();

if(status ===0){
	this.setState({
		successMsg:'Paused successfully'
	})
}else if(status ===1){

	this.setState({
		successMsg:'Resumed successfully'
	})
}
this.props.pauseResume(status,id);


    }
    showDelHandler(id) {


	this.setState({
		successMsg:'',
		showDel:id
	})



    }
    deleteAlert(id) {

		this.props.deleteAlert(2,id)


	this.setState({
		...this.state,
		delIds:[...this.state.delIds, id]
	})



    }
    componentWillUnmount(){


}

 

 


    render() {
const {submitLoader, submitResult} = this.props

        return (
            <div className="wrpBox searchDealAlerts">
					<div className="row">
						<div className="col-md-12">
							<h4>Your Deal Alerts</h4>	
							<div id="accordion" className="accordion">

								{!submitLoader ? this.renderDealAlerts():'Loading...'}
						       
						      
						    </div>
						    {/* <div className="showAllBtn text-center">
						    	<a href="#" className="btn btn-secondary">Show All</a>
						    </div> */}
						</div>
					</div>		
				</div>

        )


	}
	
	renderDealAlerts(){

		const { submitLoader, submitResult, pauseLoader, pauseResult } = this.props
		const {successMsg, showDel, delIds} = this.state
	return	submitResult && submitResult.map((alert, idx)=>{
		if(delIds.includes(alert.id)){
		return true
		}

if(alert.id === pauseResult){
	
	switch (successMsg) {
		case 'Paused successfully':
			 alert.dua_active = 0
			break;
		case 'Resumed successfully':
			alert.dua_active = 1
			break;
	
		default:
			break;
	}
}
let keyWords =  alert.dua_keyword && alert.dua_keyword.split(',')
			return ( <div className="card mb-0" key ={idx}>
		<div className="card-header collapsed" >
			<div className="row">	
				<div className="col-md-5">
	<div className="dealAlertTitle">
		<a className="card-title" data-toggle="collapse" href={`#acc-${idx}`} >{alert.dua_title}</a>
	</div>
				</div>
				<div className="col-md-7">
					
					{showDel != alert.id?<div className="dealAlertControls">
					
						<span className={pauseResult==alert.id?`alrtMsg success`:`alrtMsg  d-none`}>{successMsg}</span>
						
						
						&nbsp;
					
						<div className="buttongroup inline">
							<a href="#" onClick={(e)=>this.editAlert(e,alert)} id="edit_btn"  className="button edit "><span className="icon icon-pencil"><i className="fa fa-pencil" aria-hidden="true"></i></span>Edit</a>
						{pauseLoader!=alert.id?<span>
						{alert.dua_active==0?<a href="#"  className="button resume" onClick={(e)=>this.pauseResume(e,1,alert.id)}><span className="icon icon-resume"><i className="fa fa-play" aria-hidden="true"></i></span>Resume Deal Alert</a>:	<a href="#"  className="button pause"  onClick={(e)=>this.pauseResume(e,0,alert.id)}>
								<span className="icon icon-pause"><i className="fa fa-pause" aria-hidden="true"></i></span>Pause</a>}

						</span>:<span style={{'width':'20px', 'height':'20px'}} className="spinner-border"></span>}
						
							{/* <a href="#"  className="button share" data-toggle="modal" data-target="#shareModal"><span className="icon icon-share"><i className="fa fa-share-alt" aria-hidden="true"></i></span>Share</a> */}
						</div>
						
						<button  className="button delete inline" onClick={()=>this.showDelHandler(alert.id)} ><span className="icon icon-trash"><i className="fa fa-trash" aria-hidden="true"></i></span>Delete</button>
						
					</div>:
					<div className="deleteConfirmation text-right">
						  <span>Are you sure? </span>
						  <button className="btn btn-primary" data-role="confirm" onClick={()=>this.deleteAlert(alert.id)} >Yes</button>
						  <button className="btn btn-primary" data-role="cancel" onClick={()=>this.showDelHandler('')}>No</button>
						  
						</div>}
				</div>
			</div>
			{/* <div className="modal fade" id="shareModal" tabindex="-1" role="dialog" aria-labelledby="shareModalLabel" aria-hidden="true">
					  <div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-content">
							  <div className="modal-header">
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								  <span aria-hidden="true">&times;</span>
								</button>
							  </div>
						  <div className="modal-body">
						   <div className="text-center">
								<h2>Share Deal Alert</h2>
									<p>Deals get better when shared with others.</p>
						   </div>
							<div className="form-group">
								<label><strong>Share with link</strong></label>
								<div className="copyLink">
									<input id="shareByLink" name="shareByLink" type="text" className="textinput shareByLink" value=""/>
									<button id="shareByCopyBtn" type="submit" data-clipboard-target="#shareByLink" className="btn btn-primary ">Copy</button>
								  </div>
							</div>
						  </div>
						  
						</div>
					  </div>
					</div>
				</div> */}
		</div>
		<div id={`acc-${idx}`} className="card-body collapse" data-parent="#accordion">
			<div className="dealAlertSettings preview_details" >
				<div>
					<span className="catBox">Keywords</span>

{keyWords&& keyWords.map((word, idy)=>{

return <span className="tagBox" key={idy}>{word}</span>

})}
					
				</div>
				{/* <div><span className="catBox">Category</span><span className="tagBox">Babies &amp; Kids</span><span className="tagBox">Kids</span></div>
				<div><span className="catBox">Store</span><span className="tagBox">Colorado Pen</span></div>
				<div><span className="catBox">Brand</span><span className="tagBox">Nike</span></div> */}
			</div>
			{/* <div className="dealAlertSettings edit_details"  style={{'display': 'none'}}>
				<div><span className="catBox">Keyword</span><span className="tagBox">dress</span></div>
				<div><span className="catBox">Category</span><span className="tagBox">Babies &amp; Kids</span><span className="tagBox">Kids</span></div>
				<div><span className="catBox">Store</span><span className="tagBox">Colorado Pen</span></div>
				<div><span className="catBox">Brand</span><span className="tagBox">Nike</span></div>
				<div className="bottomSection">
					<button className="btn btn-primary" >Save Deal Alert</button>
					<button className="btn btn-secondary">Cancel</button>
					
					<span className="lastEdit">Last edit on <span className="black">01/29/20</span></span>
				  </div>
			</div> */}
		</div>
	</div>)


		})


		
	}


}

const mapStateToProps = state => {
    return {
        submitLoader: state.dealAlertReducer.loader,
        submitResult: state.dealAlertReducer.result,
		submitError: state.dealAlertReducer.error,
		pauseLoader: state.dealAlertPauseReducer.loader,
		pauseResult: state.dealAlertPauseReducer.result,
		pauseError: state.dealAlertPauseReducer.error,

       
    }
}
const mapDispatchToProps = dispatch => ({
  
  pauseResume:(status, id)=> dispatch(pauseResumeAction(status, id)),
  deleteAlert:(status, id)=> dispatch(deleteAlertAction(status, id))

})

export default connect(mapStateToProps, mapDispatchToProps)(DealAlertList)