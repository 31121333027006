import React, { useEffect, useState, useReducer, useRef } from 'react';
import { Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import uuid from "uuid";
import {imagePath, avatarPath} from '../helpers/constants'
import axios from 'axios';
import {url} from '../helpers/constants';
import AvatarCrop from './avatarCrop';


function ChangeAvatar(props) {

   const [tempSelection, SettempSelection] = useState('');
   const [files, setFiles] = useState([]);
   const [CroppedFile, setCroppedFile] = useState('');
   const [modal, setModal] = useState(false);

 
   const toggle = () => {
       setModal(!modal)
       setFiles([])
       setCroppedFile('')
       if(modal){
        SettempSelection('')
       }
      
};

const getCrop =(dataUrl)=>{

    setCroppedFile(dataUrl)


}
   const uploadAvatar = () => {
  
       if(files.length>0){
        const data = new FormData()
        

        // files.map((file)=>{
        //     data.append('file', file)
           
        // })

        data.append('file',CroppedFile)
     
        SettempSelection(CroppedFile)
        // SettempSelection(files[0].preview)

        const config = {
            headers: { 'content-type': 'multipart/form-data',
                        Authorization:`Bearer ${localStorage.getItem('myToken')}` 
                    }
        }

        axios.post(url+'/api/changeAvatar', data,config
        )
            .then(res => {
               
    
                if (res.status ==200) {
    
                   
                } else {
    
                 
    
                }
            })
       
       
       }
       setModal(false)
       setFiles([])
       setCroppedFile('')
   
   };
    const { getRootProps, getInputProps, rejectedFiles } = useDropzone({
        accept: 'image/*',
        multiple:false,
        minSize: 0,
        maxSize: 1000000,
        onDrop: acceptedFiles => {           
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
              })));
        
        }
    });
    const isFileTooLarge =
    rejectedFiles.length > 0 && rejectedFiles[0].size > '1000000';

    const thumbs = files.map(file => (
        <div key={file.name} className="col-lg-6 col-md-6 col-6">

            <div  className="postImgWrp"  >
              
                <span className="postImg_DltWrp"  >
                   
             <AvatarCrop  avtrImage = {file.preview} doCrop = {getCrop} />
                
                
                {/* <img
                    src={file.preview}
                  
                /> */}
                
            </span>
                
              
            </div>
          
        </div>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    let selectedAvatar = tempSelection?tempSelection:props.myAvatar?`${avatarPath}/${props.myAvatar}`:''

    return (
        <div>
{selectedAvatar?<img  src={selectedAvatar} className="avatarImg"/>:<img src={require("../images/avatar-1.png")} className="avatarImg"/>}
           {!props.disabled && <button type="button" className="btn small" onClick={toggle} disabled={props.disabled}   >Change your Avatar</button>
}
        <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Upload Image</ModalHeader>
        <ModalBody>
            <Row>
                <div className = "col-md-6 col-lg-6 col-6">
                <div {...getRootProps({ className: 'dropzone pointer' })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop Image here, or click to select an Image</p>
                {isFileTooLarge && (
          <div className="text-danger mt-2">Please upload image files of size less than 1 MB.</div>
        )}
            </div>

                </div>
           

  
                {thumbs}

            </Row>
     
        
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={uploadAvatar}>Save</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>

           
           
        </div>
          
    
    );
}
export default ChangeAvatar