
export default (state = {}, action) => {
  switch (action.type) {

    //store
    case 'STORE_ADD_BEGINS':
      return {
        error: null,
        loader: true,
        result: null,
        updateResult:null
      }
    case 'STORE_ADD_SUCCESS':
      return {
        error: null,
        loader: false,
        result: action.payload,
        updateResult:false

      }
    case 'STORE_ADD_FAILURE':
      return {
        error: action.payload,
        loader: false,
        result: null,
        updateResult:null

      }
    case 'STORE_UPDATE_SUCCESS':
      return {
        error: null,
        loader: false,
        result: null,
        updateResult:action.payload,

      }
    case 'STORE_UPDATE_FAILURE':
      return {
        error: action.payload,
        loader: false,
        result: null,
        updateResult:null

      }
//brands
case 'BRAND_ADD_BEGINS':
  return {
    error: null,
    loader: true,
    result: null,
    updateResult:null
  }
case 'BRAND_ADD_SUCCESS':
  return {
    error: null,
    loader: false,
    result: action.payload,
    updateResult:false

  }
case 'BRAND_ADD_FAILURE':
  return {
    error: action.payload,
    loader: false,
    result: null,
    updateResult:null

  }
case 'BRAND_UPDATE_SUCCESS':
  return {
    error: null,
    loader: false,
    result: null,
    updateResult:action.payload,

  }
case 'BRAND_UPDATE_FAILURE':
  return {
    error: action.payload,
    loader: false,
    result: null,
    updateResult:null

  }
//TAGS
case 'TAGS_ADD_BEGINS':
  return {
    error: null,
    loader: true,
    result: null,
    updateResult:null
  }
case 'TAGS_ADD_SUCCESS':
  return {
    error: null,
    loader: false,
    result: action.payload,
    updateResult:false

  }
case 'TAGS_ADD_FAILURE':
  return {
    error: action.payload,
    loader: false,
    result: null,
    updateResult:null

  }
case 'TAGS_UPDATE_SUCCESS':
  return {
    error: null,
    loader: false,
    result: null,
    updateResult:action.payload,

  }
case 'TAGS_UPDATE_FAILURE':
  return {
    error: action.payload,
    loader: false,
    result: null,
    updateResult:null

  }
//category
case 'CATEGORY_ADD_BEGINS':
  return {
    error: null,
    loader: true,
    result: null,
    updateResult:null
  }
case 'CATEGORY_ADD_SUCCESS':
  return {
    error: null,
    loader: false,
    result: action.payload,
    updateResult:false

  }
case 'CATEGORY_ADD_FAILURE':
  return {
    error: action.payload,
    loader: false,
    result: null,
    updateResult:null

  }
case 'CATEGORY_UPDATE_SUCCESS':
  return {
    error: null,
    loader: false,
    result: null,
    updateResult:action.payload,

  }
case 'CATEGORY_UPDATE_FAILURE':
  return {
    error: action.payload,
    loader: false,
    result: null,
    updateResult:null

  }

//suspend
    case 'MASTER_DEL_SUCCESS':
      return {
        error: null,
        loader: false,
        result: action.payload,
        updateResult:null

      }
    case 'MASTER_DEL_FAILURE':
      return {
        error:  action.payload,
        loader: false,
        result: null,
        updateResult:null

      }

//clear
    case 'MASTER_ACTION_CLEAR':
      return {
        error: null,
        loader: false,
        result: null,
        updateResult:null

      }


    default:
      return state
  }
}