import React, { Component } from 'react';
import Header from '../components/auth/Header';
import Footer from '../components/auth/Footer';
import {connect} from 'react-redux' 

class AcceptablePolicy extends Component {


  componentDidMount(){

		window.scrollTo(0, 0)

  }
    render(){

        return(

         <div>
<Header {...this.props} />
<section class="main">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="wrpBox privacy_content">
                     <h2>Acceptable Use Policy</h2>
                   
                     
                  </div>
               </div>
            </div>
            
         </div>
      </section>
      <Footer />
         </div>
          
        ) }

    

}

const mapStateToProps = state => ({
 
 
 })
 const mapDispatchToProps = dispatch => ({

   
 
 })
 export default connect(mapStateToProps, mapDispatchToProps)(AcceptablePolicy)