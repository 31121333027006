import React, { Component } from 'react';
import {imagePath} from '../helpers/constants'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import uuid from "uuid";

import $ from "jquery";

class UpdateDealImages extends Component {

    constructor(props){
super(props)

this.state = {
images:[],
toggle:false,
selectedItem:{}

}

this.removeImage = this.removeImage.bind(this);
this.makePrimary = this.makePrimary.bind(this);
this.toggleConfirm = this.toggleConfirm.bind(this);
this.confirmDelete = this.confirmDelete.bind(this);

    }

    componentDidMount(){
        let tempImages =[]

        if(this.props.imageData){
            this.props.imageData.map(img=>{

                tempImages.push({'id':img.id, 'url':img.preview, 'primary':this.props.primaryImage == "thumb/"+img.preview?1:0})
            })

        }

        this.setState({

            images:tempImages
        })

    }

    componentWillReceiveProps(nextProps){

        if(!nextProps.primaryStatus){
            $(".image-radio").removeClass('image-radio-checked');
            const {images} = this.state
            let newArr = [...images]
            for(let i = 0; i < newArr.length; i++) {
             
                  
    
                    newArr[i].primary = 0
                
            }
    
            this.setState({
                images:newArr
               
            })
         

        }
    }

    makePrimary = (event,img)=>{
       
        event.preventDefault();
        const {images} = this.state
        let newArr = [...images]
        for(let i = 0; i < newArr.length; i++) {
            if (newArr[i].url == img) {
                newArr[i].primary = 1
                var filename = img 
            
            }else{

                newArr[i].primary = 0
            }
        }

        this.setState({
            images:newArr
           
        })
     
        this.props.dragPrimarySet('url',filename)

    }
    removeImage = (event,img)=>{
        event.preventDefault();

     
        if(!img.primary){

            this.setState({
                ...this.state,
                selectedItem:img,
                toggle:true
            })

            // this.props.sendPrimaryWarning(false, img.id);

            // const {images} = this.state
            // let tempImg = [...images]
    
            // tempImg=   tempImg.filter((item)=>item!=img);
    
            // this.setState({
            //     images:tempImg
            // })

        }else{

           

            this.props.sendPrimaryWarning(true, img.id);

        }

     

    

      
        

        

    }


    toggleConfirm= ()=>{

        this.setState({
            ...this.state,
            toggle:!this.state.toggle
        })

    }
    confirmDelete= ()=>{

        const {selectedItem} = this.state

          this.props.sendPrimaryWarning(false, selectedItem.id);

            const {images} = this.state
            let tempImg = [...images]
    
            tempImg=   tempImg.filter((item)=>item.id!=selectedItem.id);
    
            this.setState({
                images:tempImg,
                toggle:false,
                selectedItem:{}
            })



    

    }


    render(){
        const {imageData} = this.props
const {images} = this.state
let isPrimary = false;

        return(
            <>
            <div className="row urlImgs">
               {   images &&  images.map((img, id)=>{




 
        if(img.primary == 1){
            isPrimary = true;
         
         }else isPrimary = false
      
  


// 
return( <div className="col-lg-4 col-md-6 col-6 text-xs-center" key={id}>
                
<label  className={`image-checkbox image-checkbox-checked postImgWrp`} onClick={(e)=>this.removeImage(e,img)}>
   
<span className="postImg_DltWrp">
<img src={`${imagePath}/${this.props.dealId}/${img.url}`} />
   <i className="fa fa-trash  pointer"  title="click to delete"></i>
</span>

   
 
    
</label>
<label className={isPrimary?"image-radio image-radio-checked":"image-radio"}  onClick={(e)=>this.makePrimary(e,img.url)} >
    <i className="fa fa-star" aria-hidden="true" id={img.url}></i><span className="primary_text">Primary image</span>
    
</label>
</div>)

        })}
      
        </div>


        {/* confirm modal */}
        <Modal isOpen={this.state.toggle} toggle={this.toggleConfirm} className={"modal-dialog-centered"}>

         
     

        <ModalBody>
     
<p className="pt-2">Are you sure, you want to delete this image?</p>


         
        </ModalBody>
        <ModalFooter>
    <Button color="primary" onClick={(e)=>this.confirmDelete()}>Yes</Button>
          {' '}
          <Button color="secondary" onClick={(e)=>this.toggleConfirm(e)}>No</Button>
        </ModalFooter>
        </Modal>
        {/* /confirm modal */}
        </>
        )
    }
}
export default UpdateDealImages