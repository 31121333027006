
export default (state = {}, action) => {
  switch (action.type) {
    case 'GET_REPUTATION_BEGINS':
      return {
        loader: true,
        error: false,
        result: null,
      
      }
    case 'GET_REPUTATION_SUCCESS':
      return {
        loader: false,
        error: false,
        result: action.payload,
    
      }
    case 'GET_REPUTATION_FAILURE':
      return {
        loader: false,
        error: false,
        result: null,
      

      }

    default:
      return state
  }
}