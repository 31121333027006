import React, {useState} from 'react'
import { CustomInput, Form, FormGroup, Label, Button, ButtonGroup } from 'reactstrap';

function HomeHeaderFilter(props){



    const [filterData,  setFilterData] = useState({ toggleExpired:0,
    filterBy:'new',
    
    })

    const handleSelectChange = (value) =>{

        const {changeFilter} = props


        let filterObj = {...filterData,filterBy:value }
        changeFilter(filterObj)
        setFilterData(filterObj)

        
    }
    const handleSwitchChange = (e) =>{

        const {changeFilter} = props
     

if(e.target.checked){
   
    let filterObj = {...filterData,toggleExpired:1 }
    setFilterData(filterObj)
    changeFilter(filterObj)
}else {
    let filterObj = {...filterData,toggleExpired:0 }
    setFilterData(filterObj)
    changeFilter(filterObj)
}

    }



    return (<div className="filter-header ">
    <div className="row align-items-center">
<div className="col-lg-8 col-md-12 form-group">

{/* <select name="filter" id="filter" className="form-control" onChange={handleSelectChange} value={filterData.filterBy} >

<option value="new" selected> New Deals</option>
<option value="featured" > Featured Deals</option>
<option value="expired" disabled={filterData.toggleExpired=="0"?true:false} > Expired Deals</option>

</select> */}
<ButtonGroup>
      <Button onClick={()=>handleSelectChange('new')} className={filterData.filterBy=="new"?"active":''}  >New Deals</Button>
      <Button onClick={()=>handleSelectChange('featured')} className={filterData.filterBy=="featured"?"active":''}  >Featured Deals</Button>
      <Button onClick={()=>handleSelectChange('popular')} className={filterData.filterBy=="popular"?"active":''} >Popular Deals</Button>
    
    </ButtonGroup>


  
</div>
<div className="col-lg-4 col-md-12">
<CustomInput onChange={handleSwitchChange} type="switch" className="pull-right mb-3" id="exampleCustomSwitch" value="1" checked={filterData.toggleExpired} name="customSwitch" label="Show expired deals" />
</div>


    </div>


  </div>)



}

export default HomeHeaderFilter