import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const FpwdResult = (props) => {
  const {
    type,
    className
  } = props;

  const [modal, setModal] = useState(true);

  const toggle = () =>{
    
    props.history.push('/login')
    setModal(!modal);}

  return (
    <div>
      {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
      <Modal isOpen={modal} toggle={toggle} className={"modal-dialog-centered"}>
        
        <ModalBody>
      {type ==='forgot'&& <p>
       We have sent you a link to reset password. Please check your mail
         </p> }
      {type ==='reset'&& <p>
       Your password has been reset successfully. Please login with new password
         </p> }
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>OK</Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default FpwdResult;