import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, PaginationItem, PaginationLink,  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import {range} from '../helpers/functions'


function MailPagination(props){

const {currentPage, totalCount, onPageChange, perPage, paginationLength } = props
  
    const [start, setStart] = useState(1);
    const [end, setEnd] = useState(paginationLength);
   

    const dispatch = useDispatch()
    const userData = useSelector(state => state.userDetailsReducer.result)

    const paginationLengthReset =()=>{
      if(Math.ceil(totalCount/perPage)>paginationLength){

        return(paginationLength)
      }else {
        return(Math.ceil(totalCount/perPage))
  
      }

    }



  useEffect(() => {
  
  // let resetEnd = paginationLengthReset()
  setEnd(paginationLengthReset())

 }, []);

 const handleClick = (pg) =>{

  if(pg<=paginationLength){

    setStart(1)
    setEnd(paginationLengthReset())
  }else{


if(pg+2<Math.ceil(totalCount/perPage)){

  setEnd(pg+2)
  setStart(pg-2)
}else{
  setEnd(Math.ceil(totalCount/perPage))
  setStart(Math.ceil(totalCount/perPage)- paginationLengthReset())
}

  }

  onPageChange(pg)

  

 }



 const renderFirstandPrevious = ()=>{


return(

  <>
  <PaginationItem disabled={currentPage==1?true:false}  >
<PaginationLink first onClick={()=>handleClick(1)} />
</PaginationItem>
<PaginationItem disabled={currentPage==1?true:false} >
<PaginationLink  previous onClick={()=>handleClick(currentPage-1)} />
</PaginationItem>
  </>
)

 }
 const renderPageLinks = (start, end)=>{

  let countArr = range(start,end)
  
 


 return   countArr.map((item) =>{
    return(
    
    <PaginationItem active={currentPage==item?true:false} key={item} >

     {item<=end? <PaginationLink onClick={()=>handleClick(item)} >
      {item}
      </PaginationLink>:''
      
      
      }

      </PaginationItem>)

  })  

 }
 const renderNextandLast = ()=>{


 if(Math.ceil(totalCount/perPage) > paginationLength){
  return (

    <>
    <PaginationItem disabled={currentPage== Math.ceil(totalCount/perPage)?true:false}>
<PaginationLink next onClick={()=>handleClick(currentPage+1)} />
</PaginationItem>
<PaginationItem disabled={currentPage== Math.ceil(totalCount/perPage)?true:false} >
<PaginationLink last onClick={()=>handleClick(Math.ceil(totalCount/perPage))} />
</PaginationItem>
    </>
  )

 }else return false



 


 }










    return(

        <>
        {totalCount>paginationLength? <Pagination aria-label="Page navigation example" className="mt-3">
{renderFirstandPrevious()}
           {renderPageLinks(start, end)}
           {renderNextandLast()}
</Pagination>:''	}
        </>
    )


}

export default MailPagination