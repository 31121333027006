
export default (state = {}, action) => {
  switch (action.type) {
    case 'GET_USER_BEGINS':
      return {
        loader: true,
        error: false,
        result: state.result,
        myCommentslikes: null,
        myCommentsdislikes: null,
        myCommentsFlags: null,
        myForumCommentsFlags:null,
        myForumCommentslikes: null,
        myForumCommentsdislikes: null,
      }
    case 'GET_USER_SUCCESS':
      return {
        loader: false,
        error: false,
        result: action.payload.user,
        myCommentslikes: action.payload.myCommentslikes,
        myCommentsdislikes: action.payload.myCommentsdislikes,
        myCommentsFlags: action.payload.myFlags,
        myForumCommentsFlags: action.payload.myforumFlags,
        myForumCommentslikes: action.payload.myforumCommentslikes,
        myForumCommentsdislikes: action.payload.myforumCommentsdislikes,
      }
    case 'GET_USER_FAILURE':
      return {
        loader: false,
        error: false,
        result: null,
        myCommentslikes:{},
        myCommentsdislikes:{},
        myCommentsFlags: {},
        myForumCommentsFlags: {},
        myForumCommentslikes: null,
        myForumCommentsdislikes: null,

      }
    default:
      return state
  }
}